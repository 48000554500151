import axiosServiceApi from '../services/AxiosServiceApi';

export function getWalletsFromProject(projectId) {
    return axiosServiceApi.get(`wallets/v2/byProject/${projectId}`);
}

export function getWallet() {
    return axiosServiceApi.get(`wallets`);
}

export function addWallet(walletData) {
    return axiosServiceApi.post(`wallets/v2`, walletData);
}

export function getOneWallet(project, walletId) {
    return axiosServiceApi.put(`wallets/${walletId}`, project);
}

export function deployWallet(walletId) {
    console.log('walletId', walletId);
    return axiosServiceApi.put(`wallets/v2/deploy/${walletId}`, {});
}

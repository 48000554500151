import React, { useEffect } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Drop from 'react-dropzone-uploader';
import collectionImage from '../../../assets/images/image-upload.png';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';

const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
        <div>
            {!!previews.length && previews}

            {!!(files.length < maxFiles) && <div {...dropzoneProps}>{input}</div>}
        </div>
    );
};
const Preview = ({ meta, fileWithMeta: { remove } }) => {
    if (meta.status === 'done')
        return (
            <div className="fileUploader-preview">
                <img className="card-img" src={meta.previewUrl} onClick={remove} />
            </div>
        );
    return <p className="loading-placeholder">Uploading</p>;
};

const FileInput = ({
    multiple = false,
    maxFiles = 1,
    maxSizeBytes = 1024 * 1024 * 5,
    initialFile = null,
    onFileAdded = (e) => console.warn('onFileAdded CB', e),
    onFileRemoved = (e) => console.warn('onFileRemoved CB', e),
}) => {
    const { t } = useTranslation();
    const [files, setFiles] = React.useState([]);
    const isPartOfInitialFiles = (newFile) =>
        files.some((file) => file.name === newFile.name && file.size === newFile.size);

    const handleChangeStatus = ({ meta, file, remove }, status) => {
        if (status === 'done' && !isPartOfInitialFiles(file)) {
            onFileAdded({ meta, file });
        }
        if (status === 'removed') {
            onFileRemoved({ meta, file });
        }
        if (status === 'error_file_size') {
            remove();
            swal('Oups', 'That file is too large!', 'error');
        }
    };

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files) => {
        console.log(
            'handleSubmit',
            files.map((f) => f.meta),
        );
    };

    useEffect(() => {
        let finalFiles = [];
        if (!initialFile) return;

        if (typeof initialFile === 'string') {
            const fileName = initialFile.split('/').pop();
            const fileExtension = fileName.split('.').pop();
            fetch(initialFile).then((res) => {
                res.arrayBuffer().then((buf) => {
                    finalFiles.push(new File([buf], fileName, { type: `image/${fileExtension}` }));
                    setFiles(finalFiles);
                });
            });
        } else {
            finalFiles.push(initialFile);
            setFiles(finalFiles);
        }
    }, [initialFile]);
    return (
        <Drop
            initialFiles={files}
            LayoutComponent={Layout}
            PreviewComponent={Preview}
            maxFiles={maxFiles}
            multiple={multiple}
            canCancel={false}
            maxSizeBytes={maxSizeBytes}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            inputContent={
                <div className="d-flex align-self-center align-items-center p-3" key="initial-input">
                    <img src={collectionImage} key="initial-image-fileInput" className="" /> 
                    <span className="ms-2" key="initial-text-fileInput" data-typography="poppins">
                        {t('FILE_INPUT_INITIAL_LABEL')}
                    </span>
                </div>
            }
            accept="image/*,audio/*,video/*"
            styles={{
                dropzone: {
                    minHeight: 200,
                    width: '100%',
                    backgroundColor: '#f2f4fa',
                    border: '3px dashed #6366F1',
                    borderRadius: '20px',
                    overflow: 'hidden',
                },
                inputLabel: {
                    color: '#6366F1',
                    fontSize: '16px',
                    fontWeight: 'normal',
                    backgroundColor: '#fff',
                    height: '100%',
                },
            }}
        />
    );
};

export default FileInput;

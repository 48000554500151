import {
    CONFIRMED_CREATE_COLLECTION_ACTION,
    CONFIRMED_DELETE_COLLECTION_ACTION,
    CONFIRMED_EDIT_COLLECTION_ACTION,
    CONFIRMED_GET_COLLECTIONS,
    CONFIRMED_DEPLOY_COLLECTION_ACTION,
    CREATE_COLLECTION_ACTION,

    SET_CURRENT_COLLECTION_ACTION,
} from '../actions/CollectionTypes';

const initialState = {
    collections: [],
    currentCollection: {},
};

export default function CollectionsReducer(state = initialState, actions) {

    if (actions.type === CONFIRMED_GET_COLLECTIONS) {

        const collections = [...state.collections];
        collections.push(actions.payload);
        
        return {
            ...state,
            collections,
        };
    
    }
{/*
    if (actions.type === RESET_COLLECTION_ACTION) {
        return {
            ...state,
            collections: [],
            currentCollection: {},
        };
    }
*/}
    if (actions.type === CREATE_COLLECTION_ACTION) {

        const collections = [...state.collections];
        collections.push(actions.payload);
        return {
            ...state,
            collections,
        };
    }


    if (actions.type === CONFIRMED_DELETE_COLLECTION_ACTION) {
        const collections = [...state.collections];
        const collectionIndex = collections.findIndex((collection) => collection.id === actions.payload);

        collections.splice(collectionIndex, 1);

        return {
            ...state,
            collections,
        };
    }

    if (actions.type === CONFIRMED_EDIT_COLLECTION_ACTION) {
        console.log('selecteddd cooolection')
        const collections = [...state.collections];
        const collectionIndex = collections.findIndex((collection) => collection._id === actions.payload._id);
        console.log('selecteddd cooolection', collectionIndex )
        
        collections[collectionIndex] = actions.payload;
        return {
            ...state,
            collections,
        };
    }

    if (actions.type === CONFIRMED_CREATE_COLLECTION_ACTION) {
        const collections = [...state.collections];
        collections.push(actions.payload);

        return {
            ...state,
            collections,
        };
    }

    if (actions.type === CONFIRMED_DEPLOY_COLLECTION_ACTION) {
        const collections = [...state.collections];
        collections.push(actions.payload);

        return {
            ...state,
            collections,
        };
    }

   

    if (actions.type === SET_CURRENT_COLLECTION_ACTION) {
        return {
            ...state,
            currentCollection: actions.payload,
        };
    }
   

    return state;
}

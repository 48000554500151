/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Col, Container, Form, Table, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateDropAction, deployDropAction } from '../../../store/actions/DropActions';
import { useHistory } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { useUpdateSubtitle } from '../../../context/MenuTitle';
import { getNetworks } from '../../../store/selectors/AuthSelectors';
import { getDropById } from '../../../store/selectors/DropSelector';
import { getNftsByDropId } from '../../../store/selectors/NftSelectors';

import { RangeDatePicker, DatePicker } from "@y0c/react-datepicker";

import { useTranslation } from 'react-i18next';
import FileInput from '../../components/Common/FileInput';

import '@y0c/react-datepicker/assets/styles/calendar.scss';


const useDropForm = () => {

    let { id: dropId } = useParams();

    const [setSubtitle] = useUpdateSubtitle();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [dropType, setDropType] = useState('');

    const [ coinId, setCoinId] = useState(0);
    const [ coin, setCoin] = useState(0);
    const [price, setPrice] = useState(0);

    const [useCodes, setUseCodes] =useState('');


    const [collections] = useState([]);
    const [nfts, setNfts] = useState([]);
    const [ projectId, setProjectId] = useState([]);
    const [ strategyId, setStrategyId] = useState(0);
    
    const [ contractId, setContractId] = useState(0);
    const [ walletId, setWalletId] = useState(0);

 
    const [twitter, setTwitter] = useState('');
    const [retwit, setRetwit] = useState('');
    const [liketweet, setLiketweet] = useState('');
    const [discord, setDiscord] = useState('');
    const [telegram, setTelegram] = useState('');

    const [isDirectMinting, setIsDirectMinting] = useState('');

    const [show, setShow] = useState(false);
    const showModal = () => setShow(true);
    const closeModal = () => setShow(false);
 
    const dispatch = useDispatch();
    const history = useHistory();
 
    const networks = useSelector(getNetworks);
    const drop = useSelector((state) => getDropById(state, dropId));
    const projectsAll = useSelector((state) => state.projects.projects);
    const collectionsAll = useSelector((state) => state.collections.collections);
    const walletsAll = useSelector((state) => state.wallets.wallets);
    const nftsAll = useSelector((state) => getNftsByDropId(state, dropId));
    const strategyAll = [
        //{"_id":"0", "name":"Raffle" },
        {"_id":"0", "name":"Redeem"},
        {"_id":"1", "name":"Sale"}]

    const coinsAll = [
        {"_id":"0", "name":"usdc"},
        {"_id":"1", "name":"matic"},
        {"_id":"2", "name":"weth"},
        {"_id":"3", "name":"eur"},

    ]    

    const { t } = useTranslation();    

    const selectCoin = (coinId) => {
        const newSelectedCoin = coinsAll.find((coin) => coin._id === coinId);
        setCoinId(newSelectedCoin._id);
    };

    const returnCoinName =(coinId) => {
        const selCoin = coinsAll.find((coin) => coin._id === coinId);
        return selCoin.name;

    }
    const returnCoinId =(coinName) => {
        const selCoin = coinsAll.find((coin) => coin.name == coinName);
        return selCoin._id;

    }

    
    const selectProject = (projectId) => {
        const newSelectedProject = projectsAll.find((project) => project._id === projectId);
        setProjectId(newSelectedProject._id);
    };

    const findProjectFromCollectionId =(collectionId) => {
        const newSelectedCollection = collectionsAll.find((collection) => collection._id === collectionId);
        setProjectId(newSelectedCollection.projectId);
        const newSelectedWallet = walletsAll.find((wallet) =>wallet.projectId ===newSelectedCollection.projectId )
        setWalletId(newSelectedWallet._id);

    }
    
   const getWallet = walletId =>{
        return  walletsAll.find((wallet) =>wallet._id === walletId )
    
   } 
   const getProject = projectId =>{
    return  projectsAll.find((project) =>project._id === projectId )

   }
   const getContract = contractId =>{
    return  collectionsAll.find((contract) =>contract._id === contractId )

   } 


    useEffect(() => {
        selectStrategy(strategyAll[0]._id);
        selectProject(projectsAll[0]._id);
    }, []);
    
    
    useEffect(() => {
        if (drop) {
            console.log('dropyy', drop);
            setSubtitle(drop.name);
            setName(drop.name);
            setDescription(drop.description);
            setImage(drop.image);
            setDropType(drop.dropType);
            setUseCodes(drop.useCodes);
            setContractId(drop.contractId);
            setPrice(drop.price);
            findProjectFromCollectionId(drop.contractId);
          //  setStartDate(drop.startDate.replace(/T/, ' ').replace(/\ .+/, ''));
          setStartDate(drop.startDate);
            setEndDate(drop.endDate);
            setCoin(drop.coin);
            setCoinId(returnCoinId (drop.coin));
            setIsDirectMinting(drop.isDirectMinting);
            if(drop.isDirectMinting) setStrategyId(1);


        } else {
            setSubtitle('New Mint');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropId, drop?._id, networks]);
  
  
    const hiddenFileInput = React.useRef(null);

    const handleCSVUpload = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
      };

    let strategyComponent 

    if(strategyId == '0') {
        
        strategyComponent = <>        
      
        <p>{t('DROP_STARTEGY_INDIRECTSALE_TITLE')}</p>
        
        <Row>
        <Col md={6} sm={4} xs={12}>
     
        </Col>
        <Col md={6} sm={4} xs={12}>
               
        
        
        </Col>
  
        </Row>
        </>    
    }
    if(strategyId == '1'){
        strategyComponent = <>
         <Form.Group className="mb-3">
            <p>{t('DROP_STARTEGY_DIRECTSALE_TITLE')}</p>
        </Form.Group>
        <p> {t('DROP_STARTEGY_SALE_DESCRIPTION')}</p>
        
        <Row>
        <Col md={6} sm={4} xs={12}>
            <select
            className="form-control mt-1 selecto"
            onChange={(event) => selectCoin(event.target.value)}
            value={coinId}
            >
            {coinsAll.map(({ _id, name }) => (
                <option key={_id} value={_id}>
                    {name}
                </option>
            ))}
            </select>

                
        </Col>
        <Col md={6} sm={4} xs={12}>
        <Form.Text
            as="input"
            name="name"
            rows="4"
            required
            type="text"
            className="form-control"
            placeholder={'amount'}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
        />

        </Col>
  
        </Row>
        
        </>
    }

let dropTypeComponent = <>


    <Form.Label htmlFor="description" className="text-primary">
        {t('DROP_STRATEGIES_DISTRIBUITION')}
    </Form.Label>

    <div className="mt-3 mb-2">
        <Form.Check
            inline
            type={'radio'}
            id={`dropType-fifo`}
            name={`dropType`}
            label={'First In First Served'}
            checked={dropType === 'fifo'}
            onChange={(e) => {setDropType('fifo')}}
            style={{ marginRight: '60px' }}
        />
        <Form.Check
            inline
            type={'radio'}
            id={`dropType-raffle`}
            checked={dropType === 'raffle'}
            name={`dropType`}
            onChange={(e) => setDropType('raffle')}
            label={'Raffle'}
        />
    </div>
    </>
    let useCodesComponent = <>
        
        <p> {t('DROP_STARTEGY_REDEEM_DETAILS')}</p>

        <Form.Label htmlFor="description" className="text-primary">
            {t('DROP_STARTEGY_USECODES_TITLE')}
        </Form.Label>

        <div className="mt-3 mb-2">
        <Form.Check
            inline
            type={'radio'}
            id={`useCodes-yes`}
            name={`useCodes`}
            label={'Yes'}
            checked={useCodes == '1'}
            onChange={(e) => {setUseCodes('1')}}
            style={{ marginRight: '60px' }}
        />
        <Form.Check
            inline
            type={'radio'}
            id={`dropType-raffle`}
            checked={useCodes == '0'}
            onChange={(e) => setUseCodes('0')}
            name={`useCodes`}
            label={'No'}
        />
        </div>
        </>
       

       let codesUploadComponent 

        if(useCodes =='1'){
            codesUploadComponent = <>
            <Button
               type="button"
               className="mt-2 mb-1"
               variant="outline-primary"
               onClick={handleCSVUpload}
               >
           Upload CSV file
           </Button>
           
           <input 
               type="file"
               name="file" 
               ref={hiddenFileInput}
               onChange={handleChange}
               style={{display:'none'}}
               />    </>
        }else{
            codesUploadComponent = <></>
        }
        
        

    const onChangeDatePicker = title => (...args) =>{
         setStartDate(args[0]);
         setEndDate(args[1]);
    } 


    const selectStrategy= (strategyId) => {
        const newSelectedStrategy = strategyAll.find((strategy) => strategy._id === strategyId);
        setStrategyId(newSelectedStrategy._id);   
        if(newSelectedStrategy._id === '1') {
            setIsDirectMinting(1);
        }
        else{
            setIsDirectMinting(0);
        }    
    };

    const onEditSettings =() => {
        showModal();

    }

    const onSave = () => {
        const dropData = {
            name: name,
            description: description,
            image: image, 
            dropType: dropType,
            price: price,
            coin: coin, 
            startDate: startDate,
            endDate: endDate,
            dropId: dropId,
            useCodes: 0,
            isDirectMinting: isDirectMinting,
        }   

        dispatch(updateDropAction( dropId, dropData, history));
        history.push('/drops')

    };

    const onDeploy = () => {
        const dropData = {
            name: name,
            description: description,
            image: image, 
            dropType: dropType,
            price: price,
            coin: returnCoinName('1'), 
            startDate: startDate,
            endDate: endDate,
            dropId: dropId,
            useCodes: 0,
            isDirectMinting: isDirectMinting
        }   

        dispatch(updateDropAction( dropId, dropData, history));
        
        dispatch(deployDropAction( 
            getProject(projectId),
            getWallet(walletId),
            getContract( contractId) ,
            dropId, 
            dropData, 
            history));


    };


     console.log('StartDate', startDate);




    return {
        dropId, name, description, image, collections, projectsAll, selectProject, nfts, nftsAll,
        setNfts, setName, setDescription, setImage, onSave, history, setTwitter, setRetwit, setLiketweet,
         setDiscord, setTelegram, twitter, retwit, liketweet, discord, telegram, strategyId, strategyComponent,
         setStrategyId, selectStrategy, strategyAll,handleCSVUpload, dropTypeComponent, useCodesComponent, 
         codesUploadComponent, onDeploy, contractId, projectId, walletId, startDate, setStartDate, endDate, 
         setEndDate, onChangeDatePicker, onEditSettings, show, closeModal, coin, isDirectMinting
    };
};


const ViewDrop = () => {

    const { projectId, name, description, image, nfts, nftsAll, setName, projectsAll, setDescription,
        selectProject, setImage, onSave, setTwitter, setRetwit, setLiketweet, setDiscord, setTelegram,
        twitter, retwit, liketweet, discord, telegram, strategyId, setStrategyId, strategyAll, 
        selectStrategy, strategyComponent, history,handleCSVUpload, dropTypeComponent, useCodesComponent,
        codesUploadComponent, onDeploy, contractId, walletId, startDate, setStartDate, endDate, setEndDate,
        onChangeDatePicker, onEditSettings, show, closeModal, coin, isDirectMinting 
    } = useDropForm();



    const { t } = useTranslation();
   
   
   
    return (
        
        <form>
            <Card className="p-3">
                <Row>
                    <Col md={3} sm={4} xs={12} style={{}}>
                        <Form.Label htmlFor="description" className="text-primary">
                            {t('PROJECTS_FORM_EDIT_THUMBNAIL')}
                        </Form.Label>
                        <div className="thumbnail-centered">
                            <FileInput
                                initialFile={image}
                                onFileAdded={({ file }) => setImage(file)}
                                onFileRemoved={() => setImage()}
                            />
                        </div>
                    </Col>
                    <Col md={3} sm={4} xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description" className="text-primary">
                                {t('PROJECTS_FORM_NAME')}
                            </Form.Label>

                            <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="">
                            <Form.Label htmlFor="description" className="text-primary">
                                {t('PROJECTS_FORM_DESCRIPTION')}
                            </Form.Label>

                            <Form.Text
                                as="textarea"
                                name="description"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                style={{ height: '150px' }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    
                    <Col md={6} sm={4} xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description" className="text-primary">
                                Link it to a Project
                            </Form.Label>     

                            <Row>
                                <Col sm={4} xs={6}>
                                    <select
                                        className="form-control mt-1 selecto"
                                        onChange={(event) => selectProject(event.target.value)}
                                        value={projectId}
                                    >
                                        {projectsAll.map(({ _id, name }) => (
                                            <option key={_id} value={_id}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm={8} xs={6}>
                                    <Button
                                        type="button"
                                        className="mt-2 mb-1"
                                        variant="outline-primary"
                                        onClick={() => history.push('/add-project')}
                                    >
                                        Project{' '}
                                        <span className="btn-icon-end">
                                            <i className="fa fa-plus-circle" />
                                        </span>
                                    </Button>
                                </Col>

                                <p> drop name : {name}</p>
                                <p> projectId : {projectId}</p>
                                <p> collectionId : {contractId}</p>
                                <p> walletId : {walletId}</p>
                                <p> coin: {coin} </p>
                                <p>strategyId {strategyId}</p>
                                <p>isDirectMinting {isDirectMinting}</p>
                                <p>startDate {startDate}</p>
                            </Row>
                        </Form.Group>             
                    </Col>
                </Row>
            </Card>
           
            <Row>
                <Col md={6} sm={6} xs={12}>
                    <Card className="p-3">
                        <Row>
                            { /* ACQUISING STRATEGIES */}
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="description" className="text-primary">
                                    {t('DROP_STRATEGIES_TITLE')}
                                </Form.Label>
                                <p>{t('DROP_STRATEGIES_DESCRIPTION')}</p>
                            
                                
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="example rangeDatePicker">
                                                <p className="mb-1">Date Range Pick</p>
                                                <RangeDatePicker
                                                    
                                                    startText="Start"
                                                    endText="End"
                                                    startPlaceholder="2022-10-12"
                                                    endPlaceholder="2022-10-12"
                                                 //   onChange={onChangeDatePicker("Range DatePicker")}
                                                    initialStartDate = '2022-10-13'
                                                    initialEndDate = '2022-10-16'
                                                
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </Form.Group>

                            {/* DropType */}
                    
                            {dropTypeComponent}          
                            
                            {/* Distribuition method */}
                          
                                <p>{t('DROP_STRATEGIES_ACQUIRE')}</p>      

                            <Form.Group>
                                <Col sm={4} xs={6}>
                                    <select
                                        className="form-control mt-1 selecto"
                                        onChange={(event) => selectStrategy(event.target.value)}
                                        value={strategyId}
                                    >     
                                        {strategyAll.map(({ _id, name }) => (
                                            <option key={_id} value={_id}>
                                                {name}
                                            </option>
                                        ))}
                                        
                                    </select>
                                </Col>
                                <Form.Label htmlFor="description" className="text-primary">
                                </Form.Label>
                            </Form.Group>

                            <Form.Group className="mb-3">  
                                {strategyComponent}  
                            </Form.Group>

                            <Form.Group className="mb-3">  
        
                                {useCodesComponent} 
                                {codesUploadComponent }
                        
                            </Form.Group>         
                        </Row>
                    </Card>
                </Col>

                    
                <Col md={6} sm={6} xs={12}>
                    <Card className="p-3">
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="description" className="text-primary">
                                    {t('DROP_LANDING_TITLE')}
                                </Form.Label>
                                <p>{t('DROP_LANDING_DETAIL')}</p>

                                <Row>
                                    <Col md={6} sm={6} xs={12}>    
                                        <Button
                                            type="button"
                                            className="mt-2 mb-1"
                                            variant="outline-primary"
                                         //   onClick={() => onSave()}
                                        >
                                            {t('DROP_LANDING_BUTTON')}
                                        </Button>
                                    </Col>

                                    <Col md={6} sm={6} xs={12}>    
                                        <Button
                                            type="button"
                                            className="mt-2 mb-1"
                                            variant="outline-primary"
                                            onClick={() => onEditSettings()}
                                        >
                                            {t('DROP_LANDING_EDIT_SETTING_LINK')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Row>
                    </Card>
                </Col>  
            </Row>


            <Row>
                <Col xs={12} className={'d-flex justify-content-end mb-3'}>
                    <Button
                        disabled={!name.trim()}
                        type="button"
                        className="me-2"
                        variant="primary"
                        onClick={() => onSave()}
                    >
                        Save
                    </Button>
                    <Button
                        disabled={!name.trim()}
                        type="button"
                        className="me-2"
                        variant="primary"
                        onClick={() => onDeploy()}
                    >
                        Save and Deploy
                    </Button>
                </Col>
            </Row>
            <Modal show={show}>
            <Modal.Header>
                    
                        <b><Form.Label htmlFor="description" className="text-primary">
                            Landing Preferences
                           </Form.Label> </b>
                      
                
                </Modal.Header>

                <Modal.Body>
               
                        <Form.Label htmlFor="description" className="text-primary">
                            Customize Landing name
                        </Form.Label>
                        <Row>
                            <Col md={6} sm={6} xs={12}>
                            https://mintknight.com/
                            </Col>
                        
                            <Col md={6} sm={6} xs={12}>
                            <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />

                            </Col>        

                        </Row>  
                    
                </Modal.Body>
                <Modal.Footer>
                       <Button variant="secondary"  onClick={closeModal}> save</Button> 
                </Modal.Footer>
            </Modal>
        </form> 
    );
};

export default ViewDrop;
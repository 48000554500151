import React from 'react';

export default function WalletRow({ wallet }) {
    return (
        <tr>
            <td>{wallet.wallet._id}</td>
            <td>{wallet.wallet.projectId}</td>
            <td>{wallet.wallet.address}</td>
        </tr>
    );
}

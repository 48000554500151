import React, { useContext } from 'react';

/// React router dom
import { Switch, Route } from 'react-router-dom';

/// Layout
import Nav from './layouts/nav/index';
/// Dashboard
import Home from './pages/Home/Home';

//NEW PAGES
import IndexProjects from './pages/Projects/IndexProjects';
import AddProject from './pages/Projects/AddProject';
import EditProject from './pages/Projects/EditProject';

import { ThemeContext } from '../context/ThemeContext';
//import PleaseActivate from "./pages/AccountActivation/VerifyAccount"
//import AccountActivation from "./pages/AccountActivation/AccountActivation"

///Nfts
import IndexMints from './pages/Mints/IndexMints';
import AddMint from './pages/Mints/AddMint';
import ViewEditMint from './pages/Mints/ViewEditMint';


///Company
import CompanyInfo from './components/Company/CompanyInfo';
import AddCompany from './components/Company/AddCompany';

///Collections
import IndexCollections from './components/Collections/IndexCollections';
import AddCollection from './components/Collections/AddCollection';
import ViewCollection from './components/Collections/ViewCollection';

///Drops
import IndexDrops from './pages/Drops/IndexDrops';
import ViewDrops from './pages/Drops/ViewEditDrop';
import AddDrop from './pages/Drops/AddDrop';

//import AddDrop from './pages/Drops/AddDrop';

///Media
import IndexMedia from './components/Media/IndexMedia';
import AddMedia from './components/Media/AddMedia';

///Wallet
import IndexWallets from './components/Wallets/IndexWallets';
import AddWallet from './components/Wallets/AddWallet';

//pricing
import PricingTiers from './components/PricingTiers/PricingTiers';

//profile
import Profile from './components/Profile/Profile';
import MenuTitleStore from '../context/MenuTitle';

export const routes = [
    /// Dashboard
    { url: '', component: Home, title: 'Dashboard' },
    { url: 'dashboard', component: Home, title: 'Dashboard' },

    /// company
    { url: 'company-info', component: CompanyInfo, title: 'Companies' },
    { url: 'add-company', component: AddCompany, title: 'Companies > New Company' },

    /// Projects
    { url: 'projects', component: IndexProjects, title: 'Projects' },
    {
        url: 'add-project',
        component: AddProject,
        title: 'Projects',
        subPath: 'New Project',
        redirectRoute: 'projects',
    },
    { url: 'project/:id', component: EditProject, title: 'Project', redirectRoute: 'projects' },

    /// Collections
    { url: 'collections', component: IndexCollections, title: 'Collections' },
    {
        url: 'add-collection',
        component: AddCollection,
        title: 'Collections',
        subPath: 'New Collection',
        redirectRoute: 'collections',
    },
    { url: 'collection/:id', component: ViewCollection, title: 'Collection', redirectRoute: 'collections' },

    /// Drops
    { url: 'drops', component: IndexDrops, title: 'Drops' },
    { url: 'drop/:id', component: ViewDrops, title: 'Drops', redirectRoute: 'Drops' },
    { url: 'add-drop', component: AddDrop, title: 'Add Drop' },

    /// Mints
    { url: 'mints', component: IndexMints, title: 'Mints', subPath: 'Index', redirectRoute: 'mints' },
    { url: 'add-mint', component: AddMint, title: 'Add a Mint', subPath: 'New Mint', redirectRoute: 'nfts' },
    { url: 'mint/:id', component: ViewEditMint, title: 'Mint', redirectRoute: 'Mints' },
 
    /// Media
    { url: 'medias', component: IndexMedia },
    { url: 'add-media', component: AddMedia },

    /// Wallets
    { url: 'wallets', component: IndexWallets },
    { url: 'add-wallet', component: AddWallet },

    //pricing
    { url: 'pricing', component: PricingTiers },

    //profile
    { url: 'profile', component: Profile, title: 'Profile' },
];

const Markup = () => {
    const { menuToggle } = useContext(ThemeContext);
    return (
        <MenuTitleStore>
            <div id="main-wrapper" className={`show ${menuToggle ? 'menu-toggle' : ''}`}>
                <Nav />

                <div className={`content-body`}>
                    <div className={`container-fluid`}>
                        <Switch>
                            {routes.map((data, i) => (
                                <Route key={i} exact path={`/${data.url}`} component={data.component} />
                            ))}
                        </Switch>
                    </div>
                </div>
            </div>
        </MenuTitleStore>
    );
};

export default Markup;

/* eslint-disable no-unused-vars */
/// Menu
import Metismenu from 'metismenujs';
import React, { Component, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
/// Scroll
import PerfectScrollbar from 'react-perfect-scrollbar';
/// Link
import { Link, useHistory } from 'react-router-dom';
import useScrollPosition from 'use-scroll-position';
import { ThemeContext } from '../../../context/ThemeContext';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
// Image
//import profile from "../../../images/profile/pic1.jpg";

class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new Metismenu(this.$el);
    }
    componentWillUnmount() {}
    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

const SideBar = () => {
    const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);
    const history = useHistory();

    useEffect(() => {
        var btn = document.querySelector('.nav-control');
        var aaa = document.querySelector('#main-wrapper');
        function toggleFunc() {
            return aaa.classList.toggle('menu-toggle');
        }
        btn.addEventListener('click', toggleFunc);

        //sidebar icon Heart blast
        // var handleheartBlast = document.querySelector('.heart');
        //       function heartBlast() {
        //           return handleheartBlast.classList.toggle("heart-blast");
        //       }
        //       handleheartBlast.addEventListener('click', heartBlast);
    }, []);

    let scrollPosition = useScrollPosition();
    /// Path
    let path = window.location.pathname;
    path = path.split('/');
    path = path[path.length - 1];
    /// Active menu
    let deshBoard = ['', 'dashboard-dark', 'order-list', 'event', 'customer', 'analytics', 'reviews', 'task'],
        app = [
            'app-profile',
            'post-details',
            'app-calender',
            'email-compose',
            'email-inbox',
            'email-read',
            'ecom-product-grid',
            'ecom-product-list',
            'ecom-product-order',
            'ecom-checkout',
            'ecom-invoice',
            'ecom-customers',
            'post-details',
            'ecom-product-detail',
        ],
        email = ['email-compose', 'email-inbox', 'email-read'],
        shop = [
            'ecom-product-grid',
            'ecom-product-list',
            'ecom-product-list',
            'ecom-product-order',
            'ecom-checkout',
            'ecom-invoice',
            'ecom-customers',
            'ecom-product-detail',
        ],
        charts = [
            'chart-rechart',
            'chart-flot',
            'chart-chartjs',
            'chart-chartist',
            'chart-sparkline',
            'chart-apexchart',
        ],
        bootstrap = [
            'ui-accordion',
            'ui-badge',
            'ui-alert',
            'ui-button',
            'ui-modal',
            'ui-button-group',
            'ui-list-group',
            'ui-media-object',
            'ui-card',
            'ui-carousel',
            'ui-dropdown',
            'ui-popover',
            'ui-progressbar',
            'ui-tab',
            'ui-typography',
            'ui-pagination',
            'ui-grid',
        ],
        plugins = [
            'uc-select2',
            'uc-nestable',
            'uc-sweetalert',
            'uc-toastr',
            'uc-noui-slider',
            'map-jqvmap',
            'uc-lightgallery',
        ],
        redux = ['redux-form', 'redux-wizard', 'todo'],
        widget = ['widget-basic'],
        forms = ['form-element', 'form-wizard', 'form-editor-summernote', 'form-pickers', 'form-validation-jquery'],
        table = ['table-bootstrap-basic', 'table-datatable-basic'],
        pages = [
            'page-register',
            'page-login',
            'page-lock-screen',
            'page-error-400',
            'page-error-403',
            'page-error-404',
            'page-error-500',
            'page-error-503',
            'page-error-513',
            'empty',
        ],
        error = [
            'page-error-400',
            'page-error-403',
            'page-error-404',
            'page-error-500',
            'page-error-503',
            'page-error-513',
        ],
        projects = ['projects', 'add-project'],
        collections = ['collections', 'add-collection'],
        drops = ['drops', 'add-drop'],
        mints = ['mints', 'add-mint'],
        media = ['medias', 'add-media'],
        wallets = ['wallets', 'add-wallet'],
        dashboard = ['dashboard'];

    return (
        <div
            className={`deznav ${iconHover} ${
                sidebarposition.value === 'fixed' &&
                sidebarLayout.value === 'horizontal' &&
                headerposition.value === 'static'
                    ? scrollPosition > 120
                        ? 'fixed'
                        : ''
                    : ''
            }`}
        >
            <div className='d-flex justify-content-center mb-5'>

            <Button type="submit" className="me-2 create-button-nav" variant="primary" onClick={() => {}}>
                Create NFT MINT
            </Button>
            </div>
            <PerfectScrollbar className="deznav-scroll">
                <MM className="metismenu" id="menu">
                    <li className={`${dashboard.includes(path) ? 'mm-active' : ''}`}>
                        <Link className="ai-icon" to="/dashboard">
                            <DashboardOutlinedIcon />
                            <span className="nav-text ml-3">Dashboard</span>
                        </Link>
                    </li>
                    <li className={`${projects.includes(path) ? 'mm-active' : ''}`}>
                        <Link className="ai-icon" to="/projects">
                            <FolderOpenOutlinedIcon color="secondary" />
                            <span className="nav-text ml-3 ">Project</span>
                        </Link>
                    </li>

                    <li className={`${collections.includes(path) ? 'mm-active' : ''}`}>
                        <Link className="ai-icon" to="#" onClick={() => history.push('/collections')}>
                            <AppsOutlinedIcon />
                            <span className="nav-text ml-3">Collections</span>
                        </Link>
                    </li>

                    <li className={`${mints.includes(path) ? 'mm-active' : ''}`}>
                        <Link className="ai-icon" to="/mints">
                            <ConfirmationNumberRoundedIcon />
                            <span className="nav-text ml-3">NFT MINTs</span>
                        </Link>
                    </li>
                    <li className={`${drops.includes(path) ? 'mm-active' : ''}`}>
                        <Link className="ai-icon" to="/drops">
                            <SendRoundedIcon />
                            <span className="nav-text ml-3">Drops</span>
                        </Link>
                    </li>

                    <li className={`${media.includes(path) ? 'mm-active' : ''}`}>
                        <Link className="ai-icon" to="#" onClick={() => history.push('/medias')}>
                            <PermMediaOutlinedIcon className='mr-3'/>
                            <span className="nav-text ml-3">Media</span>
                        </Link>
                        <ul>
                            <li>
                                <Link className={`${path === 'uc-select2' ? 'mm-active' : ''}`} to="/medias">
                                    View Media
                                </Link>
                            </li>
                            <li>
                                <Link className={`${path === 'uc-select2' ? 'mm-active' : ''}`} to="/add-media">
                                    Add Media
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={`${wallets.includes(path) ? 'mm-active' : ''}`}>
                        <Link className="ai-icon" to="#">
                            <AccountBalanceWalletOutlinedIcon />
                            <span className="nav-text ml-3">Wallet</span>
                        </Link>
                    </li>
                </MM>

                <div className="copyright">
                    <p>
                        <strong>MintKnight</strong> © 2022 All Rights Reserved
                    </p>
                </div>
            </PerfectScrollbar>
        </div>
    );
};

export default SideBar;

import React from 'react';
import WalletRow from '../Wallets/WalletRow';

export default function WalletTable({ header, indexes, wallets }) {
    console.log(wallets);

    const walletRows = wallets.map((wallet, indexes) => {
        return (
            <WalletRow
                // key={collection._id}
                wallet={wallet}
                indexes={indexes}
                key={indexes}
            />
        );
    });

    const tableHeader = header.map((item) => (
        <th key={indexes}>
            <strong>{item}</strong>
        </th>
    ));

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-responsive-md">
                    <thead>
                        <tr>{tableHeader}</tr>
                    </thead>

                    <tbody>{walletRows}</tbody>
                </table>
            </div>
        </div>
    );
}

import * as CryptoJS from 'crypto-js';

export function encriptSKey1(skey1, userId ) {
    var encrypted = CryptoJS.AES.encrypt(skey1, userId).toString();
    localStorage.setItem(userId + '_encryptedSK1', encrypted);
}

export function decryptSKey1(userId ) {
        const encrypted = localStorage.getItem(userId + '_encryptedSK1');
        if (!encrypted) return null;
        var decrypt = CryptoJS.AES.decrypt(encrypted, userId);
        var ret = decrypt.toString(CryptoJS.enc.Utf8);
        return ret;
    
}

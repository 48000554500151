import axiosInstance from '../services/AxiosInstance';

import * as FormData from 'form-data';

const formConfig = {
    headers: {
        'content-type': 'application/x-www-form-urlencoded',
    },
};

const prepareProjectForm = (projectData) => {
    const { image, ...data } = projectData;
    const form = new FormData();
    for (var key in data) {
        form.append(key, data[key]);
    }
    if (image && typeof image !== 'string') {
        form.append('thumb', image, image.name);
    } else if (!image) {
        form.append('thumb', null);
    }
    return form;
};

export function getProjects() {
    return axiosInstance.get(`projects/v2`);
}

export function createProject(projectData) {
    const form = prepareProjectForm(projectData);
    return axiosInstance.post(`projects/v2`, form, formConfig);
}

export function updateProject(project, projectId) {
    const form = prepareProjectForm(project);

    return axiosInstance.put(`projects/v2/${projectId}`, form, formConfig);
}

export function deleteProject(projectId) {
    return axiosInstance.delete(`projects/v2/${projectId}.json`);
}

export function getProjectApi(projectId) {
    return axiosInstance.get(`projects/v2/apikey/${projectId}`);
}

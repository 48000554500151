/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Button, Tab, Nav, Dropdown, Pagination } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CommonCard from '../Common/Card';

const DefaultCollectionStates = { Draft: 'draft', OnChain:'onchain', Active: 'active', Archived: 'onchain' };
export const DefaultTabTypes = [
    { label: 'All', default: true, filterFn: (collection) => collection},
    { label: 'Draft', filterFn: (collection) => collection.status === DefaultCollectionStates.Draft },
    { label: 'On Chain', filterFn: (collection) => collection.status === DefaultCollectionStates.OnChain },
   // { label: 'Archived', filterFn: (collection) => collection.status === DefaultCollectionStates.Archived },
];

const DefaultFilters = [
    {
        label: 'Project',
        options: [{ value: 'id-1', element: <span>something</span> }],
    },
];

const TabListLayout = ({
    data = [],
    meta = { total: 0, page: 1, pageSize: 0, itemName: 'item' },
    tabs = DefaultTabTypes,
    filters = DefaultFilters,
    onChange = console.log("changing tabs"),
    mainAction = { action: () => console.log('SET AN ACTION'), label: 'DO SOMETHING' },
    loading = true,
    menuItems = [
        {
            title: 'Edit',
            action: () => {
                console.log('Edit pressed');
            },
        },
        {
            title: 'Remove',
            action: () => {
                console.log('Remove pressed');
            },
        },
    ],
}) => {
    const defaultTab = (tabs.find((tab) => !!tab.default) || tabs[0])?.label;
    return (
        <div className="badge-demo">
            <Row className="custom-tab-1">
                <Tab.Container defaultActiveKey={defaultTab}>
                    <Col lg="9" className="d-flex justify-content-between">
                        <Nav as="ul" className="nav-tabs align-items-center">
                            {tabs.map(({ label }) => (
                                <Nav.Item key={label} as="li">
                                    <Nav.Link eventKey={label} onClick={() => onChange('tab', label)}>
                                        {label}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>

                        <div className="d-flex justify-content-end">
                            {!!filters.length &&
                                filters.map(({ label, options, onChangeFilter }) => (
                                    <Form.Control
                                        as="select"
                                        className="form-control custom-select m-1 pl-2 filter-select"
                                        key={label}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            onChange('filter', e.target.value);
                                            onChangeFilter(e.target.value);
                                        }}
                                    >
                                        {options.map(({ value, element, index }) => (
                                            <option key={`${label}-option-${index}`} value={value}>
                                                {element}
                                            </option>
                                        ))}
                                    </Form.Control>
                                ))}
                        </div>
                    </Col>
                    <Col lg="3" className="d-flex justify-content-end">
                        <Button
                            type="submit"
                            className="me-2 create-button"
                            variant="outline-primary"
                            onClick={mainAction.action}
                        >
                            {mainAction.label}
                        </Button>
                    </Col>

                    {console.log('data', data)}
                    {console.log('data', tabs)}

                    <Tab.Content className="pt-4">
                        {tabs.length ? (
                            tabs.map(({ label, filterFn }) => (
                                <Tab.Pane key={label} eventKey={label}>
                                    <Row>
                                        {data?.filter(filterFn).map((element, index) => (
                                            <CommonCard key={index} {...element}/>
                                        ))}
                                    </Row>
                                </Tab.Pane>
                            ))
                        ) : (
                            <Row
                                className={`${!data?.length && loading && 'loader list'} ${
                                    !data?.length && !loading && 'empty-state'
                                }`}
                            >
                                {data?.map((element, index) => (
                                    <CommonCard key={index} {...element} />
                                ))}
                            </Row>
                        )}
                    </Tab.Content>
                </Tab.Container>
            </Row>
            {!!data?.length && !loading && (
                <Row className="justify-content-between">
                    <div style={{ width: 250 }}>
                        Showing {data?.length} from {meta.total} {meta.itemName}
                    </div>
                    <Pagination>
                        <Pagination.Prev onClick={() => onChange('previous-page', { page: meta.page - 1 })} />
                        <Pagination.Item active>{meta.page}</Pagination.Item>
                        <Pagination.Next onClick={() => onChange('next-page', { page: meta.page + 1 })} />
                    </Pagination>
                </Row>
            )}
        </div>
    );
};

export default TabListLayout;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TabListLayout from '../../components/Common/TabListLayout';
import { useUpdateSubtitle } from '../../../context/MenuTitle';
import { getAllProjects } from '../../../store/selectors/ProjectSelectors';
import { useTranslation } from 'react-i18next';

const useListProjects = () => {
    const [setSubtitle] = useUpdateSubtitle();
    setSubtitle('');
    const history = useHistory();
    const [projects, setProjects] = useState([]);

    const projectsAll = useSelector(getAllProjects);

    useEffect(() => {
        setProjects(
            projectsAll.map((project) => ({
                key: project._id,
                image: project.image,
                title: project.name,
                description: project.description,
                status: project.updatedAt,
                onView: () => history.push(`/project/${project._id}`),
                menuItems: [],
            })),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsAll]);

    return [projects];
};

const IndexProjects = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const [projects] = useListProjects();
    
    const projectsAll = useSelector(getAllProjects);

    console.log(projectsAll)
    return (
        <TabListLayout
            loading={!projects.length}
            data={projects}
            tabs={[]}
            filters={[]}
            onChange={() => null}
            meta={{ total: projects.length, page: 1, perPage: 10, itemName: 'projects' }}
            mainAction={{ action: () => history.push('/add-project'), label: t('NEW_PROJECT') }}
        ></TabListLayout>
    );
};

export default IndexProjects;

import axios from 'axios';

const axiosInstanceNonAuth = axios.create({
    baseURL: process.env.REACT_APP_API_SERVICE,
});

axiosInstanceNonAuth.interceptors.request.use((config) => {
    config.params = config.params || {};
    return config;
});

export default axiosInstanceNonAuth;

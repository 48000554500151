import React from 'react';

import { Col, Card, Dropdown } from 'react-bootstrap';
import collectionImage from '../../../assets/images/mock_images/collection.png';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function CommonCard({
    title = 'Mintknight 2022',
    description = 'This is a wider card with supporting text and below as a natural lead-in to the additionalcontent. This content is a little',
    image = collectionImage,
    status = 'Last updated 3 min ago',
    titleExtras = [],
    showFooter = true,
    variant = 'default',
    menuItems = [
        {
            title: 'Edit',
            action: () => {
                console.log('Edit pressed');
            },
        },
        {
            title: 'Remove',
            action: () => {
                console.log('Remove pressed');
            },
        },
    ],
    onView = () => console.log('view collection'),
}) {
    const { t } = useTranslation();
    /*
    menuItems = [
        {
            title: 'Edit',
            action: () => {
                console.log('Edit pressed');
            },
        },
        {
            title: 'Remove',
            action: () => {
                console.log('Remove pressed');
            },
        },
    ]
    
*/
    return (
        <Col xl="4" lg="4" md="6" sm="6" xs="12">
            <Card className={`variant-${variant} common`}>
                <img className="card-img-bottom " src={image ? image : collectionImage} alt="Card cap" />
                <Card.Header className="d-flex justify-content-between">
                    <div>
                        <Card.Title>{title}</Card.Title>
                    </div>
                    <div>
                        {titleExtras.map((titleExtra, index) => (
                            <span key={index}>{titleExtra}</span>
                        ))}
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Text>{description}</Card.Text>
                </Card.Body>
                {showFooter && (
                    <Card.Footer>
                        <Card.Text className=" d-inline">
                            {t('CARD_LAST_UPDATED', { when: moment(status).fromNow() })}
                        </Card.Text>
                        <Dropdown className="dropdown custom-dropdown ms-auto">
                            <Dropdown.Toggle as="div" className="btn sharp tp-btn ">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                        stroke="#194039"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                        stroke="#194039"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                        stroke="#194039"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                <Dropdown.Item key="0-view" onClick={onView}>
                                    {t('DEFAULT_VIEW_CARD_ACTION')}
                                </Dropdown.Item>
                               
                                {console.log('menuItems', menuItems)}

                                {menuItems.map((item, index) => (
                                    <Dropdown.Item key={`item-${index}`} onClick={item.action}>
                                        {item.title}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Card.Footer>
                )}
            </Card>
        </Col>
    );
}

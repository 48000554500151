const buildProjectImage = (project) => {
    if (!project?.imagePath) return project;
    if (project.imageUrl.includes('/file/v2')) return { ...project, image: project.imageUrl };
    return { ...project, image: `${project.imageUrl}/file/v2/projects/${project.imagePath}` };
};

export const getProjectById = (state, projectId) => {
    const project = state.projects?.projects?.find((project) => project._id === projectId);
    if (project) return buildProjectImage(project);
};

export const getAllProjects = (state) => {
    const projects = state.projects.projects;
    if (!projects || !Array.isArray(projects)) return [];
    return projects.map(buildProjectImage);
};

/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Col, Container, Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateMintAction } from '../../../store/actions/DropActions';
import { deleteNftAction } from '../../../store/actions/NftActions';

import { useHistory } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { useUpdateSubtitle } from '../../../context/MenuTitle';
import { getNetworks } from '../../../store/selectors/AuthSelectors';
import { getDropById } from '../../../store/selectors/DropSelector';
import { getNftsByDropId } from '../../../store/selectors/NftSelectors';

import { useTranslation } from 'react-i18next';
import FileInput from '../../components/Common/FileInput';



const useMintForm = () => {
    const [setSubtitle] = useUpdateSubtitle();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [collections] = useState([]);
    const [nfts, setNfts] = useState([]);

    const [ projectId, setProjectId] = useState([]);
    const [ collectionId, setCollectionId] = useState([]);

    const [image, setImage] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    let { id: mintId } = useParams();

   
    const networks = useSelector(getNetworks);
    const mint = useSelector((state) => getDropById(state, mintId));
    const projectsAll = useSelector((state) => state.projects.projects);
    const collectionsAll = useSelector((state) => state.collections.collections);
    const nftsAll = useSelector((state) => getNftsByDropId(state, mintId));
    

    const selectProject = (projectId) => {
        const newSelectedProject = projectsAll.find((project) => project._id === projectId);
        setProjectId(newSelectedProject._id);
    };

    const selectCollection = (collectionId) => {
        const newSelectedCollection = collectionsAll.find((collection) => collection._id === collectionId);
        setCollectionId(newSelectedCollection._id);
    };

    useEffect(() => {
        selectProject(projectsAll[0]._id);
    }, []);
    
    


    useEffect(() => {
        if (mint) {
            setSubtitle(mint.name);
            setName(mint.name);
            setDescription(mint.description);
            //setCollections(projectCollections);
            setImage(mint.image);
        } else {
            setSubtitle('New Mint');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mintId, mint?._id, networks]);

    const onSave = () => {

         const dropData ={
            name: name, 
            description : description, 
            image: image, 
            dropType: 'fifo',
            useCodes: 0 ,
            isDirectMinting: 0 ,
            price: '1',
            state: 'mint',
            coin: 'usdc',
            startDate: "2022-06-12T00:00:00.000+00:00",
            endDate: "2022-08-14T00:00:00.000+00:00",
         }

        dispatch(updateMintAction(dropData, mintId, history));
        history.push(`/mints`);
    };

    const onDrop = () => {
        const dropData ={
            name: name, 
            description : description, 
            image: image,
            dropType: 'fifo',
            useCodes: 0 ,
            isDirectMinting: 0 ,
            price: '1',
            state: 'mint',
            coin: 'usdc',
            startDate: "2022-06-12T00:00:00.000+00:00",
            endDate: "2022-08-14T00:00:00.000+00:00", 
         }

        dispatch(updateMintAction(dropData, mintId, history));
        history.push(`/drop/${mintId}`);

    };

    const removeDraftNft =( index) =>{

        dispatch(deleteNftAction(nftsAll[index]._id))
    };

    return {
        mintId,
        collectionId,
        name,
        description,
        image,
        collections,
        projectsAll,
        collectionsAll,
        selectCollection,
        selectProject,
        nfts,
        nftsAll,
        setNfts,
        setName,
        setDescription,
        setImage,
        onSave,
        onDrop,
        removeDraftNft,
        history,
    };
};



const ViewMint = () => {
    const {
        projectId,
        collectionId,
        collectionsAll,
        name,
        description,
        image,
        //nfts,
        nftsAll,
        setName,
        projectsAll,
        setDescription,
        selectProject,
        selectCollection,
        setImage,
        onSave,
        onDrop,
        removeDraftNft,
        history,
    } = useMintForm();



    const { t } = useTranslation();
    return (
        <form>
            <Card className="p-3">
                <Row>
                    <Col md={4} sm={4} xs={12} style={{}}>
                        <Form.Label htmlFor="description" className="text-primary">
                            {t('PROJECTS_FORM_EDIT_THUMBNAIL')}
                        </Form.Label>
                        <div className="thumbnail-centered">
                            <FileInput
                                initialFile={image}
                                onFileAdded={({ file }) => setImage(file)}
                                onFileRemoved={() => setImage()}
                            />
                        </div>
                    </Col>
                    <Col md={4} sm={4} xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description" className="text-primary">
                                {t('PROJECTS_FORM_NAME')}
                            </Form.Label>

                            <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="">
                            <Form.Label htmlFor="description" className="text-primary">
                                {t('PROJECTS_FORM_DESCRIPTION')}
                            </Form.Label>

                            <Form.Text
                                as="textarea"
                                name="description"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                style={{ height: '150px' }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={4} sm={4} xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description" className="text-primary">
                                Link it to
                            </Form.Label>

                             <p>A project</p>
                            <Row>
                                <Col sm={10} xs={6}>
                                    <select
                                        className="form-control mt-1 selecto"
                                        onChange={(event) => selectProject(event.target.value)}
                                        value={projectId}
                                        multiple={false}
                                    >
                                        {projectsAll.map(({ _id, name }) => (
                                            <option key={_id} value={_id}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                             <p>A Collection</p>
                            <Row>
                                <Col sm={10} xs={6}>
                                    <select
                                        className="form-control mt-1 selecto"
                                        onChange={(event) => selectCollection(event.target.value)}
                                        value={collectionId}
                                        multiple={false}
                                    >
                                        {collectionsAll.map(({ _id, name }) => (
                                            <option key={_id} value={_id}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                
                            </Row>
                         </Form.Group>
                         <Form.Group className="mb-3">
                             <p>A Drop</p>
                            <Row>
                                <Col sm={10} xs={6}>
                                    <select
                                        className="form-control mt-1 selecto"
                                        onChange={(event) => selectProject(event.target.value)}
                                        value={projectId}
                                        multiple={false}
                                    >
                                        {projectsAll.map(({ _id, name }) => (
                                            <option key={_id} value={_id}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                
                            </Row>
                            </Form.Group>                            

                    
                    </Col>
                </Row>
            </Card>


            {/* section nfts draft table */}

            <Row>
                <Col xs={12}>
                    <Card className="p-3">
                        <div className="title-and-action">
                            <Form.Label className="text-primary">{t('NFT_TABLE_TITLE')}({nftsAll.length})</Form.Label>
                        </div>
                        
                        {nftsAll.length>0 ? (<>

                        <Container className="mt-2 min-300">
                            <Row className="row row-cols-sm-2 row-cols-md-4 row-cols-md-4 gy-3  g-xs-1 g-md-3 flex-wrap">
                                <Col md={8} sm={8} xs={12}>     
                                    <Table striped bordered hover>
                                    <thead>
                                            <tr>
                                                <th></th>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Attributes</th>
                                                <th>remove</th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            
                                        {nftsAll.length ? (
                                            nftsAll.map((nft, index) => ( <>
                                        
                                            <tr> 
                                               
                                                <td><img src={nft.media.fileUrl} className="img-list-nfts" alt="" /></td>    
                                                <td>{nft._id.slice(nft._id.length - 5)}</td>
                                                <td>{nft.name}</td>
                                                <td>{nft.state}</td>
                                                <td>{nft.metadata.attributes}</td>
                                                <td>
                                                        <Button
                                                                type="button"
                                                                className="mt-2 mb-1"
                                                                variant="outline-primary"
                                                                 onClick={() => removeDraftNft(index)}
                                                            >
                                                            Remove                          
                                                        </Button>                                                    
                                                      
                                                    </td>
                                                    
                                            </tr>
                                                </>
                                                
                                            ))
                                        ) : (
                                            <p>{t('NFT_FORM_EDIT_MINTS_EMPTY')}</p>
                                        )}
                                    
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                        </>) :(
                                            <p>{t('NFT_FORM_EDIT_MINTS_EMPTY')}</p>
                                        )}
                    </Card>
                </Col>
            </Row>



            <Row>
                <Col xs={12} className={'d-flex justify-content-end mb-3'}>
                    <Button
                        disabled={!name.trim()}
                        type="button"
                        className="me-2"
                        variant="primary"
                        onClick={() => onSave()}
                    >
                        Save
                    </Button>
                    <Button
                            disabled={!name.trim()}
                            type="button"
                            className="me-2"
                            variant="outline-primary"
                            onClick={() => onDrop()}
                        >
                        Save and Drop
                        </Button>
                        
                </Col>
            </Row>
        </form>
    );
};

export default ViewMint;
import {
    CONFIRMED_ADD_MEDIA_ACTION,
    CONFIRMED_DELETE_MEDIA_ACTION,
    CONFIRMED_EDIT_MEDIA_ACTION,
    CONFIRMED_GET_MEDIA,
    CONFIRMED_GET_MEDIAS,
    ADD_MEDIA_ACTION,
} from '../actions/MediaTypes';

const initialState = {
    medias: [],
};

export default function MediasReducer(state = initialState, actions) {
    if (actions.type === ADD_MEDIA_ACTION) {
        const media = {
            id: Math.random(),
            title: 'Media Title 2asdasd',
            description: 'Sample Description 2asdasdas',
        };

        const medias = [...state.medias];
        medias.push(media);
        return {
            ...state,
            medias,
        };
    }

    if (actions.type === CONFIRMED_DELETE_MEDIA_ACTION) {
        const medias = [...state.medias];
        const mediaIndex = medias.findIndex((media) => media.id === actions.payload);

        medias.splice(mediaIndex, 1);

        return {
            ...state,
            medias,
        };
    }

    if (actions.type === CONFIRMED_EDIT_MEDIA_ACTION) {
        const medias = [...state.medias];
        const mediaIndex = medias.findIndex((media) => media.id === actions.payload.id);

        medias[mediaIndex] = actions.payload;
        return {
            ...state,
            medias,
        };
    }

    if (actions.type === CONFIRMED_ADD_MEDIA_ACTION) {
        const medias = [...state.medias];
        medias.push(actions.payload);

        return {
            ...state,
            medias,
        };
    }

    if (actions.type === CONFIRMED_GET_MEDIA) {
        return {
            ...state,
            medias: actions.payload,
        };
    }

    if (actions.type === CONFIRMED_GET_MEDIAS) {
        return {
            ...state,
            medias: actions.payload,
        };
    }

    return state;
}

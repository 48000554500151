/* eslint-disable no-undef */

import swal from 'sweetalert';
import { formatError } from '../../services/AuthService';
import {
    createDrop,
    getDrops,
    deleteDrop,
    updateDrop,
} from '../../services/DropsService';
import {addDraftNftToDropAction, addBulkNftToDropAction} from './NftActions';
import {
    CONFIRMED_CREATE_DROP_ACTION,
    CONFIRMED_DELETE_DROP_ACTION,
    CONFIRMED_EDIT_DROP_ACTION,
    CONFIRMED_GET_DROPS,
    CONFIRMED_GET_DROP_API,
    ERROR_CREATE_DROP_ACTION,
    SET_CURRENT_DROP_ACTION,
} from './DropTypes';
import { deployWalletAction } from '../../store/actions/WalletsActions';
import { deployCollectionAction } from '../../store/actions/CollectionActions';




export function getDropAction() {
    return (dispatch) => {
        return new Promise((resolve) => {
            getDrops().then((response) => {
                dispatch(confirmedGetDropsAction(response.data));
                resolve(response.data.drops);
            });
        });
    };
}
export function deleteDropAction(dropId, history) {
    return (dispatch) => {
        deleteDrop(dropId)
            .then((data) => {
                if (data.status === 'failed') throw data;
                dispatch(confirmedDeleteDropAction(dropId));
                swal('Yay!', 'Drop deleted successfully', 'success');
                history.push('/drops');
            })
            .catch((error) => handleFailure(error, dispatch, ERROR_CREATE_DROP_ACTION));
    };
}

const handleFailure = (error, dispatch, action) => {
    console.error(`🐛 | drop api | error`, error);
    const errorMessage = formatError(error);
    dispatch({ type: action, payload: errorMessage });
    return error;
};



export function createDropAction(dropData, collectionId, walletId, nfts, history, goToNewDrop) {


    return (dispatch) => {
            createDrop(dropData, collectionId)
            .then ( async (response) => {
                
                console.log('resposnse', response)

                if (!!response.data.status || response.data.status == 'failed') {
                    swal('error creating drop', response.data.error, 'error');
                    return;
                }
                
                dispatch(confirmedCreateDropsAction(response.data)); 
                

                for(let i=0; i<nfts.length; i++) {
                    
                    console.log('about to sabfe nft', nfts[i].name); 
                   // console.log('dropId', dropId);
                    dispatch(addDraftNftToDropAction(
                        collectionId,
                        walletId, 
                        response.data, 
                        nfts[i], 
                        history, 
                        goToNewDrop
                        ));
                }

               if (history) {
                    const newRoute = goToNewDrop ? `/add-collection?drop=${data._id}` : '/mints';
                    history.push(newRoute);
                }
            })
            .catch((error) => {
                swal('error adding nft in draft', error, 'error');
                console.error(error, JSON.stringify(error));
                handleFailure(
                    { error: error.status ? error.error : error.message },
                    dispatch,
                    ERROR_CREATE_DROP_ACTION,
                );
            });

    }
}


export function createDropActionBulk(dropData, collectionId,  csvFile, zipFile, walletId, history, goToNewDrop) {
  
    return (dispatch) => {
            createDrop(dropData, collectionId)
            .then ( async (response) => {
                
              

                if (!!response.data.status || response.data.status == 'failed') {
                    swal('error creating drop', response.data.error, 'error');
                    return;
                }
                
                dispatch(confirmedCreateDropsAction(response.data)); 
                
                
                const bulkResponse = dispatch(addBulkNftToDropAction( collectionId, csvFile, zipFile, response.data._id ));
                
                if(bulkResponse == true) {
                   console.log('good bulk')
                }
                 

               if (history) {
                    const newRoute = goToNewDrop ? `/add-collection?drop=${data._id}` : `/mints`;
                    history.push(newRoute);
                }
            })
            .catch((error) => {
                swal('error adding nft in draft', error, 'error');
                console.error(error, JSON.stringify(error));
                handleFailure(
                    { error: error.status ? error.error : error.message },
                    dispatch,
                    ERROR_CREATE_DROP_ACTION,
                );
            });

    }
}





export function updateMintAction(dropData, dropId ) {
    return (dispatch) => {
       
        updateDrop(dropData, dropId)
            .then(({ data }) => {
                if (String(data.status).toLowerCase() === 'failed') throw data;
                dispatch(confirmedUpdateDropAction(data));
            })
            .catch((error) => {
                console.error(error, JSON.stringify(error));
                handleFailure(
                    { error: error.status ? error.error : error.message },
                    dispatch,
                    ERROR_CREATE_DROP_ACTION,
                );
            });
    };
}


export function updateDropAction(dropId, dropData) {
    return (dispatch) => {
       
        updateDrop(dropData, dropId)
            .then(({ data }) => {
                if (String(data.status).toLowerCase() === 'failed') throw data;
                dispatch(confirmedUpdateDropAction(data));
               // history.push('/drops');
            })
            .catch((error) => {
                console.error(error, JSON.stringify(error));
                handleFailure(
                    { error: error.status ? error.error : error.message },
                    dispatch,
                    ERROR_CREATE_DROP_ACTION,
                );
            });
    };
}

export function deployDropAction(project, wallet, contract, dropId, dropData, history) {
    return (dispatch) => {

        
        console.log('wallet', wallet)
        console.log('dropId', dropId)
        console.log('dropId', dropData)
        console.log('dropId', history)

       
        
        /* check if contract id deployed */
        if ( contract.status == 'draft') {
             
             console.log('contract on draft')
            
             if ( wallet.status == 'draft') {
             /*deploy wallet */
                
               dispatch(deployWalletAction(wallet._id))
             }

           /* deploy contract */
           dispatch(deployCollectionAction(contract._id))

        }
    


{/**
        updateDrop(dropData, dropId)
            .then(({ data }) => {
                if (String(data.status).toLowerCase() === 'failed') throw data;
                dispatch(confirmedUpdateDropAction(data));
               // history.push('/drops');
            })
            .catch((error) => {
                console.error(error, JSON.stringify(error));
                handleFailure(
                    { error: error.status ? error.error : error.message },
                    dispatch,
                    ERROR_CREATE_DROP_ACTION,
                );
            });
             */}
    };
}




export function confirmedDeleteDropAction(dropId) {
    return {
        type: CONFIRMED_DELETE_DROP_ACTION,
        payload: dropId,
    };
}

export function confirmedCreateDropsAction(singleDrop) {
    return {
        type: CONFIRMED_CREATE_DROP_ACTION,
        payload: singleDrop,
    };
}

export function confirmedGetDropsAction(drops) {
    return {
        type: CONFIRMED_GET_DROPS,
        payload: drops,
    };
}

export function confirmedUpdateDropAction(drop) {
    return {
        type: CONFIRMED_EDIT_DROP_ACTION,
        payload: drop,
    };
}


export function confirmedGetDropApiAction(apikey) {
    return {
        type: CONFIRMED_GET_DROP_API,
        payload: apikey,
    };
}

export function setCurrentDropAction(drop) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_DROP_ACTION,
            payload: drop,
        });
    };
}

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import PostsReducer from './reducers/PostsReducer';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import ProjectsReducer from './reducers/ProjectsReducer';
import CollectionsReducer from './reducers/CollectionsReducer';
import CompanysReducer from './reducers/CompanysReducer';
import NftsReducer from './reducers/NftsReducer';
import WalletsReducer from './reducers/WalletsReducer';
import MediasReducer from './reducers/MediaReducer';
import UsersReducer from './reducers/UsersReducer';
import DemoReducer from './reducers/DemoReducer';
import TiersReducer from './reducers/TiersReducer';
import DropsReducer from './reducers/DropsReducer';

import { LOGOUT_ACTION, CLEAR_STORE_ACTION  } from './actions/AuthTypes';


const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
    todoReducers,
    projects: ProjectsReducer,
    collections: CollectionsReducer,
    companys: CompanysReducer,
    nfts: NftsReducer,
    wallets: WalletsReducer,
    media: MediasReducer,
    user: UsersReducer,
    demo: DemoReducer,
    tiers: TiersReducer,
    drops: DropsReducer,
    form: reduxFormReducer,
});

const rootReducer = (state, action) => {

    if (action.type === LOGOUT_ACTION || action.type === CLEAR_STORE_ACTION ) {
        state = undefined;
      }
    return appReducers(state, action);
  }

// const store = createStore(rootReducers);

export const store = createStore(rootReducer, composeEnhancers(middleware));

//import { createSelector } from 'reselect';

export const getNftById = (state, nftId) => state.nfts.nfts.find((nft) => nft.id === nftId);

//export const getNft = () => createSelector([getNftById], (nft) => nft);


export const getNftsByDropId = (state, dropId) => {
    return state.nfts.nfts.filter((nft) => nft.dropId === dropId);
};

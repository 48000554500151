import React, { useState,  Fragment } from 'react';
import {  ListGroup, Button } from 'react-bootstrap';
import { Col,  Card, Row } from 'react-bootstrap';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import { useDispatch } from 'react-redux';
import { createCompanyAction } from '../../../store/actions/CompanyActions.js';
import { useHistory } from 'react-router-dom';

const AddCompany = () => {
    const [name, setName] = useState('Company');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [taxId, setTaxId] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = (event) => {
        event.preventDefault(); // Prevent default submission
        console.log(event);

        const singleCompany = {
            name: name,
            country: country,
            address: address,
            taxId: taxId,
        };

        try {
            dispatch(createCompanyAction(singleCompany, history));
        } catch (e) {
            console.log(e);
            alert(`Company creation failed! ${e.message}`);
        }
    };

    return (
        <div className="badge-demo">
            <Breadcrumbs motherMenu="Companys" activeMenu="Add" pageContent="Add" />
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Body>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Company</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="basic-form">
                                            <form onSubmit={onSubmit}>
                                                <div className="mb-3 row">
                                                    <label className="col-sm-4 col-form-label">Name</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Name"
                                                            onChange={(e) => setName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label className="col-sm-4 col-form-label">Country</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Description"
                                                            onChange={(e) => setCountry(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <label className="col-sm-4 col-form-label">Adress</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Description"
                                                            onChange={(e) => setAddress(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label className="col-sm-4 col-form-label">Tax iD</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Description"
                                                            onChange={(e) => setTaxId(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <Button type="submit" className="me-2" variant="primary">
                                                    Next
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Company Info</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="basic-list-group">
                                                <ListGroup as="ul">
                                                    <Fragment key="1">
                                                        <ListGroup.Item as="li">
                                                            <i className="fa fa-exclamation-circle text-success" />
                                                            &nbsp; The name of the company corresponds to your Company,
                                                            Company Inside the Company if any or the na me of the Client
                                                            you are giving Service to.
                                                        </ListGroup.Item>
                                                    </Fragment>
                                                    <Fragment key="2">
                                                        <ListGroup.Item as="li">
                                                            <i className="fa fa-exclamation-circle text-success" />
                                                            &nbsp; The description is your reference to the NFT company
                                                            you are building.
                                                        </ListGroup.Item>
                                                    </Fragment>
                                                </ListGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AddCompany;

import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_SERVICE,
});

axiosInstance.interceptors.request.use((config) => {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    config.headers.Authorization = `Bearer ${tokenDetails.token}`;
    config.params = config.params || {};

    return config;
});

export default axiosInstance;

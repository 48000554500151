import axiosServiceApi from '../services/AxiosServiceApi';
import * as FormData from 'form-data';

const formConfig = {
    headers: {
        'content-type': 'application/x-www-form-urlencoded',
    },
};

const prepareNftsForm = (nftData) => {

    const { image, ...nft } = nftData;

    const form = new FormData();

    for (var key in nft) {
    //    console.log('data[key]', data[key])
        form.append(`nft[${key}]`, nft[key]);
    }
    if (image && typeof image !== 'string') {
        form.append('files', image, image.name);
    } else if (!image) {
        form.append('files', null);
    }
    return form;
};



const prepareBulkForm = (bulkData) => {
    const {csvFilename, zipFilename, dropId} = bulkData;
    
    const form = new FormData();

    form.append('files', csvFilename, csvFilename.name);
    form.append('files', zipFilename, zipFilename.name);
    if (dropId) form.append('dropId', dropId);

    return form;

};


export function getNfts(contractId, key) {
    return axiosServiceApi.get(`nfts/v2/${contractId}`, { headers: { Authorization: `Bearer ${key}` } });
}

export function createNft(nftData) {
    return axiosServiceApi.post(`nfts.json`, nftData);
}

export function saveNft(contractId, nft) {
    const form = prepareNftsForm(nft);
    return axiosServiceApi.post(`nfts/v2/${contractId}`, form, formConfig);
}

export function bulkUploadNft(contractId, bulkData) {


    console.log('form bulk', bulkData);
    const form = prepareBulkForm(bulkData);

    return axiosServiceApi.post(`nfts/v2/upload/${contractId}`, form, formConfig);
}



export function mintNft(nftData) {
    return axiosServiceApi.post(`nfts/mint`, nftData);
}

export function updateNft(nft, nftId) {
    return axiosServiceApi.put(`nfts/${nftId}.json`, nft);
}

export function deleteNft(nftId) {
    return axiosServiceApi.delete(`nfts/v2/nft/${nftId}`);
}


export function transferNft(nftId, nftData) {
    return axiosServiceApi.put(`nfts/${nftId}`, nftData);
}

import axiosInstance from './AxiosInstance';
import axiosInstanceNonAuth from './AxiosInsatanceNonAuth';

export function updateUser(userData, userId) {
    return axiosInstance.put(`users/v2/${userId}`, userData);
}

export function accountActivation(veriToken) {
    return axiosInstanceNonAuth.post(`users/v2/verifyEmail/${veriToken}`);
}

export function forgotPassword(userData) {
    return axiosInstanceNonAuth.post('users/v2/sendEmailPassUpdate/', { email: userData });
}

export function updatePassword(password, passwToken) {
    return axiosInstanceNonAuth.put(`users/v2/update-password/${passwToken}`, { password, passwToken });
}

export function getUser(userData) {
    return axiosInstance.get('users/v2', userData);
}

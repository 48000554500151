/* eslint-disable no-undef */
import { formatError, login, saveTokenInLocalStorage, signUp } from '../../services/AuthService';

import {
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGIN_SET_USER_PASSWORD_ACTION,
    LOADING_TOGGLE_ACTION,
    LOGOUT_ACTION,
} from './AuthTypes';
import swal from 'sweetalert';



import { getCompanysAction, createCompanyAction, setCurrentCompanyAction } from './CompanyActions';
import { getProjectAction,  } from './ProjectActions';
import { getCollectionsFromProjectAction } from './CollectionActions';
import { getWalletsFromProjectAction} from './WalletsActions';
import { getMediasFromProjectAction } from './MediaActions';
import { getAllUserData } from '../../services/AuthService';


async function getInitialData(dispatch) {
    // Get all data (companyies, projects, collections, wallet, media)
    let currentCompany = null;
    let currentProject = null;
    let currentProjectApiKey = null;
    const companyies = [];
    let collections = [];
    let wallets = [];
    let medias = [];
    let user = {};

    const _companyies = await dispatch(getCompanysAction());
    if (_companyies) companyies.push(_companyies);
    const projects = await dispatch(getProjectAction());



    // We wanna 'zero' company
    if (companyies?.length > 0) {
        currentCompany = companyies.find((company) => company.name === 'zero');
        if (currentCompany) {
            // Set current company
            dispatch(setCurrentCompanyAction(currentCompany));
        }
    }
    // hem de retornar totes els wallets i les collecions
    for (let i =0; i <projects.length; i++) { 
        await dispatch(getCollectionsFromProjectAction(projects[i]._id));
        await dispatch(getWalletsFromProjectAction(projects[i]._id));
        await dispatch(getMediasFromProjectAction(projects[i]._id));
    }


    const initialData = {
        companyies,
        currentCompany,
        projects,
        currentProject,
        currentProjectApiKey,
        collections,
        wallets,
        medias,
        user,
    };

    return initialData;
}

async function setup(dispatch) {
    // Create company
    const companyParams = {
        name: 'zero',
        // country: '',
        // address: '',
        // taxId: 0,
    };
    await dispatch(createCompanyAction(companyParams, null));

}

function getSetupResult(data) {

    // Check zero company
    if (!data.companyies) return { success: false, error: 'Companies could not be obtained' };
    const zeroCompany = data.companyies.find((company) => company.name === 'zero');
    if (!zeroCompany) return { success: false, error: 'Company "zero" does not exist' };
  
    return { success: true, error: '' };
}

export function signupAction(email, password) {
    return (dispatch) => {
        signUp(email, password)
            .then(async (response) => {
                if (response.data.status === 'failed') {
                    swal('Sign up Error', response.data.error, 'error');
                    dispatch(signupFailedAction(response.data.error));
                } else {
                    // eslint-disable-next-line no-unused-vars
                    const userData = response.data;
                }
            })
            .catch((error) => {
                console.log('error', error);
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}




export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then(async (response) => {
                if (!!response.data.status || response.data.status === 'Failed') {
                    swal('Sign in Error', response.data.error, 'error');
                    dispatch(loginFailedAction(response.data.error));
                    return;
                }

                const userData = response.data
                console.log('userDataa', userData);

                saveTokenInLocalStorage(userData);
                dispatch(loginConfirmedAction(userData));
             
               //get All user Data is
               await getAllUserData(userData, dispatch);

                // get InitialData -> 
                const initialData = await getInitialData(dispatch);

                const ret = getSetupResult(initialData);
                if (ret.success) {
                    dispatch(setUserPasswordAction(password));
                    history.push('/dashboard');
                } else {
              
                    await setup(dispatch);
                  
                    // Try Again
                    const initialData = await getInitialData(dispatch);
                    const ret = getSetupResult(initialData);
                    if (ret.success) {
                        dispatch(setUserPasswordAction(password));
                        history.push('/dashboard');
                    } else {
                        swal('Sign in Error', ret.error, 'error');
                        dispatch(loginFailedAction(ret.error));
                    }
                
                }
            })
            .catch((error) => {
                console.error(`🐛 | loginAction | error`, error);
                const errorMessage = formatError(error?.response?.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}


export function logoutAction(history) {
    return (dispatch) => {
        localStorage.removeItem('userDetails');
        dispatch({ type: LOGOUT_ACTION, payload: null });
        if (history) {
            history.push('/login');
        }
    };
}



export function setUserPasswordAction(password) {
    return (dispatch) => {
        dispatch({ type: LOGIN_SET_USER_PASSWORD_ACTION, payload: password });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {

    console.log('dsts', data);
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

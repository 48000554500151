import { createCompany, getCompanys, deleteCompany, updateCompany } from '../../services/CompanysService';
import {
    CONFIRMED_CREATE_COMPANY_ACTION,
    CONFIRMED_DELETE_COMPANY_ACTION,
    CONFIRMED_EDIT_COMPANY_ACTION,
    CONFIRMED_GET_COMPANYS_ACTION,
    SET_CURRENT_COMPANY_ACTION,
} from './CompanyTypes';

export function deleteCompanyAction(companyId, history) {
    return (dispatch) => {
        deleteCompany(companyId).then(() => {
            dispatch(confirmedDeleteCompanyAction(companyId));
            history.push('/company-info');
        });
    };
}

export function confirmedDeleteCompanyAction(companyId) {
    return {
        type: CONFIRMED_DELETE_COMPANY_ACTION,
        payload: companyId,
    };
}

export function createCompanyAction(companyData, history) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            createCompany(companyData).then(
                (response) => {
                    const singleCompany = {
                        ...response.data,
                    };
                    dispatch(confirmedCreateCompanyAction(singleCompany));
                    if (history) {
                        history.push('/company-info');
                    }
                    resolve(singleCompany);
                },
                (err) => {
                    console.log('createCompanyAction -> Error', err);
                    reject(err);
                },
            );
        });
    };
}
export function confirmedCreateCompanyAction(singleCompany) {
    return {
        type: CONFIRMED_CREATE_COMPANY_ACTION,
        payload: singleCompany,
    };
}

export function getCompanysAction() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            getCompanys().then(
                (response) => {
                    let companys = response.data;
                    dispatch(confirmedGetCompanysAction(companys));
                    resolve(companys);
                },
                (err) => {
                    console.log('getCompanysAction -> Error', err);
                    reject(err);
                },
            );
        });
    };
}
export function confirmedGetCompanysAction(companys) {
    return {
        type: CONFIRMED_GET_COMPANYS_ACTION,
        payload: companys,
    };
}

export function updateCompanyAction(company, history) {
    return (dispatch) => {
        updateCompany(company, company.id).then(() => {
            dispatch(confirmedUpdateCompanyAction(company));
            history.push('/compan-info');
        });
    };
}
export function confirmedUpdateCompanyAction(company) {
    return {
        type: CONFIRMED_EDIT_COMPANY_ACTION,
        payload: company,
    };
}

export function setCurrentCompanyAction(company) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_COMPANY_ACTION,
            payload: company,
        });
    };
}

/* eslint-disable no-unused-vars */
import React from 'react';
import MediaRow from '../Media/MediaRow';

export default function MediaTable({ header, indexes, medias, edit }) {
    const mediaRows = medias.map((media, indexes) => {
        return (
            <MediaRow
                key={indexes}
                media={media}
                indexes={indexes}
            />
        );
    });

    const tableHeader = header.map((item, index) => (
        <th key={index}>
            <strong>{item}</strong>
        </th>
    ));

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-responsive-md">
                    <thead>
                        <tr>{tableHeader}</tr>
                    </thead>

                    <tbody>{mediaRows}</tbody>
                </table>
            </div>
        </div>
    );
}

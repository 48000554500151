import axiosServiceApi from '../services/AxiosServiceApi';

import * as FormData from 'form-data';

const formConfig = {
    headers: {
        'content-type': 'application/x-www-form-urlencoded',
    },
};

const prepareDropForm = (dropData) => {
    const { image, ...data } = dropData;
    const form = new FormData();
    for (var key in data) {
        form.append(key, data[key]);
    }
    if (image && typeof image !== 'string') {
        form.append('thumb', image, image.name);
    } else if (!image) {
        form.append('thumb', null);
    }
    return form;
};

export function getDrops(contractId) {
    return axiosServiceApi.get(`drops/v2/contract/${contractId}`);
}

export function createDrop(dropData, contractId) {
    const form = prepareDropForm(dropData);
    return axiosServiceApi.post(`drops/v2/contract/${contractId}`, form, formConfig);
}


export function updateDrop(dropData, dropId) {
    const form = prepareDropForm(dropData);
    return axiosServiceApi.put(`drops/v2/${dropId}`, form, formConfig);
}

export function deleteDrop(dropId) {
    return axiosServiceApi.delete(`drops/v2/${dropId}.json`);
}

export function getProjectApi(projectId) {
    return axiosServiceApi.get(`projects/v2/apikey/${projectId}`);
}


/* eslint-disable no-undef */

import swal from 'sweetalert';
import { formatError } from '../../services/AuthService';
import {
    createProject,
    getProjectApi,
    getProjects,
    deleteProject,
    updateProject,
} from '../../services/ProjectsService';

import {
    CONFIRMED_CREATE_PROJECT_ACTION,
    CONFIRMED_DELETE_PROJECT_ACTION,
    CONFIRMED_EDIT_PROJECT_ACTION,
    CONFIRMED_GET_PROJECTS,
    CONFIRMED_GET_PROJECT_API,
    ERROR_CREATE_PROJECT_ACTION,
    SET_CURRENT_PROJECT_ACTION,
} from './ProjectTypes';

import {addWalletAction, setCurrentWalletAction} from '../actions/WalletsActions'



export function getProjectAction() {
    return (dispatch) => {
        return new Promise((resolve) => {
            getProjects().then((response) => {
                dispatch(confirmedGetProjectsAction(response.data));
                resolve(response.data.projects);
            });
        });
    };
}
export function deleteProjectAction(projectId, history) {
    return (dispatch) => {
        deleteProject(projectId)
            .then((data) => {
                if (data.status === 'failed') throw data;
                dispatch(confirmedDeleteProjectAction(projectId));
                swal('Yay!', 'Project deleted successfully', 'success');
                history.push('/projects');
            })
            .catch((error) => handleFailure(error, dispatch, ERROR_CREATE_PROJECT_ACTION));
    };
}

const handleFailure = (error, dispatch, action) => {
    console.error(`🐛 | project api | error`, error);
    const errorMessage = formatError(error);
    dispatch({ type: action, payload: errorMessage });
    return error;
};



export function createProjectAction(projectData, history, goToNewProject) {
    return (dispatch) => {
            createProject(projectData)
            .then ( async (response) => {
                
                console.log('resposnse', response.data)

                if (!!response.data.status && response.data.status === 'Failed') {
                    swal('error creating project', response.data.error, 'error');
                    return;
                }
                dispatch(setCurrentProjectAction(response.data)); 
               
                await dispatch(getProjectApiAction(response.data._id));

                
                // Create a wallet for this project
                const singleWallet = {
                    refUser: 'Onchain Wallet',
                    walletType: 'onchain',
                };

               const wall = await dispatch(addWalletAction(singleWallet, null))
               dispatch(setCurrentWalletAction(wall)); 

                dispatch(confirmedCreateProjectsAction(response.data)); 
               if (history) {
                    const newRoute = goToNewProject ? `/add-collection?project=${data._id}` : '/projects';
                    history.push(newRoute);
                }
            })
            .catch((error) => {
                console.error(error, JSON.stringify(error));
                handleFailure(
                    { error: error.status ? error.error : error.message },
                    dispatch,
                    ERROR_CREATE_PROJECT_ACTION,
                );
            });

        }
    }
   




export function updateProjectAction(project, history) {
    return (dispatch) => {
        const { name, description, image } = project;
        updateProject({ name, description, image }, project._id)
            .then(({ data }) => {
                console.log(`🐛 | .then | data`, data);
                if (String(data.status).toLowerCase() === 'failed') throw data;
                dispatch(confirmedUpdateProjectAction(data));
                history.push('/projects');
            })
            .catch((error) => {
                console.error(error, JSON.stringify(error));
                handleFailure(
                    { error: error.status ? error.error : error.message },
                    dispatch,
                    ERROR_CREATE_PROJECT_ACTION,
                );
            });
    };
}

export function confirmedDeleteProjectAction(projectId) {
    return {
        type: CONFIRMED_DELETE_PROJECT_ACTION,
        payload: projectId,
    };
}

export function confirmedCreateProjectsAction(singleProject) {
    return {
        type: CONFIRMED_CREATE_PROJECT_ACTION,
        payload: singleProject,
    };
}

export function confirmedGetProjectsAction(projects) {
    return {
        type: CONFIRMED_GET_PROJECTS,
        payload: projects,
    };
}

export function confirmedUpdateProjectAction(project) {
    return {
        type: CONFIRMED_EDIT_PROJECT_ACTION,
        payload: project,
    };
}

export function getProjectApiAction(projectId) {
    return async (dispatch) => {
        let apiKey = null;
        try {
            const response = await getProjectApi(projectId);
            apiKey = response.data;
            dispatch(confirmedGetProjectApiAction(apiKey));
        } catch (error) {
            dispatch(errorHandle(error));
        }
        return apiKey;
    };
}
export function confirmedGetProjectApiAction(apikey) {
    return {
        type: CONFIRMED_GET_PROJECT_API,
        payload: apikey,
    };
}

export function setCurrentProjectAction(project) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_PROJECT_ACTION,
            payload: project,
        });
    };
}

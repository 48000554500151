import axiosServiceApi from '../services/AxiosServiceApi';

import * as FormData from 'form-data';

const formConfig = {
    headers: {
        'content-type': 'application/x-www-form-urlencoded',
    },
};

const prepareCollectionForm = (collectionData) => {

    const { image, ...data } = collectionData;

    console.log('image', image);

    const form = new FormData();
    for (var key in data) {
        form.append(key, data[key]);
    }
    if (image && typeof image !== 'string') {
        console.log('$$$$#### with oimage')
        form.append('thumb', image, image.name);
    } else if (!image) {
        form.append('thumb', null);
    }
    return form;
};


export function getCollectionsFromProject(projectId) {
    return axiosServiceApi.get(`contracts/v2/byProject/${projectId}`);
}


export function createCollection(collectionData) {
    const form = prepareCollectionForm(collectionData);
    return axiosServiceApi.post(`contracts/v2`, form, formConfig);
}

export function updateCollection(collection, collectionId) {
    const form = prepareCollectionForm(collection);
    return axiosServiceApi.put(`contracts/v2/${collectionId}`,form, formConfig);
}

export function deleteCollection(collectionId) {
    return axiosServiceApi.delete(`contracts/v2/${collectionId}.json`);
}

//export function saveCollection(formData) {
//    return axiosServiceApi.post(`contracts/v2`, formData);
//}

export function deployCollection(contractId) {
    return axiosServiceApi.put(`contracts/v2/deploy/${contractId}`, {});
}

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { getCompanysAction } from '../../../store/actions/CompanyActions';

/// Bootstrap
import { Col, Badge, Card, Row } from 'react-bootstrap';

import Breadcrumbs from '../../layouts/Breadcrumbs';

const CompanyInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const companys = useSelector((state) => state.companys.companys);

    const testcompany = {
        name: 'name',
        address: 'address',
        country: 'country',
        taxId: 'taxId',
    };

    const [company, setCompany] = useState([{ name: 'name', address: 'address', country: 'country', taxId: 'taxId' }]);

    useEffect(() => {
        if (companys === undefined || companys.length == 0) {
            try {
                dispatch(getCompanysAction());
            } catch (e) {
                alert(`fetching Projects  failed! ${e.message}`);
            }
        } else {
            console.log('saving data to state');
            setCompany([companys]);
        }
    }, [companys]);

    const onSubmit = (event) => {
        event.preventDefault(); // Prevent default submission

        try {
            dispatch(getCompanysAction());
        } catch (e) {
            alert(`fetching Projects  failed! ${e.message}`);
        }
    };

    const handleAdd = () => {
        if (company[0].name === '') {
            return (
                // eslint-disable-next-line react/jsx-no-undef
                <NormalButton
                    variant="contained"
                    onClick={() => history.push('/addcompany')}
                    text="Add Company Info"
                    style={{ minWidth: '100px' }}
                />
            );
        } else {
        }
    };

    return (
        <div className="badge-demo">
            <Breadcrumbs motherMenu="Company" activeMenu="Info" pageContent="Info" />
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Header className="d-block">
                            <Card.Title>Company Info</Card.Title>
                            <Card.Text className="mb-0 subtitle">
                                {/*  <Button type="submit" onClick ={onSubmit} className="me-2" variant="primary">FetchCompany</Button>
                                 */}
                            </Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                <p>
                                    <b>Company Name: </b> {company[0].name}
                                </p>
                                <p>
                                    <b>Company Adress: </b>
                                    {company[0].address}
                                </p>
                                <p>
                                    <b>Company Country: </b>
                                    {company[0].country}
                                </p>
                                <p>
                                    <b>Tax ID: </b>
                                    {company[0].taxId}
                                </p>
                                <br></br>
                                <p>
                                    <b>Available Projects: </b>
                                    {company[0].projectsAvailable}
                                </p>
                                <p>
                                    <b>Available Projects: </b>
                                    {company[0].projectsUsed}
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CompanyInfo;

import {
    CONFIRMED_CREATE_COMPANY_ACTION,
    CONFIRMED_DELETE_COMPANY_ACTION,
    CONFIRMED_EDIT_COMPANY_ACTION,
    CONFIRMED_GET_COMPANYS_ACTION,
    CREATE_COMPANY_ACTION,
    SET_CURRENT_COMPANY_ACTION,
} from '../actions/CompanyTypes';

const initialState = {
    companys: [],
    currentCompany: {},
};

export default function CompanysReducer(state = initialState, actions) {
    if (actions.type === CREATE_COMPANY_ACTION) {
        const company = {
            id: Math.random(),
            title: 'Company Title 2asdasd',
            description: 'Sample Description 2asdasdas',
        };

        const companys = [...state.companys];
        companys.push(company);
        return {
            ...state,
            companys,
        };
    }

    if (actions.type === CONFIRMED_DELETE_COMPANY_ACTION) {
        const companys = [...state.companys];
        const companyIndex = companys.findIndex((company) => company.id === actions.payload);

        companys.splice(companyIndex, 1);

        return {
            ...state,
            companys,
        };
    }

    if (actions.type === CONFIRMED_EDIT_COMPANY_ACTION) {
        const companys = [...state.companys];
        const companyIndex = companys.findIndex((company) => company.id === actions.payload.id);

        companys[companyIndex] = actions.payload;
        return {
            ...state,
            companys,
        };
    }

    if (actions.type === CONFIRMED_CREATE_COMPANY_ACTION) {
        // const companys = [...state.companys];
        // companys.push(actions.payload);
        const companys = actions.payload;
        return {
            ...state,
            companys,
        };
    }

    if (actions.type === CONFIRMED_GET_COMPANYS_ACTION) {
        return {
            ...state,
            companys: actions.payload,
        };
    }

    if (actions.type === SET_CURRENT_COMPANY_ACTION) {
        // var newdata = Object.assign({}, state);
        // newdata.currentCompany = Object.assign({}, actions.payload);
        // state = Object.assign({}, state, newdata);
        // return state;

        return {
            ...state,
            currentCompany: actions.payload,
        };
    }

    return state;
}

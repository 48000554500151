import { CONFIRMED_EDIT_USER_ACTION, CONFIRMED_GET_USER_ACTION } from '../actions/UserTypes';

const initialState = {
    user: {},
};

export default function UsersReducer(state = initialState, actions) {
    if (actions.type === CONFIRMED_EDIT_USER_ACTION) {
        const users = [...state.users];
        const userIndex = users.findIndex((user) => user.id === actions.payload.id);

        users[userIndex] = actions.payload;
        return {
            ...state,
            users,
        };
    }

    if (actions.type === CONFIRMED_GET_USER_ACTION) {
        const user = actions.payload;
       // const subscription = actions.payload.subscription;
        return {
            ...state,
            user,
       //     subscription,
        };
    }

    return state;
}

import { addMedia, getMedia, deleteMedia, updateMedia, getMediasFromProject } from '../../services/MediaService';
import {
    CONFIRMED_ADD_MEDIA_ACTION,
    CONFIRMED_DELETE_MEDIA_ACTION,
    CONFIRMED_EDIT_MEDIA_ACTION,
    CONFIRMED_GET_MEDIAS,
} from './MediaTypes';

import { getTask } from '../../services/TasksService';

export function deleteMediaAction(mediaId, projectId, history) {
    return (dispatch) => {
        deleteMedia(mediaId).then(() => {
            dispatch(confirmedDeleteMediaAction(mediaId));
            history.push('/mediapage');
        });
    };
}

export function confirmedDeleteMediaAction(mediaId) {
    return {
        type: CONFIRMED_DELETE_MEDIA_ACTION,
        payload: mediaId,
    };
}

export function createMediaAction(mediaData, history) {
    return (dispatch) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            let mediaResponse;
            try {
                mediaResponse = await addMedia(mediaData);
            } catch (error) {
                console.log('createMediaAction error', error);
                reject(error);
                return;
            }

            console.log('mediaResponse', mediaResponse);
            if (!!mediaResponse.data.status && mediaResponse.data.status.toLowerCase() == 'failed') {
                console.log('createMediaAction error (status)', mediaResponse.data.error);
                reject(`Media creation failed! ${mediaResponse.data.error}`);
                return;
            }

            let taskResponse,
                taskSuccessful = false;
            for (let i = 0; i < 20; i++) {
                try {
                    taskResponse = await getTask(mediaResponse.data.taskId);
                    console.log('tries', i);
                    if (taskResponse.data.state === 'running' || taskResponse.data.state === 'queued') {
                        await new Promise((r) => setTimeout(r, 5000));
                    } else {
                        taskSuccessful = true;
                        break;
                    }
                } catch (error) {
                    console.log('getTask error', error);
                }
            }

            if (!taskSuccessful) {
                console.log('createMediaAction error (timeout)');
                reject(`Creation media failed! Timeout error`);
                return;
            }

            let media = mediaResponse.data.media;
            console.log('media', media);
            media.url = taskResponse.data.contractAddress;
            dispatch(confirmedCreateMediaAction(media));

            if (history) history.push('/medias');

            resolve(media);
        });
    };
}

export function getMediasAction() {
    return (dispatch) => {
        getMedia().then((response) => {
            dispatch(confirmedGetMediasAction(response.data));
        });
    };
}

export function confirmedCreateMediaAction(singleMedia) {
    return {
        type: CONFIRMED_ADD_MEDIA_ACTION,
        payload: singleMedia,
    };
}

export function confirmedUpdateMediaAction(media) {
    return {
        type: CONFIRMED_EDIT_MEDIA_ACTION,
        payload: media,
    };
}

export function updateMediaAction(media, history) {
    return (dispatch) => {
        updateMedia(media, media.id).then(() => {
            dispatch(confirmedUpdateMediaAction(media));
            history.push('/mediapage');
        });
    };
}

export function getMediasFromProjectAction(projectId) {
    return (dispatch) => {
        return new Promise((resolve) => {
            getMediasFromProject(projectId).then((response) => {
                let medias = response.data;
                dispatch(confirmedGetMediasAction(medias));
                resolve(medias);
            });
        });
    };
}

export function confirmedGetMediasAction(medias) {
    return {
        type: CONFIRMED_GET_MEDIAS,
        payload: medias,
    };
}

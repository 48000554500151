import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserAction } from '../../../store/actions/UserActions';

function Profile() {
    // use effect que et carrega l'user?

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.user.user);
    const subscription = useSelector((state) => state.user.subscription);

    useEffect(() => {
        dispatch(getUserAction());
    }, []);

    useEffect(() => {
       // dispatch(getUserAction());
    }, [user]);

    const addCredit = (event) => {
        event.preventDefault(); // Prevent default submission
        // eslint-disable-next-line no-undef
        Paddle.Checkout.open({
            product: 26574,
            email: user.email,
            passthrough: '{"credit": "addingCredit"}',
            closeCallback() {
                dispatch(getUserAction());
            },
        });
    };

    const changeMembership = () => {
        history.push('/pricing');
    };

    const changeBillingInfo = () => {
        window.open(subscription.update_url, '_blank');
    };
    const cancelSubscription = () => {
        window.open(subscription.cancel_url, '_blank');
    };

    let ButtonGrups;

    console.log(user);


    if (user.subscription == 'free') {
        ButtonGrups = (
            <Button variant="primary" onClick={changeMembership}>
                Upgrade membership
            </Button>
        );
    }
    if (user.subscription == 'basic' || user.subescription == 'pro') {
        ButtonGrups = (
            <div className="btn-group" role="group" aria-label="Basic example">
                <Button variant="primary" onClick={changeMembership}>
                    Change subscription
                </Button>
                <Button variant="primary" onClick={changeBillingInfo}>
                    Update Billing info
                </Button>
                <Button variant="primary" onClick={cancelSubscription}>
                    Cancel Subscriptionn
                </Button>
            </div>
        );
    }


    return (
        <div>
            <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                    <div className="photo-content">
                        <div className="cover-photo" />
                    </div>
                    <div className="profile-info">
                        <div className="profile-photo">
                            <img src="../../../images/profile/1.jpeg" className="img-fluid rounded-circle" alt="" />
                        </div>
                        <div className="profile-details">
                            <div className="profile-name px-3 pt-2">
                                <h4 className="text-primary mb-0">email</h4>
                                <p>{user.role}</p>
                            </div>
                            <div className="profile-email px-2 pt-2">
                                <h4 className="text-muted mb-0">{user.email}</h4>
                            </div>
                            <div className="dropdown ms-auto" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="">
                        <p>
                            <span>Balance:</span> <b>{Math.round(user.balance * 100) / 100}</b>
                            {' MK'}
                        </p>
                        <Button onClick={addCredit} className="me-2" variant="primary">
                            Add credit{' '}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="profile-blog">
                        <p>
                            Membership: <b>{user.membership}</b>
                        </p>

                        {ButtonGrups}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;

import React from 'react';

import { Link } from 'react-router-dom';
/// Scroll
import { Dropdown } from 'react-bootstrap';

/// Image
// import profile from '../../../assets/images/knight.png';

import LogoutPage from './Logout';
import { useTitleData } from '../../../context/MenuTitle';
import { useTranslation } from 'react-i18next';
import { routes } from '../..';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';

const Header = () => {
    const { t } = useTranslation();
    const [subtitle] = useTitleData();

    return (
        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            <Switch>
                                {routes.map(({ url, title, subPath, redirectRoute }, i) => (
                                    <Route key={i} exact path={`/${url}`}>
                                        <Link
                                            className={`breadcrumb-link ${
                                                (!!subtitle || !!subPath) && 'with-subtitle'
                                            }`}
                                            to={`/${redirectRoute ?? url}`}
                                        >
                                            {t(title) || 'TITLE NOT FOUND'} {(!!subtitle || !!subPath) && ' >  '}
                                        </Link>

                                        {(!!subtitle || !!subPath) && (
                                            <h1 className="subtitle">{subtitle || subPath}</h1>
                                        )}
                                    </Route>
                                ))}
                            </Switch>
                        </div>
                        <ul className="navbar-nav header-right main-notification">
                            <Dropdown as="li" className="nav-item dropdown header-profile">
                                <Dropdown.Toggle
                                    variant=""
                                    as="a"
                                    className="nav-link i-false c-pointer"
                                    role="button"
                                    data-toggle="dropdown"
                                >
                                    <svg
                                        id="icon-user1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="text-primary"
                                        width={35}
                                        height={35}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                        <circle cx={12} cy={7} r={4} />
                                    </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="right" className="mt-2 dropdown-menu-end">
                                    <Link to="/profile" className="dropdown-item ai-icon">
                                        <svg
                                            id="icon-user1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="text-primary"
                                            width={18}
                                            height={18}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                            <circle cx={12} cy={7} r={4} />
                                        </svg>
                                        <span className="ms-2">Profile </span>
                                    </Link>

                                    <Link to="/pricing" className="dropdown-item ai-icon">
                                        <span className="ms-2">Pricing</span>
                                    </Link>
                                    {/* 
					 <Link to="/form-element" className="dropdown-item ai-icon">
							<svg
							id="icon-user1"
							xmlns="http://www.w3.org/2000/svg"
							className="text-primary"
							width={18}
							height={18}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							>
							<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
							<circle cx={12} cy={7} r={4} />
							</svg>

							<span className="ms-2">Add Project </span>
						</Link> */}
                                    {/* <hr />
						<Link to="/app-profile" className="dropdown-item ai-icon">
							<span className="ms-2">Zero project</span>
						</Link> */}
                                    {/* <hr /> */}

                                    <LogoutPage />
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Header;

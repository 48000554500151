/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Col, Card, Row } from 'react-bootstrap';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';

import { getMediasAction } from '../../../store/actions/MediaActions';
import MediaTable from '../Media/MediaTable';

const IndexMedia = () => {
    const projectsAll = useSelector((state) => state.projects.projects);

    const dispatch = useDispatch();
    const mediasAll = useSelector((state) => state.media.medias);
    const [, setMedias] = useState([
        { name: 'Test media', description: 'Description media test', createdAt: '20/11/2021' },
    ]);

    useEffect(() => {
        try {
            dispatch(getMediasAction());
        } catch (e) {
            alert(`error fetching media ${e.message}`);
        }
    }, []);

    useEffect(() => {
        if (mediasAll === undefined || mediasAll.length == 0) {
        } else {
            setMedias(mediasAll);
        }
    }, [mediasAll]);

    // eslint-disable-next-line react/jsx-key
    const projectsNames = projectsAll.map((project) => <option value={project._id}>{project.name}</option>);

    const tableHeader = [
        'image',
        'Name',
        // "id",
        // "ProjectId",
        'url',
    ];
    const tableIndexes = ['name', 'description', 'network', 'projectId'];

    const mediaView = mediasAll.map((media) => media.media);

    const onSubmit = (event) => {
        event.preventDefault(); // Prevent default submission

        try {
            dispatch(getMediasAction());
        } catch (e) {
            alert(`Collection creation failed! ${e.message}`);
        }
    };

    return (
        <div className="badge-demo">
            <Breadcrumbs
                breadcrumbs={[
                    { path: 'dashboard', title: 'Home' },
                    { path: 'medias', title: 'Media' },
                ]}
                activeMenu="medias"
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Header className="d-block">
                            <Card.Title>Media</Card.Title>
                            <br></br>
                        </Card.Header>
                        <Card.Body>
                            <MediaTable header={tableHeader} indexes={tableIndexes} medias={mediasAll} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default IndexMedia;

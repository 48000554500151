import React, { useState } from 'react';
import { Button, Card, Row, Col, Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createProjectAction } from '../../../store/actions/ProjectActions';
import { useHistory } from 'react-router-dom';

import FileInput from '../../components/Common/FileInput';
import { getNetworks } from '../../../store/selectors/AuthSelectors';
import { useTranslation } from 'react-i18next';
import { useUpdateSubtitle } from '../../../context/MenuTitle';
//import { setCurrentProjectAction, getProjectApiAction } from '../../../store/actions/ProjectActions';
//import {addWalletAction} from '../../../store/actions/WalletsActions'

const useProjectForm = () => {
    const [setSubtitle] = useUpdateSubtitle();
    setSubtitle('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [network, setNetwork] = useState('mumbai');
    const [image, setImage] = useState();
    const dispatch = useDispatch();
    const history = useHistory();

    const networks = useSelector(getNetworks);

    const  onCreate = async (goToNewCollection = false) => {

        dispatch(createProjectAction({
             name, description, network, image }, history, goToNewCollection));
  
    };

    return { name, description, network, setName, setDescription, setImage, setNetwork, onCreate, networks };
};

const AddEditProject = () => {
    const { name, description, network, setName, setDescription, setImage, setNetwork, onCreate, networks } =
        useProjectForm();
    const isValid = name.trim();
    const { t } = useTranslation();
    return (
        <form>
            <Row>
                <Card className="p-3">
                    <Row className="justify-content-between">
                        <Col sm={6} md={5} xs={12} className="mb-sm-3">
                            <Form.Label htmlFor="thumbnail" className="text-primary">
                                {t('PROJECTS_FORM_THUMBNAIL')}
                            </Form.Label>
                            <p className="mb-2">{t('PROJECTS_FORM_THUMBNAIL_DESCRIPTION')} </p>
                            <FileInput onFileAdded={({ file }) => setImage(file)} onFileRemoved={() => setImage()} />
                        </Col>
                        <Col sm={6} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="description" className="text-primary">
                                    {t('PROJECTS_FORM_NAME')}
                                </Form.Label>

                                <Form.Text
                                    as="input"
                                    name="name"
                                    rows="4"
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder={t('PROJECTS_FORM_NAME')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label htmlFor="description" className="text-primary">
                                    {t('PROJECTS_FORM_DESCRIPTION')}
                                </Form.Label>

                                <Form.Text
                                    as="textarea"
                                    name="description"
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                    style={{ height: '150px' }}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card>
            </Row>
            <Row>
                <Col xs={12} className="p-0">
                    <Card className="p-3">
                        <Form.Label className="text-primary"> {t('PROJECTS_FORM_NETWORK')}</Form.Label>
                        <p className="mb-0">{t('PROJECTS_FORM_NETWORK_DESCRIPTION')}</p>
                        <Container className="mt-2">
                            <Row className="row row-cols-sm-2 row-cols-md-4 row-cols-md-4 gy-3  g-xs-1 g-md-3 flex-wrap justify-content-between">
                                {networks.map(({ name, value, imgSrc, description }, idx) => (
                                    <Col key={idx}>
                                        <div
                                            id={`network-${value}`}
                                            className={`rounded border d-flex flex-column b border border-3 p-2  ${
                                                network === value ? 'border-primary border-3' : ''
                                            }`}
                                            onClick={() => setNetwork(value)}
                                            style={{ minHeight: '100%', height: 'auto' }}
                                        >
                                            <div className="thumbnail-container d-flex align-content-center flex-wrap">
                                                <img
                                                    src={imgSrc}
                                                    alt={name}
                                                    style={{ maxHeight: 100, maxWidth: '100%', width: 'auto' }}
                                                    className="mx-auto d-block"
                                                />
                                            </div>
                                            <h4 className="fs-4 mt-1 text-center">{name}</h4>
                                            <p className="fs-6">{t(description)}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={'d-flex justify-content-end mb-3'}>
                    <Button
                        disabled={!isValid}
                        type="button"
                        className="me-2"
                        variant="outline-primary"
                        onClick={() => onCreate()}
                    >
                        {t('PROJECTS_FORM_SAVE')}
                    </Button>
                    <Button
                        disabled={!isValid}
                        type="button"
                        className="me-2"
                        variant="primary"
                        onClick={() => onCreate(true)}
                    >
                        {t('PROJECTS_FORM_SAVE_2')}
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default AddEditProject;

import { getTiers, updateTierMember } from '../../services/TiersService';
import { CONFIRMED_GET_TIERS_ACTION, CONFIRMED_UPDATE_TIER_MEMBER_ACTION } from './TiersTypes';

export function confirmedGetTiersAction(tiers) {
    return {
        type: CONFIRMED_GET_TIERS_ACTION,
        payload: tiers,
    };
}

export function confirmedUpdateTierMember(tier) {
    return {
        type: CONFIRMED_UPDATE_TIER_MEMBER_ACTION,
        payload: tier,
    };
}

export function getTiersAction() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            getTiers().then(
                (response) => {
                    const tiers = response.data;
                    dispatch(confirmedGetTiersAction(tiers));
                    resolve(tiers);
                },
                (err) => {
                    console.log('getTiersAction -> Error', err);
                    reject(err);
                },
            );
        });
    };
}

export function updateTierMemberAction(tierData) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            updateTierMember(tierData).then(
                (response) => {
                    const tier = response.data;
                    dispatch(confirmedUpdateTierMember(tier));
                    resolve(tier);
                },
                (err) => {
                    console.log('UpdateTierMember -> Error', err);
                    reject(err);
                },
            );
        });
}

import axios from 'axios';
import { store } from '../store/store';

const axiosService = axios.create({
    baseURL: process.env.REACT_APP_API_SERVICE,
});

axiosService.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.token;

    config.headers.Authorization = `Bearer ${token}`;
    config.params = config.params || {};

    return config;
});

export default axiosService;

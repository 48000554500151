/* eslint-disable no-undef */
import { updateUser, forgotPassword, accountActivation, updatePassword, getUser } from '../../services/UsersService';
import {
    CONFIRMED_EDIT_USER_ACTION,
    CONFIRMED_ACTIVATED_ACCOUNT_ACTION,
    CONFIRMED_GET_USER_ACTION,
    CONFIRMED_GET_USER_MEMBERSHIP_ACTION,
} from './UserTypes';
import swal from 'sweetalert';

export function updateUserAction(User) {
    return (dispatch, ) =>
        new Promise((resolve, reject) => {
            updateUser(User, User.id).then(
                () => {
                    dispatch(confirmedUpdateUserAction(User));
                    resolve(User);
                },
                (err) => {
                    console.log('updateUserAction -> Error', err);
                    reject(err);
                },
            );
        });
}
export function confirmedUpdateUserAction(User) {
    return {
        type: CONFIRMED_EDIT_USER_ACTION,
        payload: User,
    };
}

export function getUserAction() {
    return (dispatch, ) =>
        new Promise((resolve, reject) => {
            getUser().then(
                (response) => {
                    dispatch({
                        type: CONFIRMED_GET_USER_ACTION,
                        payload: response.data,
                    });
                    resolve(response.data);
                },
                (err) => {
                    console.log('getUserAction -> Error', err);
                    reject(err);
                },
            );
        });
}

export function getUserMembershipAction() {
    return (dispatch, ) =>
        new Promise((resolve, reject) => {
            getUserMembership().then(
                (response) => {
                    console.log('inside membership action', response.data);
                    dispatch({
                        type: CONFIRMED_GET_USER_MEMBERSHIP_ACTION,
                        payload: response.data,
                    });
                    resolve(response.data);
                },
                (err) => {
                    console.log('getUserMembershipAction -> Error', err);
                    reject(err);
                },
            );
        });
}

export function activateAccountAction(token) {
    console.log('$$$$ very Token', token.veriToken)
    return ( ) =>
        new Promise((resolve, reject) => {
            accountActivation(token.veriToken).then(
                (response) => {
                    console.log(response);
                    if (response.data.status === 'failed') {
                        swal('Account activation Error', response.data.error, 'error');
                    } else {
                        resolve(response);
                    }
                },
                (err) => {
                    console.log('accountActivationAction -> Error', err);
                    reject(err);
                },
            );
        });
}

export function PasswForgotAction(email, ) {
    return (dispatch) => {
        forgotPassword(email)
            .then(async (response) => {
                console.log(response);
                if (!!response.data.status && response.data.status === 'failed') {
                    console.log('failed sending intructions for password retrieve');
                    swal('failed sending intructions for reset the password', response.data.error, 'error');
                    dispatch(loginFailedAction(response.data.error));
                    return;
                }

                swal('please check your inbox for further steps ', response.data.success, 'success');

                console.log('userData', userData);
                dispatch(loginConfirmedAction(userData));
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function updatePasswordAction(password, token, ) {
    return (dispatch) => {
        console.log(password);
        console.log(token);

        updatePassword(password, token)
            .then(async (response) => {
                console.log(response);

                console.log(response);
                if (response.data.status === 'success') {
                    swal('Password updated succesfully', response.data.message, 'success');
                    // dispatch(loginFailedAction(response.data.error));
                }
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function confirmedActivateAccountAction(User) {
    return {
        type: CONFIRMED_ACTIVATED_ACCOUNT_ACTION,
        payload: User,
    };
}

import axiosInstance from '../services/AxiosInstance';

export function getCompanys() {
    return axiosInstance.get(`companies/v2`);
}

export function createCompany(companyData) {
    return axiosInstance.post(`companies/v2`, companyData);
}

export function updateCompany(company, companyId) {
    return axiosInstance.put(`companies/${companyId}.json`, company);
}

export function deleteCompany(companyId) {
    return axiosInstance.delete(`companies/${companyId}.json`);
}

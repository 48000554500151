import { CONFIRMED_GET_TIERS_ACTION, CONFIRMED_UPDATE_TIER_MEMBER_ACTION } from '../actions/TiersTypes';

const initialState = {
    tiers: {},
};

export default function TiersReducer(state = initialState, actions) {
    if (actions.type === CONFIRMED_GET_TIERS_ACTION) {
        const tiers = actions.payload.response;

        return {
            ...state,
            tiers,
        };
    }

    if (actions.type === CONFIRMED_UPDATE_TIER_MEMBER_ACTION) {
        const tier = actions.payload.response;

        return {
            ...state,
            tier,
        };
    }

    return state;
}

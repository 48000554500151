import React from 'react';
import { Card, Row, Col, Form, Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const mockStats = () => ({
    total: Math.floor(Math.random() * 10000),
    whitelist: Math.floor(Math.random() * 999),
    redeemed: Math.floor(Math.random() * 1000),
    sales: Number.parseFloat(Math.random() * 100).toFixed(2),
});

const TabTypes = [
    { label: 'Total', stats: mockStats() },
    { label: 'QR', stats: mockStats() },
    { label: 'Redeem', stats: mockStats() },
    { label: 'Drop', stats: mockStats() },
    { label: 'Sales', disabled: true, stats: mockStats() },
];

export default function Stats({ tabs = TabTypes }) {
    const { t } = useTranslation();

    return (
        <Card className="p-3" style={{ border: ' 3px solid #6366F1' }}>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Label htmlFor="description" className="text-primary">
                        {t('STATS_DEPLOYMENT')}
                    </Form.Label>
                    <p className="fs-6 ms-3 text-muted">{t('STATS_REDEEM_STRATEGY')}</p>
                    <div className="progress mb-3" style={{ height: '20px', width: '75%', margin: '16px 12px' }}>
                        <div
                            className="progress-bar w-75"
                            role="progressbar"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                    <p className="fs-6 me-3 ms-3" style={{ color: 'black' }}>
                        {t('STATS_TOTAL_MINTED_NFTS')} <span className="text-muted">23/45</span>
                    </p>
                </Col>
                <Col md={6} sm={12}>
                    <Tab.Container tabs={tabs} defaultActiveKey={tabs[0].label}>
                        <Form.Label htmlFor="description" className="text-primary">
                            {t('STATS_PERFORMANCE')}
                        </Form.Label>
                        <Row className="custom-tab-1">
                            <Nav as="ul" className="nav-tabs align-items-center">
                                {tabs.map(({ label, disabled }) => (
                                    <Nav.Item key={label} as="li" className="me-xl-3 me-0 mb-0">
                                        <Nav.Link eventKey={label} disabled={disabled}>
                                            {label}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Row>
                        <Row>
                            <Tab.Content>
                                {tabs.map(({ label, stats }) => (
                                    <Tab.Pane key={label} eventKey={label}>
                                        <Row>
                                            <Col xs={6}>
                                                <p className="fs-6 me-4" style={{ color: 'black' }}>
                                                    Total NFTs <span className="text-muted">{stats.total}</span>
                                                </p>
                                                <p className="fs-6 me-4" style={{ color: 'black' }}>
                                                    Reedemed NFTs <span className="text-muted">{stats.redeemed}</span>
                                                </p>
                                            </Col>
                                            <Col xs={6}>
                                                <p className="fs-6 me-4" style={{ color: 'black' }}>
                                                    Whitelist <span className="text-muted">{stats.whitelist}</span>
                                                </p>
                                                <p className="fs-6 me-4" style={{ color: 'black' }}>
                                                    Sales <span className="text-muted">{stats.sales} ETH</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Row>
                    </Tab.Container>
                </Col>
            </Row>
        </Card>
    );
}

import { saveNft, mintNft, getNfts, updateNft, deleteNft, bulkUploadNft } from '../../services/NftsService';
import {
    CONFIRMED_CREATE_NFT_ACTION,
    CONFIRMED_DELETE_NFT_ACTION,
    CONFIRMED_EDIT_NFT_ACTION,
    CONFIRMED_GET_NFTS,
    CONFIRMED_GET_ALL_NFTS,
} from './NftTypes';
import { getTask } from '../../services/TasksService';
import swal from 'sweetalert';


export function addDraftNftToDropAction(contractId, walletId, drop, nftMetadata){
 //   return (dispatch) => {
    return () => {
            // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) =>{
            let saveNFTResponse;

            console.log('nftMetadata', nftMetadata)
            const nftData ={
                name: drop.name,
                description: drop.description,
                walletId: walletId, 
                dropId: drop._id,
                attributes: [],
                image: nftMetadata.image,
            }
            try {
                saveNFTResponse = await saveNft(contractId, nftData);
            } catch (error) {
                reject(error);
                return;
            }
            resolve(saveNFTResponse);


        })
    }

}

export function addBulkNftToDropAction(contractId, csvFilename, zipFilename, dropId){
    //   return (dispatch) => {
       return () => {
               // eslint-disable-next-line no-async-promise-executor
           return new Promise(async (resolve, reject) =>{
               let saveBulkResponse;
   
               const bulkData ={
                    csvFilename,
                    zipFilename,
                    dropId, 
               }
               try {
                   saveBulkResponse = await bulkUploadNft(contractId, bulkData);
               } catch (error) {
                   reject(error);
                   return;
               }

               console.log('saveBulkResponse', saveBulkResponse.data.status);
                   if(saveBulkResponse.data.status) swal('error creating uploading nfts', saveBulkResponse.data.error); 
                
               resolve(saveBulkResponse);
   
   
           })
       }
   
   }






export function mintNftAction(collectionId, walletId, skey, nftData, history) {
    return (dispatch) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            // Save NFT
            let saveNFTResponse;
            try {
                saveNFTResponse = await saveNft(collectionId, nftData);
            } catch (error) {
                reject(error);
                return;
            }
            console.log('saveNFTResponse', saveNFTResponse);
            if (saveNFTResponse.state === 'failed') {
                reject('Failed to upload NFT');
                return;
            }
            let nftId = saveNFTResponse.data.nft._id;

            const mintData = {
                nftId: nftId,
                walletId: walletId,
                skey: skey,
                to: walletId,
            };
            console.log('mintData', mintData);

            // Mint NFT
            let mintNFTResponse;
            try {
                mintNFTResponse = await mintNft(mintData);
            } catch (error) {
                reject(error);
                return;
            }

            // if (mintNFTResponse.data.nft.state == "Failed"){
            //     reject(`NFT creation failed! ${saveNFTResponse.error}`);
            //     return;
            // }

            console.log('mintNFTResponse', mintNFTResponse);
            if (!!mintNFTResponse.data.status && mintNFTResponse.data.status.toLowerCase() == 'failed') {
                console.log('mintNftAction error (status)', mintNFTResponse.data.error);
                reject(`NFT creation failed! ${mintNFTResponse.data.error}`);
                return;
            }

            let taskResponse,
                taskSuccessful = false;
            for (let i = 0; i < 20; i++) {
                try {
                    taskResponse = await getTask(mintNFTResponse.data.taskId);
                    console.log('tries', i);
                    if (taskResponse.data.state === 'running' || taskResponse.data.state === 'queued') {
                        await new Promise((r) => setTimeout(r, 5000));
                    } else {
                        taskSuccessful = true;
                        break;
                    }
                } catch (error) {
                    console.log('getTask error', error);
                }
            }

            if (!taskSuccessful) {
                reject(`NFT creation failed! Timeout error`);
                return;
            }

            let nft = saveNFTResponse.data.nft;
            nft.state = taskResponse.data.state;
            nft.txHash = taskResponse.data.txHAsh;
            dispatch(confirmedCreateNftAction(nft));

            if (history) history.push('/collection');

            resolve(nft);
        });
    };
}


export function deleteNftAction(nftId) {
   
    return (dispatch) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            // Save NFT
            let deleteNFTResponse;
            try {

                console.log('nft info 2', nftId) 
                deleteNFTResponse = await deleteNft(nftId);
            } catch (error) {
                reject(error);
                return;
            }
            
            console.log('deleteNFTResponse', deleteNFTResponse)

            if (!!deleteNFTResponse.data.status && deleteNFTResponse.data.status.toLowerCase() == 'failed') {
                reject(`NFT creation failed! ${deleteNFTResponse.data.error}`);
                return;
            }
            dispatch(confirmedDeleteNftAction(nftId));
            resolve(nftId);


        });
    }
    

}


export function confirmedDeleteNftAction(nftId) {
    return {
        type: CONFIRMED_DELETE_NFT_ACTION,
        payload: nftId,
    };
}

export function confirmedCreateNftAction(singleNft) {
    return {
        type: CONFIRMED_CREATE_NFT_ACTION,
        payload: singleNft,
    };
}

export function getNftsAction(contractId) {
    return (dispatch) => {
        getNfts(contractId).then((response) => {
            let nfts = response.data;
            dispatch(confirmedGetNftsAction(nfts));
        });
    };
}
export function confirmedGetNftsAction(nfts) {
    return {
        type: CONFIRMED_GET_NFTS,
        payload: nfts,
    };
}


export function updateNftAction(nft, history) {
    return (dispatch) => {
        updateNft(nft, nft.id).then(() => {
            dispatch(confirmedUpdateNftAction(nft));
            history.push('/nftpage');
        });
    };
}
export function confirmedUpdateNftAction(nft) {
    return {
        type: CONFIRMED_EDIT_NFT_ACTION,
        payload: nft,
    };
}
/*
export function transferNftAction(nftId, nftData, history) {
    return (dispatch) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            //console.log('transferNft nftId', nftId, nftData);
            transferNft(nftId, nftData).then(
                async (reponse) => {
                    console.log('transferNftAction reponse.data', reponse.data);
                    if (!!reponse.data.status && reponse.data.status.toLowerCase() == 'failed') {
                        if (reponse.data.error == "Cannot read property 'substr' of undefined") {
                            reject('El NFT ya ha sido transferido');
                        } else {
                            reject(reponse.data.error);
                        }
                    } else {
                        let taskResponse,
                            taskSuccessful = false;
                        for (let i = 0; i < 20; i++) {
                            try {
                                taskResponse = await getTask(reponse.data.taskId);
                                console.log('tries', i);
                                if (taskResponse.data.state === 'running' || taskResponse.data.state === 'queued') {
                                    await new Promise((r) => setTimeout(r, 5000));
                                } else {
                                    taskSuccessful = true;
                                    break;
                                }
                            } catch (error) {
                                console.log('getTask error', error);
                            }
                        }

                        if (!taskSuccessful) {
                            reject(`NFT transfer failed! Timeout error`);
                            return;
                        }

                        dispatch(confirmedDeleteNftAction(nftId));
                        if (history) history.push('/collection');
                        resolve();
                    }
                },
                (err) => {
                    console.log('transferNftAction error', err);
                    reject(err);
                },
            );
        });
    };
}
*/
export function getAllNftsAction(collections) {
    return async (dispatch) => {
        var allNfts = [];

        var getNftsByContract = (contractId) => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) => {
                getNfts(contractId).then(
                    (response) => {
                        let nfts = response.data;
                        resolve(nfts);
                    },
                    () => {
                        resolve([]);
                    },
                );
            });
        };
        for (let i = 0; i < collections.length; i++) {
            let collection = collections[i];
            let nfts = await getNftsByContract(collection._id);
            allNfts = allNfts.concat(nfts);
        }

        dispatch(confirmedGetAllNftsAction(allNfts));
    };
}
export function confirmedGetAllNftsAction(nfts) {
    return {
        type: CONFIRMED_GET_ALL_NFTS,
        payload: nfts,
    };
}

import {
    CONFIRMED_CREATE_DROP_ACTION,
    CONFIRMED_DELETE_DROP_ACTION,
    CONFIRMED_EDIT_DROP_ACTION,
    CONFIRMED_GET_DROPS,
    CREATE_DROP_ACTION,
    CONFIRMED_GET_DROP_API,
    SET_CURRENT_DROP_ACTION,
} from '../actions/DropTypes';

const initialState = {
    drops: [],
    currentProject: {},
};

export default function DropssReducer(state = initialState, actions) {
    if (actions.type === CREATE_DROP_ACTION) {
        return {
            ...state,
            drops: [...state.drops, actions.payload],
        };
    }

    if (actions.type === CONFIRMED_DELETE_DROP_ACTION) {
        const drops = [...state.drops];
        const dropIndex = drops.findIndex((drop) => drop._id === actions.payload);

        drops.splice(dropIndex, 1);

        return {
            ...state,
            drops,
        };
    }

    if (actions.type === CONFIRMED_EDIT_DROP_ACTION) {
        const drops = [...state.drops];
        const dropIndex = drops.findIndex((drop) => drop._id === actions.payload._id);

        drops[dropIndex] = actions.payload;
        return {
            ...state,
            drops,
        };
    }

    if (actions.type === CONFIRMED_CREATE_DROP_ACTION) {
        const drops = [...state.drops];
        drops.push(actions.payload);

        return {
            ...state,
            drops: drops,
        };
    }

    if (actions.type === CONFIRMED_GET_DROPS) {
        return {
            ...state,
            drops: actions.payload,
        };
    }

    if (actions.type === CONFIRMED_GET_DROP_API) {
        return {
            ...state,
            apikey: actions.payload.token,
        };
    }

    if (actions.type === SET_CURRENT_DROP_ACTION) {
        return {
            ...state,
            currentProject: actions.payload,
        };
    }

    return state;
}

const buildDropImage = (drop) => {
    if (!drop?.imagePath) return drop;
    if (drop.imageUrl.includes('/file/v2')) return { ...drop, image: drop.imageUrl };
    return { ...drop, image: `${drop.imageUrl}/file/v2/drops/${drop.imagePath}` };
};

export const getDropById = (state, dropId) => {
    const drop = state.drops?.drops?.find((drop) => drop._id === dropId);
    if (drop) return buildDropImage(drop);
};

export const getAllDrops = (state) => {
    const drops = state.drops.drops;
    if (!drops || !Array.isArray(drops)) return [];
    return drops.map(buildDropImage);
};

/*
export const getAllMints = (state) => {
    const drops = state.drops.drops;
    if (!drops || !Array.isArray(drops)) return [];

    for (let i=0; 1<drops.length; i++){
        if( drops[i].state == 'mint')

    }

    return drops.map(buildDropImage);
};
*/



export const getAllMints = (state) => {
    return state.drops.drops.filter((drop) => drop.state == 'mint');
};
export const CREATE_PROJECT_ACTION = '[Project Action] Create Project';
export const CONFIRMED_CREATE_PROJECT_ACTION = '[Project Action] Confirmed Create Project';
export const ERROR_CREATE_PROJECT_ACTION = '[Project Action] Error Create Project';
export const GET_PROJECTS = '[Project Action] Get Projects';
export const CONFIRMED_GET_PROJECTS = '[Project Action] Confirmed Get Projects';
export const EDIT_PROJECT_ACTION = '[Project Action] Edit Project';
export const CONFIRMED_EDIT_PROJECT_ACTION = '[Project Action] Confirmed Edit Project';
export const CONFIRMED_DELETE_PROJECT_ACTION = '[Project Action] Confirmed Delete Project';
export const GET_PROJECT_API = '[Project Action] Get Project Api';
export const CONFIRMED_GET_PROJECT_API = '[Project Action] Confirmed Get Project Api';
export const SET_CURRENT_PROJECT_ACTION = '[Project Action] Set Current Project';

let direction = 'ltr';

export const dezThemeSet = [
    {
        /* Default */ typography: 'poppins',
        version: 'light',
        layout: 'vertical',
        headerBg: 'color_1',
        navheaderBg: 'color_1',
        sidebarBg: 'color_1',
        sidebarStyle: 'full',
        sidebarPosition: 'fixed',
        headerPosition: 'fixed',
        containerLayout: 'full',
        direction: direction,
    },
    {
        /* Demo Theme 1 */ typography: 'poppins',
        version: 'light',
        layout: 'vertical',
        primary: 'color_5',
        headerBg: 'color_5',
        navheaderBg: 'color_1',
        sidebarBg: 'color_1',
        sidebarStyle: 'full',
        sidebarPosition: 'fixed',
        headerPosition: 'fixed',
        containerLayout: 'wide',
        direction: direction,
    },
    {
        /* Demo Theme 2 */ typography: 'poppins',
        version: 'light',
        layout: 'vertical',
        navheaderBg: 'color_11',
        headerBg: 'color_1',
        sidebarBg: 'color_11',
        sidebarStyle: 'full',
        sidebarPosition: 'fixed',
        headerPosition: 'fixed',
        containerLayout: 'wide',
        primary: 'color_11',
        direction: direction,
    },
    {
        /* Demo Theme 3 */
        typography: 'poppins',
        version: 'dark',
        layout: 'vertical',
        headerBg: 'color_3',
        navheaderBg: 'color_3',
        sidebarBg: 'color_1',
        sidebarStyle: 'full',
        sidebarPosition: 'fixed',
        headerPosition: 'fixed',
        containerLayout: 'wide',
        primary: 'color_1',
        direction: direction,
    },
    {
        /* Demo Theme 4 */
        typography: 'poppins',
        version: 'light',
        layout: 'vertical',
        navheaderBg: 'color_15',
        headerBg: 'color_1',
        sidebarStyle: 'full',
        sidebarBg: 'color_1',
        sidebarPosition: 'fixed',
        headerPosition: 'fixed',
        containerLayout: 'wide',
        primary: 'color_15',
        direction: direction,
    },
    {
        /* Demo Theme 5 */
        typography: 'poppins',
        version: 'light',
        layout: 'horizontal',
        navheaderBg: 'color_1',
        headerBg: 'color_1',
        sidebarBg: 'color_9',
        sidebarStyle: 'modern',
        sidebarPosition: 'static',
        headerPosition: 'fixed',
        containerLayout: 'wide',
        primary: 'color_9',
        direction: direction,
    },
    /* {
		//Demo Theme 6
		typography: "poppins",
		version: "light",
		layout: "vertical",
		navheaderBg: "color_3",
		headerBg: "color_1",
		sidebarStyle: "mini",
		sidebarBg: "color_10",
		sidebarPosition: "fixed",
		headerPosition: "fixed",
		containerLayout: "wide",
		primary: "color_1",
		direction: direction
	},
	{
		// Demo Theme 7 
		typography: "poppins",
		version: "light",
		layout: "vertical",
		navheaderBg: "color_1",
		headerBg: "color_1",
		sidebarStyle: "compact",
		sidebarBg: "color_1",
		sidebarPosition: "fixed",
		headerPosition: "fixed",
		containerLayout: "wide",
		primary: "color_6",
		direction: direction
	},
	{
		// Demo Theme 8 *
		typography: "poppins",
		version: "light",
		layout: "vertical",
		navheaderBg: "color_15",
		headerBg: "color_1",
		sidebarStyle: "icon-hover",
		sidebarBg: "color_15",
		sidebarPosition: "fixed",
		headerPosition: "fixed",
		containerLayout: "wide",
		primary: "color_15",
		direction: direction
	} */
];

import React from 'react';

export default function MediaRow({ media }) {
    return (
        <tr>
            <td align="center">
                <img className="img-thumbnail" src={media.url} style={{ maxHeight: '100px', maxWidth: '100px' }}></img>
            </td>
            <td>{media.name}</td>
            {/* <td>{media._id}</td> */}
            {/* <td>{media.projectId}</td> */}
            <td>{media.url}</td>
        </tr>
    );
}

import {
    ADD_WALLET_ACTION,
    CONFIRMED_ADD_WALLET_ACTION,
    CONFIRMED_GET_WALLET,
    CONFIRMED_GET_ONE_WALLET,
    CONFIRMED_GET_WALLETS,
    SET_CURRENT_WALLET_ACTION,
} from '../actions/WalletsTypes';

const initialState = {
    wallets: [],
    currentWallet: {},
};

export default function WalletsReducer(state = initialState, actions) {
    if (actions.type === ADD_WALLET_ACTION) {

        const wallets = [...state.wallets];
        wallets.push(actions.payload);
        return {
            ...state,
            wallets,
        };
    }

    if (actions.type === CONFIRMED_ADD_WALLET_ACTION) {
        const wallets = [...state.wallets];
        if(actions.payload) {
            console.log('there is a wallet')
            wallets.push(actions.payload);
        }
        return {
            ...state,
            wallets,
        };
    }

    if (actions.type === CONFIRMED_GET_WALLET) {
        return {
            ...state,
            wallets: actions.payload,
        };
    }

    if (actions.type === CONFIRMED_GET_ONE_WALLET) {
        return {
            ...state,
            wallets: actions.payload,
        };
    }

    if (actions.type === CONFIRMED_GET_WALLETS) {
        return {
            ...state,
            wallets: actions.payload,
        };
    }

  
    if (actions.type === SET_CURRENT_WALLET_ACTION) {
        return {
            ...state,
            currentWallet: actions.payload,
        };
    }

    return state;
}

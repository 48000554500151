import React, { useCallback, useState } from 'react';

export const MenuTitle = React.createContext(null);

export default function MenuTitleStore({ children }) {
    const [subtitle, setSubtitle] = useState(null);

    const store = {
        subtitle,
        setSubtitle,
    };

    return <MenuTitle.Provider value={store}>{children}</MenuTitle.Provider>;
}

export const useAvaliableTitles = () => {
    const store = React.useContext(MenuTitle);
    return [store.avaliableTitles];
};

export const useUpdateSubtitle = () => {
    const store = React.useContext(MenuTitle);

    const setSubtitle = useCallback(
        (subtitle = '') => {
            if (!store.subtitle && !subtitle) return;
            store.setSubtitle(subtitle);
            return subtitle;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store.subtitle],
    );
    return [setSubtitle];
};

export const useTitleData = () => {
    const store = React.useContext(MenuTitle);
    return [store.subtitle, store.setSubtitle];
};

import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Tab, Nav, Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createDropAction, createDropActionBulk } from '../../../store/actions/DropActions';
import { useHistory } from 'react-router-dom';

import FileInput from '../../components/Common/FileInput';
import { useTranslation } from 'react-i18next';
import { useUpdateSubtitle } from '../../../context/MenuTitle';
import { useQuery } from '../../../hooks/useQuery';
import swal from 'sweetalert';
//import { setCurrentProjectAction, getProjectApiAction } from '../../../store/actions/ProjectActions';
//import {addWalletAction} from '../../../store/actions/WalletsActions'

const useMintForm = () => {
    const [setSubtitle] = useUpdateSubtitle();
    setSubtitle('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [, setProjectId] = useState();
    const [walletId, setWalletId] = useState();

    const [collectionId, setCollectionId] = useState();
    const [dropId, setDropId] = useState();
    const [image, setImage] = useState('');
    const [nftImage, setNftImage] = useState('');

    //upload zip files
    const [selectedCSVFile, setSelectedCSVFile] = useState();
    const [selectedZIPFile, setSelectedZIPFile] = useState();

    //const [isFilePicked, setIsFilePicked] = useState(false);

    const [formValues, setFormValues] = useState([{ key: "", value : ""}])
    const [nfts, setNfts] = useState([])
      
    
    const params = useQuery();

    const dispatch = useDispatch();
    const history = useHistory();
    const projectsAll = useSelector((state) => state.projects.projects);
    const walletsAll = useSelector((state) => state.wallets.wallets);
    const collectionsAll = useSelector((state) => state.collections.collections);
    const dropsAll = useSelector((state) => state.drops.drops);
   
    useEffect(() => {
        selectCollection(params.get('collection') || collectionsAll[0]._id);
    }, []);
   

    const selectCollection = (collectionId) => {
        const newSelectedCollection = collectionsAll.find((collection) => collection._id === collectionId);
        setCollectionId(newSelectedCollection._id);      
        selectProjectAndWalletId(newSelectedCollection); 
  
    };

   

    const selectProjectAndWalletId = (collection) =>{
        const newSelectedProject = projectsAll.find((project) =>project._id ===collection.projectId )
        setProjectId(newSelectedProject._id);
        const newSelectedWallet = walletsAll.find((wallet) =>wallet.projectId ===newSelectedProject._id )
        setWalletId(newSelectedWallet._id);
    }

    const selectDrop = (dropId) => {
        const newSelectedDrop = dropsAll.find((drop) => drop._id === dropId);
        setDropId(newSelectedDrop._id);
    };

    const  onCreate = async (goToNewCollection = false) => {
           
        console.log(nfts);
  
          const dropInfo = {
            name: name, 
            description: description, 
            image: image,
            dropType: 'fifo',
            useCodes: 0 ,
            isDirectMinting: 0 ,
            price: '1',
            state: 'mint',
            coin: 'usdc',
            startDate: "2022-06-12T00:00:00.000+00:00",
            endDate: "2022-08-14T00:00:00.000+00:00",
          }
          

       console.log('nfts', nfts)  
       dispatch(createDropAction(dropInfo, collectionId, walletId, nfts, history, goToNewCollection));
           
    };

    const  onBulkCreate = async (goToNewCollection = false) => {

        if(!selectedCSVFile) swal('you have to select a csv file');
        if(!selectedZIPFile) swal('you have to select a ZIP file');

        const dropInfo = {
          name: name, 
          description: description, 
          image: image,
          dropType: 'fifo',
          useCodes: 0 ,
          isDirectMinting: 0 ,
          price: '1',
          state: 'mint',
          coin: 'usdc',
          startDate: "2022-06-12T00:00:00.000+00:00",
          endDate: "2022-08-14T00:00:00.000+00:00",

        }
        
     dispatch(createDropActionBulk(dropInfo, collectionId,  selectedCSVFile, selectedZIPFile, walletId, history, goToNewCollection));
     
  };

    const changeHandlerCSV = (event) => {
        console.log(event) 
       // const file = event.target.files[0];        
        
       // console.log('&&&', file.type) 
       // if(file.type !== 'text/csv' || file.type !== 'application/zip') {
       //     swal('typefile not allow');
      //  }else {
		setSelectedCSVFile(event.target.files[0]);
        console.log('csv', selectedCSVFile);
       // }
    };

    const changeHandlerZIP = (event) => {
        console.log(event)
		setSelectedZIPFile(event.target.files[0]);
        console.log('zip', selectedZIPFile);
		//setIsSelected(true);
	};



    const uploadNft = () => {
        console.log(nftImage);
        let nft = [...formValues]
     //   alert(JSON.stringify(nfttmp));
        addNftToUpload(nft, nftImage, name, description)
       
    };

    let addNftToUpload = (att, img, name, description) => {
        setNfts([...nfts, { attributes: att, image: img, name:name, description:description }])
      }
    
    let removeNftToUpload = (i) => {
        let newNfts = [...nfts];
        newNfts.splice(i, 1);
        setNfts(newNfts)
    }


    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      }  
    
    let addFormFields = () => {
        setFormValues([...formValues, { key: " ", value: " " }])
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }
    
    let handleSubmit = (event) => {
        event.preventDefault();
        alert(JSON.stringify(formValues));
    }
    
    const hiddenFileInput = React.useRef(null);
    const hiddenFileInput2 = React.useRef(null);


    const handleCSVUpload = () => {
        hiddenFileInput.current.click();
    };
    const handleZIPUpload = () => {
        hiddenFileInput2.current.click();
    };
    
    //const handleZIPUpload= [];
    //const hiddenZipFileInput = [];

   // const hiddenZipFileInput= event => {
   //     hiddenZipInput.current.click();
   // };

    return { name, description, setName, setDescription, setImage, setDropId, setCollectionId, 
        onCreate, onBulkCreate, collectionsAll, dropsAll, selectDrop, selectCollection, collectionId, dropId,
        formValues, setFormValues, handleSubmit, removeFormFields,
        addFormFields, handleChange, uploadNft, setNftImage, nfts, removeNftToUpload, 
         changeHandlerCSV, changeHandlerZIP, handleCSVUpload, handleZIPUpload, hiddenFileInput, hiddenFileInput2,
         selectedCSVFile };
   };

const AddMint = () => {
    const { name, description,  setName, setDescription, setImage, formValues,
          onCreate, onBulkCreate, collectionsAll, dropsAll, selectDrop, selectCollection, collectionId,
          handleSubmit, removeFormFields,addFormFields, handleChange, key, value, uploadNft, 
          setNftImage, nfts, removeNftToUpload, changeHandlerCSV, changeHandlerZIP,  handleCSVUpload, handleZIPUpload,
          hiddenFileInput2, hiddenFileInput} =
        useMintForm();
    const isValid = name.trim();
    const { t } = useTranslation();
    
    const tabs = [
        { label: 'Bulk Upload', stats: [] },
        { label: 'Manual Upload', stats: [] },
        
    ];
    

    console.log('nfts', nfts);
    


    return (
       
        <>
        <Row>
            <Row>
               
                <Col md={12} sm={12}>
                    <Tab.Container tabs={tabs} defaultActiveKey={tabs[0].label}>
                       
                        <Row className="custom-tab-1">
                            <Nav as="ul" className="nav-tabs align-items-center">
                                {tabs.map(({ label, disabled }) => (
                                    <Nav.Item key={label} as="li" className="me-xl-3 me-0 mb-0">
                                        <Nav.Link eventKey={label} disabled={disabled}>
                                            {label}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Row>
                        
                        
                        <Row>
                         <Tab.Content>
                            <Tab.Pane key={'Bulk Upload'} eventKey={'Bulk Upload'}>
                             
                                <Col sm={12} xs={12}>
                                    <Card className="p-4 short-card">
                                        <Row>
                                            <Col sm={6} md={4} xs={12} className="mb-sm-3">
                                                <Form.Label htmlFor="thumbnail" className="text-primary">
                                                    {t('BULKUPLOAD_FORM_THUMBNAIL')}  
                                                </Form.Label>
                                                <p className="mb-2">{t('BULKUPLOAD_FORM_THUMBNAIL_DESCRIPTION')} </p>
                                                <FileInput 
                                                    onFileAdded={({ file }) => setImage(file)} 
                                                    onFileRemoved={() => setImage()} 
                                                />
                                            </Col>
                        
                                            <Col sm={6} md={3} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label htmlFor="description" className="text-primary">
                                                        Name
                                                    </Form.Label>

                                                    <Form.Text
                                                        as="input"
                                                        name="name"
                                                        rows="4"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Mint Name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="">
                                                    <Form.Label htmlFor="description" className="text-primary">
                                                        Description
                                                    </Form.Label>

                                                    <Form.Text
                                                        as="textarea"
                                                        name="description"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Mint Description"
                                                        style={{ height: '150px' }}
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />
                                                </Form.Group>
                                                </Col>
                                        

                                            <Col sm={12} md={5} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label htmlFor="description" className="text-primary">
                                                        Link it
                                                    </Form.Label>

                                                    <p className="mb-3">
                                              NFTS are minted under collections, which define part of their settings
                                                    </p>
                                                    <Row>
                                                        <Col sm={4} xs={6}>
                                                            <select
                                                                className="form-control mt-1 selecto"
                                                            onChange={(event) => selectCollection(event.target.value)}
                                                                value={collectionId}
                                                            >
                                                            
                                                                {collectionsAll.map(({ _id, name }) => (
                                                                    <option key={_id} value={_id}>
                                                                        {name}
                                                                    </option>
                                                                ))}
                                                                
                                                            </select>
                                                        </Col>
                                                        

                                                        <Col sm={8} xs={6}>
                                                            <Button
                                                                type="button"
                                                                className="mt-2 mb-1"
                                                                variant="outline-primary"
                                                                onClick={() => history.push('/add-collection')}
                                                            >
                                                                collection{' '}
                                                                <span className="btn-icon-end">
                                                                    <i className="fa fa-plus-circle" />
                                                                </span>
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col sm={4} xs={6}>
                                                            <select
                                                                className="form-control mt-1 selecto"
                                                               onChange={(event) => selectDrop(event.target.value)}
                                                                value={collectionId}
                                                            >
                                                            
                                                                {dropsAll.map(({ _id, name }) => (
                                                                    <option key={_id} value={_id}>
                                                                        {name}
                                                                    </option>
                                                                ))}
                                                                
                                                            </select>
                                                        </Col>
                                                        <Col sm={8} xs={6}>
                                                            <Button
                                                                type="button"
                                                                className="mt-2 mb-1"
                                                                variant="outline-primary"
                                                                onClick={() => history.push('/add-mint')}
                                                            >
                                                               Drops{' '}
                                                                <span className="btn-icon-end">
                                                                    <i className="fa fa-plus-circle" />
                                                                </span>
                                                               
                                                            </Button>
                                                        </Col>
                                                    </Row>   
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card>


                                    {/* UPLOADING ZIP, CSV */}
                                    <Card className="p-4 short-card">
                                        <Row>         
                                            <Col sm={12} md={12} xs={12} className="mb-sm-3">
                                                <Form.Group className="">
                                                    <Form.Label htmlFor="description" className="text-primary">
                                                        Upload images and attribute values
                                                    </Form.Label>
                                                    <p className="mb-3"> Upload a csv file following the template below. Each record should be connected to an image by the image name uploaded in the image upload section.
                                                    </p>
                                                    <Row>
                                                        <Col sm={4} md={4} xs={12} className="mb-sm-3">
                                                            <Form.Label htmlFor="description" className="text-primary">
                                                                Dowload XLS Template
                                                            </Form.Label>
                                                        </Col>

                                                        <Col sm={4} md={4} xs={12} className="mb-sm-3">
                                                            

                                                          {/*<p>selectedCSVFile.name}</p>*/}

                                                            <Button
                                                                type="button"
                                                                className="mt-2 mb-1"
                                                                variant="outline-primary"
                                                                onClick={handleCSVUpload}
                                                                >
                                                                Upload CSV file
                                                                </Button>

                                                            <input type="file"
                                                                name="csv" 
                                                                ref={hiddenFileInput}
                                                                onChange={changeHandlerCSV}
                                                                style={{display:'none'}}
                                                                />    
                                                        </Col>
                    
                                                        <Col sm={4} md={4} xs={12} className="mb-sm-3">

                                                            <Button
                                                                type="button"
                                                                className="mt-2 mb-1"
                                                                variant="outline-primary"
                                                                onClick={handleZIPUpload}
                                                                >
                                                                Upload ZIP file
                                                            </Button>

                                                            <input type="file" 
                                                                name="zip" 
                                                                ref={hiddenFileInput2}
                                                                onChange={changeHandlerZIP} 
                                                                style={{display:'none'}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>       
                                             </Col>  
                                        </Row>    
                                    </Card>    
                                </Col>


                            <Row>
                                <Col xs={12} className={'d-flex justify-content-end mb-3'}>
                                    <Button
                                        disabled={!isValid}
                                        type="button"
                                        className="me-2"
                                        variant="outline-primary"
                                        onClick={() => onBulkCreate()}
                                    >
                                        {t('MINTS_FORM_SAVE')}
                                    </Button>
                                    <Button
                                        disabled={!isValid}
                                        type="button"
                                        className="me-2"
                                        variant="primary"
                                        onClick={() => onBulkCreate(true)}
                                    >
                                        {t('MINTS_FORM_SAVE_2')}
                                    </Button>
                                </Col>
                            </Row>
                        </Tab.Pane>
                                    
                        <Tab.Pane key={'Manual Upload'} eventKey={'Manual Upload'}>
                            <Row>
                                <Col sm={12} xs={12}>
                                    <Card className="p-4 short-card">
                                        <Row>
                                            <Col sm={6} md={4} xs={12} className="mb-sm-3">
                                                <Form.Label htmlFor="thumbnail" className="text-primary">
                                                    {t('BULKUPLOAD_FORM_THUMBNAIL')}  
                                                </Form.Label>
                                                <p className="mb-2">{t('BULKUPLOAD_FORM_THUMBNAIL_DESCRIPTION')} </p>
                                                <FileInput 
                                                    onFileAdded={({ file }) => setImage(file)} 
                                                    onFileRemoved={() => setImage()} 
                                                />
                                            </Col>
                        
                                            <Col sm={6} md={3} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label htmlFor="description" className="text-primary">
                                                        Name
                                                    </Form.Label>

                                                    <Form.Text
                                                        as="input"
                                                        name="name"
                                                        rows="4"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Mint Name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="">
                                                    <Form.Label htmlFor="description" className="text-primary">
                                                        Description
                                                    </Form.Label>

                                                    <Form.Text
                                                        as="textarea"
                                                        name="description"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Mint Description"
                                                        style={{ height: '150px' }}
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />
                                                </Form.Group>
                                                </Col>
                                        

                                            <Col sm={12} md={5} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label htmlFor="description" className="text-primary">
                                                        Link it
                                                    </Form.Label>

                                                    <p className="mb-3">
                                                  NFTS are minted under collections, which define part of their settings
                                                    </p>
                                                    <Row>
                                                        <Col sm={4} xs={6}>
                                                            <select
                                                                className="form-control mt-1 selecto"
                                                               onChange={(event) => selectCollection(event.target.value)}
                                                                value={collectionId}
                                                            >
                                                            
                                                                {collectionsAll.map(({ _id, name }) => (
                                                                    <option key={_id} value={_id}>
                                                                        {name}
                                                                    </option>
                                                                ))}
                                                                
                                                            </select>
                                                        </Col>
                                                        <Col sm={8} xs={6}>
                                                            <Button
                                                                type="button"
                                                                className="mt-2 mb-1"
                                                                variant="outline-primary"
                                                                onClick={() => history.push('/add-collection')}
                                                            >
                                                               Collections{' '}
                                                                <span className="btn-icon-end">
                                                                    <i className="fa fa-plus-circle" />
                                                                </span>
                                                               
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4} xs={6}>
                                                            <select
                                                                className="form-control mt-1 selecto"
                                                               onChange={(event) => selectDrop(event.target.value)}
                                                                value={collectionId}
                                                            >
                                                            
                                                                {dropsAll.map(({ _id, name }) => (
                                                                    <option key={_id} value={_id}>
                                                                        {name}
                                                                    </option>
                                                                ))}
                                                                
                                                            </select>
                                                        </Col>
                                                        <Col sm={8} xs={6}>
                                                            <Button
                                                                type="button"
                                                                className="mt-2 mb-1"
                                                                variant="outline-primary"
                                                                onClick={() => history.push('/add-mint')}
                                                            >
                                                               Drops{' '}
                                                                <span className="btn-icon-end">
                                                                    <i className="fa fa-plus-circle" />
                                                                </span>
                                                               
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                   
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card className="p-4 short-card">
                                        <Row>
                                            <Col sm={6} md={7} xs={12} className="mb-sm-3">
                                        
                                                <Form.Label htmlFor="description" className="text-primary">
                                                    Attributes
                                                </Form.Label>


                                              
                                                <p className="mb-3">
                                                  Here you can define what additional data will accompany each NFT. Add below the sata fields that will be part of the nft
                                                </p>
                                               

                                            <Row> 

                                         <Col sm={4} md={12} xs={12} className="mb-sm-3">  

                                         <Row>    
                                         <Col sm={4} md={4} xs={12} className="mb-sm-3" >  
                                         <Form.Label htmlFor="description" className="text-primary">
                                            Key
                                            </Form.Label>
                                         </Col> 
                                         <Col sm={4} md={4} xs={12} className="mb-sm-3" >  
                                         <Form.Label htmlFor="description" className="text-primary">
                                            Value
                                            </Form.Label>
                                         </Col>  
                                         </Row> 

                                        <form  onSubmit={handleSubmit}>
                                            {formValues.map((element, index) => (
                                                <Row key={index}>    
                                                <Col sm={4} md={4} xs={12} className="mb-sm-3" >  
                                                <Form.Group className="mb-3">
                                                    

                                                    <Form.Text
                                                        as="input"
                                                        name="key"
                                                        rows="4"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Key Name"
                                                        value={key}
                                                        onChange={e => handleChange(index, e)}
                                                    />
                                                  </Form.Group>
                                                 </Col>
                                                 <Col sm={4} md={4} xs={12} className="mb-sm-3" > 
                                                 <Form.Group className="mb-3">                        
                                                    
                                                    <Form.Text
                                                        as="input"
                                                        name="value"
                                                        rows="4"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Key Value"
                                                        value={value}
                                                        onChange={e => handleChange(index, e)}
                                                    />
                                                   
                                                </Form.Group>
                                                </Col>
                                                <Col sm={4} md={4} xs={12} className="mb-sm-3" >    
                                                {
                                                    index ? 
                                                    <Button
                                                            type="button"
                                                            className="mt-2 mb-1"
                                                            variant="outline-primary"
                                                            onClick={() => removeFormFields(index)}
                                                        >
                                                        Remove                          
                                                    </Button>                                                    
                                                    : null
                                                }

                                                </Col>
                                                
                                                
                                                </Row>
                                            ))}
                                                    <Button
                                                            type="button"
                                                            className="mt-2 mb-1"
                                                            variant="outline-primary"
                                                            onClick={() => addFormFields()}
                                                        >
                                                    Add                          
                                                    </Button>   
                                        </form>
                                            </Col>    
                                            </Row>
                                            </Col>  
                                            <Col sm={6} md={4} xs={12} className="mb-sm-3">
                                            <Form.Group className="">
                                               
                                                <Form.Label htmlFor="thumbnail" className="text-primary">
                                                    {t('MANUALUPLOAD_FORM_THUMBNAIL')}  
                                                </Form.Label>
                                                <p className="mb-2">{t('MANUALUPLOAD_FORM_THUMBNAIL_DESCRIPTION')} </p>
                                                <FileInput 
                                                    onFileAdded={({ file }) => setNftImage(file)} 
                                                    onFileRemoved={() => setNftImage()} 
                                                />
                                                
                                        <Row>
                                            <Col sm={6} md={6} xs={12} className="mb-sm-3">
                                                <Form.Label htmlFor="description" className="text-primary">
                                                </Form.Label>
                                            </Col>

                                            <Col sm={6} md={5} xs={12} className="mb-sm-3">
                                                <Button
                                                    type="button"
                                                    className="mt-2 mb-1"
                                                    variant="outline-primary"
                                                    onClick={() => uploadNft()}
                                                >
                                                    Upload NFT
                                                
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>       
                                </Col>                  
                            </Row>    
                        </Card>

                        <Card className="p-4 short-card">        
                            <Row>
                                <Col md={8} sm={8} xs={12}>     
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Attributes</th>
                                                <th>Remove</th>
                                            </tr>
                                        </thead>                             
                                        
                                        <tbody>    
                                            {nfts.length ? (
                                                 nfts.map((nft, index) => ( <>
                                                <tr>
                                                    <td><img src={nft.image} className="img-fluid" alt="" /></td>    
                                                    <td>{nft.image.name}</td>
                                                    <td>{nft._id}</td>
                                                    <td>{nft.attributes.length }</td>
                                                    <td>
                                                    <Button
                                                                type="button"
                                                                className="mt-2 mb-1"
                                                                variant="outline-primary"
                                                                onClick={() => removeNftToUpload(index)}
                                                            >
                                                            Remove                          
                                                        </Button>     
                                                    </td>                               
                                                </tr>
                                            </>    
                                            ))
                                        ) : (
                                            <p>{t('NFT_FORM_EDIT_MINTS_EMPTY')}</p>
                                        )}
                                    
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col sm={6} md={4} xs={12} className="mb-sm-3">
                                    <p1>here I have to put the card</p1>
                                </Col>     
                            </Row>
                        </Card>            
                    </Col>                                                             
                </Row>


                <Row>
                    <Col xs={12} className={'d-flex justify-content-end mb-3'}>
                        <Button
                            disabled={!isValid}
                            type="button"
                            className="me-2"
                            variant="outline-primary"
                            onClick={() => onCreate()}
                        >
                        {t('MINTS_FORM_SAVE')}
                        </Button>
                        <Button
                            disabled={!isValid}
                            type="button"
                            className="me-2"
                            variant="primary"
                            onClick={() => onCreate(true)}
                        >
                            {t('MINTS_FORM_SAVE_2')}
                        </Button>
                    </Col>
                    </Row>
                </Tab.Pane>
            </Tab.Content>
        </Row>
    </Tab.Container>
</Col>       
</Row>
</Row></>
       
    );
};

export default AddMint;
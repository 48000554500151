import React from "react";
import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from './jsx';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, withRouter, useHistory } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import './assets/vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import './styles/scss/main.scss';

const SignUp = lazy(() => import('./jsx/pages/Registration/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword/ForgotPassword'));
const AccountActivation = lazy(() => import('./jsx/pages/AccountActivation/AccountActivation'));

const Login = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import('./jsx/pages/Login/Login')), 500);
    });
});
const DemoQR = lazy(() => import('./jsx/pages/DemoQR/DemoQR'));
const DemoQRClaim = lazy(() => import('./jsx/pages/DemoQRClaim/DemoQRClaim'));
const HealthCheck = lazy(() => import('./jsx/pages/HealthCheck/HealthCheck'));
const VerifyAccount = lazy(() => import('./jsx/pages/AccountActivation/VerifyAccount'));
const UpdatePassword = lazy(() => import('./jsx/pages/ForgotPassword/UpdatePassword'));

function App(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        let path = window.location.pathname.split('/');
        //console.log('path', path);
        if (
            path.length < 2 ||
            (path[1] != 'demo-qr' &&
                path[1] != 'demo-qr-claim' &&
                path[1] != 'health-check' &&
                path[1] != 'account-activation' &&
                path[1] != 'verify-account' &&
                path[1] != 'edit-password')
        ) {
            checkAutoLogin(dispatch, history);
        }
    }, [dispatch, history]);

    let routes = (
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/page-register" component={SignUp} />
            <Route path="/page-forgot-password" component={ForgotPassword} />
            <Route path="/account-activation/:veriToken" component={AccountActivation} />
            <Route path="/verify-account/" component={VerifyAccount} />
            <Route path="/edit-password/:passwToken" component={UpdatePassword} />

            {/* <Route exact path="/demo-qr2" render={(props) => (
                <DemoQR demoId={1} nftId="xxxxxxxxxx"/>
            )} />   */}
            <Route path="/health-check" component={HealthCheck} />
            <Route path="/demo-qr/:nftId" component={DemoQR} />
            <Route path="/demo-qr-claim" component={DemoQRClaim} />
        </Switch>
    );
    if (props.isAuthenticated) {
        return (
            <>
                <Suspense
                    fallback={
                        <div id="preloader">
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div>
                    }
                >
                    <Index />
                </Suspense>
            </>
        );
    } else {
        return (
            <div className="vh-100">
                <Suspense
                    fallback={
                        <div id="preloader">
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div>
                    }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));

import axiosServiceApi from '../services/AxiosServiceApi';

export function getMedia() {
    return axiosServiceApi.get(`media/v2`);
}

export function getMediasFromProject(projectId) {
    return axiosServiceApi.get(`media/v2/byProject/${projectId}`);
}

export function addMedia(mediaData) {
    return axiosServiceApi.post(`media/v2`, mediaData);
}

export function updateMedia(media, mediaId) {
    return axiosServiceApi.put(`medias/v2/${mediaId}.json`, media);
}

export function deleteMedia(mediaId) {
    return axiosServiceApi.delete(`medias/v2/${mediaId}.json`);
}

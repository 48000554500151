import {
    CONFIRMED_CREATE_NFT_ACTION,
    CONFIRMED_MINT_NFT_ACTION,
    CONFIRMED_DELETE_NFT_ACTION,
    CONFIRMED_EDIT_NFT_ACTION,
    CONFIRMED_GET_NFTS,
    CONFIRMED_GET_ALL_NFTS,
} from '../actions/NftTypes';

const initialState = {
    nfts: [],
    allNfts: [],
};

export default function NftsReducer(state = initialState, actions) {
    if (actions.type === CONFIRMED_DELETE_NFT_ACTION) {
        const nfts = [...state.nfts];
        const nftIndex = nfts.findIndex((nft) => nft._id === actions.payload);

        nfts.splice(nftIndex, 1);

        return {
            ...state,
            nfts,
        };
    }

    if (actions.type === CONFIRMED_EDIT_NFT_ACTION) {
        const nfts = [...state.nfts];
        const nftIndex = nfts.findIndex((nft) => nft.id === actions.payload.id);

        nfts[nftIndex] = actions.payload;
        return {
            ...state,
            nfts,
        };
    }

    if (actions.type === CONFIRMED_CREATE_NFT_ACTION) {
        const nfts = [...state.nfts];
        nfts.push(actions.payload);

        return {
            ...state,
            nfts,
        };
    }

    if (actions.type === CONFIRMED_MINT_NFT_ACTION) {
        const nfts = [...state.nfts];
        nfts.push(actions.payload);

        return {
            ...state,
            nfts,
        };
    }

    if (actions.type === CONFIRMED_GET_NFTS) {
        return {
            ...state,
            nfts: actions.payload,
        };
    }

    if (actions.type === CONFIRMED_GET_ALL_NFTS) {
        return {
            ...state,
            allNfts: actions.payload,
        };
    }

    return state;
}

/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileInput from '../../components/Common/FileInput';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Button, Card, Row, Col, Container, Form } from 'react-bootstrap';

import { updateCollectionAction } from '../../../store/actions/CollectionActions';

import { useUpdateSubtitle } from '../../../context/MenuTitle';
import Stats from '../Common/Stats';
import { getCollectionById } from '../../../store/selectors/CollectionSelector';
import { getAllProjects } from '../../../store/selectors/ProjectSelectors';
import { useParams } from 'react-router-dom';
import mockCollectionImage from '../../../assets/images/mock_images/collection.png';
import { useTranslation } from 'react-i18next';
import wallet_deploy from '../../../assets/images/waiting_gif/wallet_deploy.gif';
import CommonCard from '../Common/Card';



const setMockImage = (setter) => {
    fetch(mockCollectionImage).then((res) => {
        res.arrayBuffer().then((buf) => {
            setter(new File([buf], 'projectTest.png', { type: 'image/png' }));
        });
    });
};

const useCollection = () => {
    const [id, setId] = useState();
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState();
    const [projectId, setProjectId] = useState();
    const [isValid, setIsValid] = useState(false);
    const [transferable, setTransferable] = useState(true);
    const [contractType, setContractType] = useState('51');

    const [show, setShow] = useState(false);
    const showModal = () => setShow(true);
    const closeModal = () => setShow(false);
    
    const dispatch = useDispatch();

    useEffect(() => {
        setIsValid(!!name.trim() && !!description.trim());
    }, [name, description]);

    const setCollection = (collection) => {
        console.log('####coll', collection)
        if (!collection.imagePath) {
            setMockImage(setImage);
        } else {
            setImage(`${collection.imageUrl}`);
        }

        setId(collection._id);
        setName(collection.name);
        setDescription(collection.description);
        setProjectId(collection.projectId);
        setTransferable(collection.transferable)
        setContractType(collection.contractType)
        console.log('transferable', transferable);
    };

    return {
        id,
        description,
        name,
        image,
        projectId,
        isValid,
        transferable,
        contractType,
        setId,
        setDescription,
        setName,
        setImage,
        setProjectId,
        setCollection,
        setTransferable,
        setContractType,
    };
};

const ViewCollection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [setSubtitle] = useUpdateSubtitle();
    const { id, description, name, image, projectId, isValid, transferable, contractType, 
            setDescription, setName, setProjectId, setContractType, setCollection, setTransferable, setImage } =
        useCollection();
    let { id: collectionId } = useParams();
    const storedCollection = useSelector((state) => getCollectionById(state, collectionId));
    const projectsAll = useSelector(getAllProjects);
    const mints=[];

    useEffect(() => {
        console.log(`🐛 | useEffect | storedCollection`, storedCollection?.name, storedCollection);
        console.log(image)
        storedCollection && setCollection(storedCollection);
        storedCollection && setSubtitle(storedCollection.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedCollection]);

    const onSubmit = async () => {
       dispatch(updateCollectionAction({ ...storedCollection, name, description, transferable, image, contractType }, history));
    };


    const { t } = useTranslation();
    return (
        <form>
            <Row>
                <Col sm={12} xs={12}>
                    <Card className="p-4 short-card">
                        <Row>

                        <Col sm={6} md={4} xs={12} className="mb-sm-3">
                            <Form.Label htmlFor="thumbnail" className="text-primary">
                                {t('PROJECTS_FORM_THUMBNAIL')}  
                        
                            </Form.Label>
                           
                            <FileInput 
                                initialFile={image}
                                onFileAdded={({ file }) => setImage(file)} 
                                onFileRemoved={() => setImage()} 
                            />
                        </Col>
                        
                            <Col sm={6} md={3} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="description" className="text-primary">
                                        Name
                                    </Form.Label>

                                    <Form.Text
                                        as="input"
                                        name="name"
                                        rows="4"
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Collection Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="">
                                    <Form.Label htmlFor="description" className="text-primary">
                                        Description
                                    </Form.Label>

                                    <Form.Text
                                        as="textarea"
                                        name="description"
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Collection Description"
                                        style={{ height: '150px' }}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={12} md={5} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="description" className="text-primary">
                                        Link it to a Project
                                    </Form.Label>

                                    <p className="mb-3">
                                        Collections are grouped under a project and the project defines the blockchain
                                        network. If you want to use a different network create a new project.
                                    </p>
                                    <Row>
                                        <Col sm={4} xs={6}>
                                            <select
                                                className="form-control mt-1 selecto"
                                                onChange={(event) => selectProject(event.target.value)}
                                                value={projectId}
                                            >
                                                {projectsAll.map(({ _id, name }) => (
                                                    <option key={_id} value={_id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col sm={8} xs={6}>
                                            <Button
                                                type="button"
                                                className="mt-2 mb-1"
                                                variant="outline-primary"
                                                onClick={() => history.push('/add-project')}
                                            >
                                                Project{' '}
                                                <span className="btn-icon-end">
                                                    <i className="fa fa-plus-circle" />
                                                </span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col sm={12} xs={12}>
                    <Card className="p-4">
                        <Row>
                            <Col sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="description" className="text-primary">
                                        Transferrable
                                    </Form.Label>

                                    <p className="mb-0">
                                        If enabled NFT owners will be able to transfer their NFTs. If disabled, NFTs
                                        held under this contract will not be transferrable while disabled.
                                    </p>
                                    <div className="mt-3 mb-2">
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`transferable-yes`}
                                            name={`transferable`}
                                            label={'Yes'}
                                            checked={transferable === true}
                                            onChange={(e) => {setTransferable(true)}}
                                            style={{ marginRight: '60px' }}
                                        />
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`transferable-no`}
                                            checked={transferable === false}
                                            name={`transferable`}
                                            onChange={(e) => setTransferable(false)}
                                            label={'No'}
                                        />
                                    </div>
                                    <Row className="mb-4">
                                        <Col lg={4} sm={6}>
                                            <div className="form-group mb-3">
                                                <select defaultValue={'option'} className="nice-select form-control">
                                                    <option>Days</option>
                                                    <option>Months</option>
                                                    <option>Years</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={6}>
                                            <Form.Text
                                                as="input"
                                                name="name"
                                                rows="4"
                                                required
                                                type="number"
                                                className="form-control"
                                                placeholder="Amount"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </Col>
                                    </Row>

                                    <Form.Label htmlFor="description" className="text-primary">
                                        Mutable
                                    </Form.Label>

                                    <p className="mb-0">
                                        Mutable NFT content can be changed after minting as is will not be stored on
                                        chain.
                                    </p>
                                    <div className="mt-3 mb-4">
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`Mutable-yes`}
                                            name={`Mutable`}
                                            label={'Yes'}
                                            style={{ marginRight: '60px' }}
                                            checked={contractType == '51'}
                                            onChange={(e) => {setContractType('51')}}
                                        />
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`Mutable-no`}
                                            name={`Mutable`}
                                            label={'No'}
                                            checked={contractType == '52'}
                                            onChange={(e) => {setContractType('52')}}
                                        />
                                    </div>

                                    <Form.Label htmlFor="description" className="text-primary">
                                        Reveal
                                    </Form.Label>

                                    <p className="mb-0">
                                        This functionality enables you to reveal the content af the NFT when the drop
                                        ends.
                                    </p>
                                    <div className="mt-3 mb-4">
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`Reveal-yes`}
                                            name={`Reveal`}
                                            label={'Yes'}
                                            style={{ marginRight: '60px' }}
                                        //    checked={reveal === true}
                                        //    onChange={(e) => {setReveal(true)}}

                                        />
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`Reveal-no`}
                                            name={`Reveal`}
                                            label={'No'}
                                 //           checked={reveal === false}
                                 //           onChange={(e) => {setReveal(false)}}
              
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Form.Label htmlFor="thumbnail" className="text-primary">
                                    Royalties
                                </Form.Label>
                                <p className="mb-3">
                                    If you want to collect royalties from primary and secondary sales please define the
                                    %. This will be encoded in the smart contract.
                                </p>
                                <Row>
                                    <Col lg={4} sm={6}>
                                        <Form.Text
                                            as="input"
                                            name="Primary"
                                            rows="1"
                                            required
                                            type="number"
                                            className="form-control"
                                            placeholder="Primary %"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Col>
                                    <Col lg={4} sm={6}>
                                        <Form.Text
                                            as="input"
                                            name="Secondary"
                                            rows="1"
                                            required
                                            type="number"
                                            className="form-control"
                                            placeholder="Secondary %"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Col>
                                </Row>

                                <p className="mt-3 mb-3">
                                    Choose the wallet where you&apos;d like to collect the proceeds.
                                </p>
                                <Row>
                                    <Col sm={6} xs={6}>
                                        <div id="asd" className="m-3">
                                            <Form.Check
                                                inline
                           //                     checked={!walletId}
                                                type={'radio'}
                                                id={`project-wallet-yes`}
                                                name={`projectWallet`}
                                                label={'Use the project wallet'}
                                                onChange={() => setWalletId('')}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={6} xs={6}>
                                        <Form.Text
                                            as="input"
                                            name="newAddress"
                                            rows="1"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Or paste a New address"
                         //                   value={walletId}
                         //                   onChange={(e) => setWalletId(e.target.value || '')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* <Row>
                    <Col sm={6} xs={12}>
                        <Card className="p-3">
                            <Form.Label htmlFor="thumbnail" className="text-primary">
                                Landing page settings
                            </Form.Label>
                            <p className="mb-2">
                                Choose the setting for the landing page where NFTs can be reedemed. Customize the logo,
                                URL, and disclaimers below.
                            </p>

                            <Row>
                                <Col lg={6} md={12}>
                                    <FileInput />
                                </Col>
                                <Col lg={6} md={12} className={'d-flex align-items-end justify-content-center'}>
                                    <Button
                                        type="button"
                                        className="mt-2 mb-3"
                                        variant="outline-primary"
                                        onClick={() => onCreate()}
                                    >
                                        Edit landing preferences
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row> */}
         
         
         <Row>
                <Col xs={12}>
                    <Card className="p-3">
                        <div className="title-and-action">
                            <Form.Label className="text-primary">{t('COLLECTIONS_FORM_EDIT_COLLECTIONS')}</Form.Label>

                            <Button
                                type="button"
                                className="btn-sm "
                                variant="outline-primary"
                                onClick={() => history.push(`/add-mint?collection=${collectionId}`)}
                            >
                                {t('COLLECTIONS_FORM_EDIT_COLLECTIONS_ADD')} +
                            </Button>
                        </div>
                        <Container className="mt-2 min-300">
                            <Row className="row row-cols-sm-2 row-cols-md-4 row-cols-md-4 gy-3  g-xs-1 g-md-3 flex-wrap">
                                {mints.length ? (
                                    mints.map((mint, index) => (
                                        <CommonCard
                                            showFooter={false}
                                            titleExtras={[
                                                <img key="1" src={Transferable} style={{ marginRight: '4px' }} />,
                                                <img key="1" src={Mutable} style={{ marginRight: '4px' }} />,
                                                <img key="2" src={OnChain} />,
                                            ]}
                                            key={`CommonCard-${index}`}
                                            title={mint.name}
                                            description={mint.description}
                                            variant="darker"
                                        />
                                    ))
                                ) : (
                                    <p>{t('COLLECTIONS_FORM_EDIT_MINTS_EMPTY')}</p>
                                )}
                            </Row>
                        </Container>
                    </Card>
                </Col>
            </Row>
         
         
         
         
         
            <Row>
                <Col xs={12} className={'d-flex justify-content-end mb-3'}>
                    <Button
                        disabled={!name.trim() || !description.trim()}
                        type="button"
                        className="me-5"
                        variant="outline-primary"
                        onClick={() => onSubmit()}
                    >
                        Save
                    </Button>
                    <Button
                        disabled={!name.trim() || !description.trim()}
                        type="button"
                        className="me-2"
                        variant="primary"
                        onClick={() => onSubmit(true)}
                    >
                        Save and add Mint
                    </Button>
                </Col>
            </Row>

            <Modal>
                <Modal.Body>
                    <img className="card-img-top img-fluid" src={wallet_deploy} alt="Card image cap"></img>
                    <h2 className="text-center">Your Collection is being deployed</h2>
                </Modal.Body>
                <Modal.Footer>
                    {/*   <Button variant="secondary"  onClick={handleClose}> Close Modal</Button> */}
                </Modal.Footer>
            </Modal>
        </form>
    );
};


export default ViewCollection;

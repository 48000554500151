import { CONFIRMED_GET_DATA } from '../actions/DemoTypes';

const initialState = {
    data: [],
};

export default function DemoReducer(state = initialState, actions) {
    if (actions.type === CONFIRMED_GET_DATA) {
        return {
            ...state,
            data: actions.payload,
        };
    }

    return state;
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { confirmedGetDropsAction } from '../../../store/actions/DropActions';
import { confirmedGetNftsAction } from '../../../store/actions/NftActions';

import { getProjectApiAction } from '../../../store/actions/ProjectActions';
import { useHistory } from 'react-router';
import TabListLayout from '../../components/Common/TabListLayout';
import { useDispatch } from 'react-redux';
import { getDrops } from '../../../services/DropsService';
import { getAllDrops } from '../../../store/selectors/DropSelector';
import { getNfts } from '../../../services/NftsService';


const useListMints = () => {
    const [drops, setdrops] = useState([]);
    const dropsAll = useSelector(getAllDrops);

    const projectsAll = useSelector((state) => state.projects.projects);
    const collectionsAll = useSelector((state) => state.collections.collections);
    const history = useHistory();
    
    useEffect(() => {
        console.log('all drops', dropsAll)
        setdrops(
            dropsAll.map((drop) => ({
                key: drop._id,
                image: drop.image,
                title: drop.name,
                description: drop.description,
                status: drop.status,
                projectId: drop.projectId,
                contractId: drop.contractId,
                onView: () => history.push(`/mint/${drop._id}`),
                menuItems: [],
            })),
        );
    }, [dropsAll, projectsAll, collectionsAll]);

    return [drops, projectsAll, collectionsAll];
};



const GetAlldrops = (collections) => {
    let alldrops = [];
    return async (dispatch) => {
        
        var getdropsByContract = async (contractId, key) => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) => {
                getDrops(contractId, key).then(
                    (response) => {
                        resolve(response.data);
                    },
                    () => {
                        resolve([]);
                    },
                );
            });
        };
        
        
        for (let i = 0; i < collections.length; i++) {
            const collection = collections[i];
           // const key = apiKeys.filter((key) => key.id === collection.projectId);
            const dropsByContract = await getdropsByContract(collection._id);
            dropsByContract.forEach((drop) => {
                alldrops = alldrops.concat({ ...drop, projectId: collection.projectId });
            });
        }
        
        dispatch(confirmedGetDropsAction(alldrops));
    
    };
};




const GetAllNfts = (collections) => {
    let allnfts = [];
    return async (dispatch) => {
        
        var getNftsByContract = async (contractId, key) => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) => {
                getNfts(contractId, key).then(
                    (response) => {
                        resolve(response.data);
                    },
                    () => {
                        resolve([]);
                    },
                );
            });
        };
        
        
        for (let i = 0; i < collections.length; i++) {
            const collection = collections[i];
           // const key = apiKeys.filter((key) => key.id === collection.projectId);
            const nftsByContract = await getNftsByContract(collection._id);
            nftsByContract.forEach((nft) => {
                allnfts = allnfts.concat({ ...nft, projectId: collection.projectId });
            });
        }
        
        dispatch(confirmedGetNftsAction(allnfts));
    
    };
};




const IndexMints = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [apiKeys, setApiKeys] = useState([]);
    const [drops, projectsAll, collectionsAll] = useListMints();

    const [projectIdFilter, setProjectIdFilter] = useState('all');
    const [collectionIdFilter, setCollectionIdFilter] = useState('all');
    const [dropsFiltered, setdropsFiltered] = useState(drops);

    useEffect(() => {
        if (apiKeys.length === projectsAll.length) {
            dispatch(GetAlldrops(collectionsAll, apiKeys));
            dispatch(GetAllNfts(collectionsAll, apiKeys));

        }
    }, [apiKeys, collectionsAll]);



    useEffect(() => {
        const fetch = async () => {
            projectsAll.forEach(async (project) => {
                const key = await dispatch(getProjectApiAction(project._id));
                setApiKeys((apiKeys) => [...apiKeys, { id: project._id, key: key.token }]);
            });
        };
        fetch();
    }, [projectsAll, dispatch]);

    useEffect(() => {
        setdropsFiltered(
            drops.filter((drop) => {
                return (
                    (drop.projectId === projectIdFilter || projectIdFilter === 'all') &&
                    (drop.contractId === collectionIdFilter || collectionIdFilter === 'all')
                );
            }),
        );
    }, [projectIdFilter, drops, projectsAll, collectionsAll, collectionIdFilter]);

    return (
        <TabListLayout
            data={dropsFiltered}
            filters={[
                {
                    label: 'Filter by Project',
                    options: [
                        {
                            value: 'all',
                            element: 'All projects',
                            index: 0,
                        },
                        ...projectsAll.map((project, index) => {
                            return {
                                value: project._id,
                                element: project.name,
                                index: index + 1,
                            };
                        }),
                    ],
                    onChangeFilter: setProjectIdFilter,
                },
                {
                    label: 'Filter by Collection',
                    options: [
                        {
                            value: 'all',
                            element: 'All collections',
                            index: 0,
                        },
                        ...collectionsAll.map((collection, index) => {
                            return {
                                value: collection._id,
                                element: collection.name,
                                index: index + 1,
                            };
                        }),
                    ],
                    onChangeFilter: setCollectionIdFilter,
                },
            ]}
            onChange={() => null}
            mainAction={{ action: () => history.push('/add-mint'), label: 'New Mint' }}
        ></TabListLayout>
    );
};

export default IndexMints;


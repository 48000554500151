export const CREATE_DROP_ACTION = '[Drop Action] Create Drop';
export const CONFIRMED_CREATE_DROP_ACTION = '[Drop Action] Confirmed Create Drop';
export const ERROR_CREATE_DROP_ACTION = '[Drop Action] Error Create Drop';
export const GET_DROPS = '[Drop Action] Get Drops';
export const CONFIRMED_GET_DROPS = '[Drop Action] Confirmed Get Drops';
export const EDIT_DROP_ACTION = '[Drop Action] Edit Drop';
export const CONFIRMED_EDIT_DROP_ACTION = '[Drop Action] Confirmed Edit Drop';
export const CONFIRMED_DELETE_DROP_ACTION = '[Drop Action] Confirmed Delete Drop';
export const GET_DROP_API = '[Drop Action] Get Drop Api';
export const CONFIRMED_GET_DROP_API = '[Drop Action] Confirmed Get Drop Api';
export const SET_CURRENT_DROP_ACTION = '[Drop Action] Set Current Drop';

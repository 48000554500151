/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import {  Modal,  Button } from 'react-bootstrap';
import { Col, Card, Row } from 'react-bootstrap';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { createMediaAction } from '../../../store/actions/MediaActions';
import { useHistory } from 'react-router-dom';
import wallet_deploy from '../../../assets/images/waiting_gif/wallet_deploy.gif';
import swal from 'sweetalert';

const AddMedia = () => {
    const [name, setName] = useState('');
    const [selectedFile, setSelectedFile] = useState('');

    const [projectId, setProjectId] = useState('');

    const currentProject = useSelector((state) => state.projects.currentProject);
    const dispatch = useDispatch();
    const history = useHistory();

    const [show, setShow] = useState(false);
    const showModal = () => setShow(true);
    const closeModal = () => setShow(false);

    useEffect(() => {
        setProjectId(currentProject._id);
    }, []);

    const onUpload = (event) => {
        event.preventDefault(); // Prevent default submission

        console.log('projectId', projectId);

        const form = new FormData();
        form.append('nftImage', selectedFile, name);

        showModal();

        dispatch(createMediaAction(form, history)).then(
            (media) => {
                closeModal();
                swal('Media has been created successfully', media.name, 'success');
            },
            (err) => {
                closeModal();
                swal('Error adding a new media', err, 'error');
            },
        );
    };

    return (
        <div className="badge-demo">
            <Breadcrumbs
                breadcrumbs={[
                    { path: 'dashboard', title: 'Home' },
                    { path: 'medias', title: 'Media' },
                    { path: 'add-media', title: 'Add media' },
                ]}
                activeMenu="add-media"
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Body>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Media</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="basic-form">
                                            <form onSubmit={onUpload}>
                                                <div className="mb-3 row">
                                                    <label className="col-sm-4 col-form-label">Media Name</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Name"
                                                            onChange={(e) => setName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <label className="col-sm-4 col-form-label">Media Upload</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            required
                                                            type="file"
                                                            className="form-control"
                                                            id="customFile"
                                                            onChange={(e) => {
                                                                console.log(e.target.files[0]);
                                                                setSelectedFile(e.target.files[0]);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* 
                                            <div class="mb-3 row">
                                                <label class="col-sm-4 col-form-label">Project</label>
                                                <div class="col-sm-8"> 
                                                    <select 
                                                    class=" form-control wide mb-3" 
                                                    id="collectionsymbol"
                                                    onChange={(e) => 
                                                    {  setProjectName(e.target.value.split(/[ ,]+/)[1])
                                                        setProjectId(e.target.value.split(/[ ,]+/)[0])
                                                    }
                                                    } >
                                            
                                                    {projectsNames}
                                                    </select>
                                                </div>               
                                            </div> */}

                                                <Button type="submit" className="me-2" variant="primary">
                                                    Upload
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal show={show}>
                                <Modal.Body>
                                    <img className="card-img-top img-fluid" src={wallet_deploy} alt="Card image cap"></img>
                                    <h2 className="text-center">Your Media is Uploading ...</h2>
                                </Modal.Body>
                                <Modal.Footer></Modal.Footer>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AddMedia;

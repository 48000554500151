/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getProjectApiAction } from '../../../store/actions/ProjectActions';
import TabListLayout from '../../components/Common/TabListLayout';
import { useUpdateSubtitle } from '../../../context/MenuTitle';
import { useDispatch } from 'react-redux';


const useListCollections = () => {
    const [setSubtitle] = useUpdateSubtitle();
    setSubtitle('');
    const history = useHistory();
    const [collections, setCollections] = useState([]);
    const projectsAll = useSelector((state) => state.projects.projects);
    const collectionsAll = useSelector((state) => state.collections.collections);

    useEffect(() => {
        setCollections(
            collectionsAll.map((collection) => ({
                key: collection._id,
                image: collection.imageUrl,
                title: collection.name,
                description: collection.description,
                status: collection.status,
               // state: 'draft',
                onView: () => history.push(`/collection/${collection._id}`),
                menuItems: [],
                projectId: collection.projectId,
                titleExtras: [
                    <span key={collection._id} className="collectionProject">
                        {projectsAll.find((project) => project._id === collection.projectId)?.name}
                    </span>,
                ],
            })),
        );
    }, [projectsAll, collectionsAll]);

    return [collections, projectsAll];
};


const IndexCollections = () => {
    const history = useHistory();
    const dispatch = useDispatch();


    const [collections, projectsAll] = useListCollections();
    const [apiKeys, setApiKeys] = useState([]);
    const [projectIdFilter, setProjectIdFilter] = useState('all');
    const [collectionFiltered, setCollectionFiltered] = useState(collections);
     
    useEffect(() => {
        const fetch = async () => {
            projectsAll.forEach(async (project) => {
                const key = await dispatch(getProjectApiAction(project._id));
                setApiKeys((apiKeys) => [...apiKeys, { id: project._id, key: key.token }]);
            });
        };
        fetch();
    }, [projectsAll, dispatch]);


    useEffect(() => {
        setCollectionFiltered(
            collections
                .filter((collection) => {
                    return collection.projectId === projectIdFilter || projectIdFilter === 'all';
                })
                .map((collection) => ({
                    ...collection,
                    ...{ titleExtras: projectIdFilter !== 'all' ? [<></>] : collection.titleExtras },
                })),
        );
    }, [projectIdFilter, collections, projectsAll]);

    return (
        <TabListLayout
            data={collectionFiltered}
            loading={!collections.length}
            filters={[
                {
                    label: 'Filter by Project',
                    options: [
                        {
                            value: 'all',
                            element: 'All projects',
                            index: 0,
                        },
                        ...projectsAll.map((project, index) => {
                            return {
                                value: project._id,
                                element: project.name,
                                index: index + 1,
                            };
                        }),
                    ],
                    onChangeFilter: setProjectIdFilter,
                },
            ]}
            onChange={() => null}
            mainAction={{ action: () => history.push('/add-collection'), label: 'New Collection' }}
            meta={{ total: collections.length, page: 1, perPage: 10, itemName: 'collections' }}
            menuItems = {[
                {
                    title: 'Edit',
                    action: () => {
                        console.log('Edit pressed');
                    },
                },
                {
                    title: 'Remove',
                    action: () => {
                        console.log('Remove pressed');
                    },
                },
            ]}
        ></TabListLayout>
    );
};

export default IndexCollections;

import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ breadcrumbs, activeMenu }) => {
    // let path = window.location.pathname.split("/");
    // console.log('path', path);
    return (
        <div className="row page-titles mx-0 rounded-bottom">
            <ol className="breadcrumb">
                {breadcrumbs.map((item, index) => (
                    <li
                        className={`breadcrumb-item ${activeMenu == item.path ? ' active' : ''}`}
                        key={`breadcrumb-${index}`}
                    >
                        <Link to={item.path}>{item.title}</Link>
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default Breadcrumbs;

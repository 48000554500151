import React, { useEffect, useState } from 'react';
import { Button, Col, Card, Row, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAction } from '../../../store/actions/UserActions';
import { getTiersAction, updateTierMemberAction } from '../../../store/actions/TierActions';

function PricingTiers() {
    const dispatch = useDispatch();

    const [basicPrice, setBasicPrice] = useState('');
    const [proPrice, setProPrice] = useState('');

    const [showPro, setShowPro] = useState(false);
    const [selectedTier, setSelectedTier] = useState({});
    const showModalPro = () => setShowPro(true);
    const closeModalPro = () => setShowPro(false);

    const [showBasic, setShowBasic] = useState(false);
    const showModalBasic = () => setShowBasic(true);
    const closeModalBasic = () => setShowBasic(false);

    const [showBasicUpgrade, setShowBasicUpgrade] = useState(false);
    const showModalBasicUpgrade = () => setShowBasicUpgrade(true);
    const closeModalBasicUpgrade = () => setShowBasicUpgrade(false);

    const [showFree, setShowFree] = useState(false);
    const showModalFree = () => setShowFree(true);
    const closeModalFree = () => setShowFree(false);

    const user = useSelector((state) => state.user.user);
    const subscription = useSelector((state) => state.user.subscription);
    const tiers = useSelector((state) => state.tiers.tiers);

    const { Paddle } = window;

    useEffect(() => {
        dispatch(getUserAction());
        dispatch(getTiersAction());
        setBasicPrice('9.99');
        setProPrice('249.99');
        fixprices();
    }, []);

    const fixprices = async () => {
        tiers.map(async (t) => {
            if (t.name == 'basic') {
                setBasicPrice(await t.recurring_price['USD']);
            }
            if (t.name == 'pro') {
                setProPrice(await t.recurring_price['USD']);
            }
        });
    };

    const onSubmitFree = (event) => {
        event.preventDefault(); // Prevent default submission
        console.log(user);
        if (!user.subscriptionId) {
            Paddle.Checkout.open({
                product: 26369,
                email: user.email,
                passthrough: '{"membership": "free"}',
                successCallback: 'checkoutComplete',
                closeCallback: function () {
                    dispatch(getUserAction());
                },
            });
        } else {
            showModalFree();
        }
    };

    const onSubmitBasic = (event) => {
        event.preventDefault(); // Prevent default submission

        if (!user.subscriptionId) {
            Paddle.Checkout.open({
                product: 25924,
                email: user.email,
                passthrough: '{"membership": "basic"}',
                closeCallback: function () {
                    dispatch(getUserAction());
                },
            });
        }
        if (user.membership == 'free') {
            tiers.map((t) => {
                if (t.name === 'basic') {
                    setSelectedTier(t);
                }
                return selectedTier;
            });
            showModalBasicUpgrade();
        } else {
            tiers.map((t) => {
                if (t.name === 'basic') {
                    setSelectedTier(t);
                }
                return selectedTier;
            });
            showModalBasic();
        }
    };

    const onSubmitPro = (event) => {
        if (!user.subscriptionId) {
            event.preventDefault(); // Prevent default submission
            Paddle.Checkout.open({
                product: 25982,
                email: user.email,
                passthrough: '{ "membership": "pro" }',
                closeCallback() {
                    dispatch(getUserAction());
                },
            });
        } else {
            tiers.map((t) => {
                if (t.name === 'pro') {
                    setSelectedTier(t);
                }
                return selectedTier;
            });
            showModalPro();
        }
    };

    const updateFromBasicToPro = () => {
        const tierData = {
            subscription_id: subscription.subscription_id,
            plan_id: '25982',
        };
        dispatch(updateTierMemberAction(tierData)).then(dispatch(getUserAction()));
        closeModalPro();
    };

    const updateFromProToBasic = () => {
        const tierData = {
            subscription_id: subscription.subscription_id,
            plan_id: '25924',
        };
        dispatch(updateTierMemberAction(tierData)).then(dispatch(getUserAction()));
        closeModalBasic();
    };

    const updateFromFreeToBasic = () => {
        const tierData = {
            subscription_id: subscription.subscription_id,
            plan_id: '25924',
        };
        dispatch(updateTierMemberAction(tierData)).then(dispatch(getUserAction()));
        closeModalBasicUpgrade();
    };

    const updateToFree = () => {
        const tierData = {
            subscription_id: subscription.subscription_id,
            plan_id: '26369',
        };
        dispatch(updateTierMemberAction(tierData)).then(dispatch(getUserAction()));
        closeModalFree();
    };

    let button1;
    let button2;
    let button3;
    if (user.membership === 'free') {
        button1 = (
            <Button disabled="true" variant="primary" onClick={onSubmitFree}>
                Current plan
            </Button>
        );
        button2 = (
            <Button variant="primary" onClick={onSubmitBasic}>
                Upgrade Now
            </Button>
        );
        button3 = (
            <Button variant="primary" onClick={onSubmitPro}>
                Upgrade Now
            </Button>
        );
    }
    if (user.membership === 'basic') {
        button1 = (
            <Button variant="primary" onClick={onSubmitFree}>
                Downgrade to free
            </Button>
        );
        button2 = (
            <Button disabled="true" variant="primary" onClick={onSubmitBasic}>
                Current plan
            </Button>
        );
        button3 = (
            <Button variant="primary" onClick={onSubmitPro}>
                Upgrade Now
            </Button>
        );
    }
    if (user.membership === 'pro') {
        button1 = (
            <Button variant="primary" onClick={onSubmitFree}>
                Downgrade to free
            </Button>
        );
        button2 = (
            <Button variant="primary" onClick={onSubmitBasic}>
                Downgrade to basic
            </Button>
        );
        button3 = (
            <Button disabled="true" variant="primary" onClick={onSubmitPro}>
                Current plan
            </Button>
        );
    }

    return (
        <div className="badge-demo">
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Body>
                            <div className="row">
                                <div className="col-xl-12">
                                    <h2>Pricing plans</h2>
                                    <p>Start lauinching nft for free, or update to a monthly subscription</p>
                                    <div className="row">
                                        <div className="col-xl-12 col-xxl-12 col-sm-12 ">
                                            <div className="form-head mb-4 d-flex flex-wrap align-items-center">
                                                <div className="me-auto">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <Card>
                                                                <Card.Header>
                                                                    <h2> FREE</h2>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <p
                                                                        style={{ color: 'black' }}
                                                                        className="font-w600  mb-0"
                                                                    >
                                                                        {' '}
                                                                        $0 / month
                                                                    </p>
                                                                    {button1}

                                                                    <ul style={{ color: 'grey', marginTop: '20px' }}>
                                                                        <li>- Mint NFT on Polligon testnet</li>
                                                                        <li>- Deploy smart contracts, wallets </li>
                                                                        <li>
                                                                            -Prepare your campaigns, drops, and test
                                                                            them in testnet
                                                                        </li>
                                                                    </ul>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <Card>
                                                                <Card.Header>
                                                                    <h2> BASIC</h2>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <p
                                                                        style={{ color: 'black' }}
                                                                        className="font-w600  mb-0"
                                                                    >
                                                                        {' '}
                                                                        $ {basicPrice} / month
                                                                    </p>
                                                                    {button2}

                                                                    <ul style={{ color: 'grey', marginTop: '20px' }}>
                                                                        <li>- Mint up to 10 NFT / month</li>
                                                                        <li>- Multiple networks available</li>
                                                                        <li>- Mutable or inmutable metadata </li>
                                                                    </ul>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <Card>
                                                                <Card.Header>
                                                                    <h2> PRO</h2>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <p
                                                                        style={{ color: 'black' }}
                                                                        className="font-w600  mb-0"
                                                                    >
                                                                        {' '}
                                                                        $ {proPrice} / month
                                                                    </p>
                                                                    {button3}

                                                                    <ul style={{ color: 'grey', marginTop: '20px' }}>
                                                                        <li>- Up to 10,000 API calls / month</li>
                                                                        <li>- Create your gaming NFT</li>
                                                                        <li>
                                                                            - Launch them using launching startegies{' '}
                                                                        </li>
                                                                    </ul>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-xxl-6 col-sm-6 ">
                                            <div className="overflow-hidden" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal show={showPro}>
                                <Modal.Body>
                                    <h2 className="text-center">
                                        Do you want to Upgrade your plan to: <b> {selectedTier.name} </b>{' '}
                                    </h2>
                                    <p>
                                        {' '}
                                        If you want to enjoy all the benefits of the {selectedTier.name} plan please
                                        keep following with the upgrade process
                                    </p>

                                    <p>Te new Plan conditions are the following</p>
                                    <ul>
                                        <li> Plan Name: {selectedTier.name} </li>
                                        <li> Monthly price: {selectedTier.name} </li>
                                        <li> bylling type: {selectedTier.billing_type} </li>
                                        <li> Recurring payments: {selectedTier.name} </li>
                                    </ul>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button
                                        type="button"
                                        className="btn btn-danger light"
                                        data-bs-dismiss="modal"
                                        onClick={closeModalPro}
                                    >
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={updateFromBasicToPro}>
                                        Upgrade Membership
                                    </button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showBasic}>
                                <Modal.Body>
                                    <h2 className="text-center">
                                        Do you want to Downdgrade your plan to: <b> {selectedTier.name} </b>{' '}
                                    </h2>
                                    <p>
                                        The {selectedTier.name} plan only operates on testnet, all the opearations are
                                        deployed in a chain that can disapear anytime. We strongly recomment to only use
                                        the free version for testing and designing purpuse.
                                    </p>
                                    <ul>
                                        <li> Plan Name: {selectedTier.name} </li>
                                        <li> Monthly price: {selectedTier.name} </li>
                                        <li> bylling type: {selectedTier.billing_type} </li>
                                        <li> Recurring payments: {selectedTier.name} </li>
                                    </ul>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button
                                        type="button"
                                        className="btn btn-danger light"
                                        data-bs-dismiss="modal"
                                        onClick={closeModalBasic}
                                    >
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={updateFromProToBasic}>
                                        Downdgrade Membership
                                    </button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showBasicUpgrade}>
                                <Modal.Body>
                                    <h2 className="text-center">
                                        Do you want to Upgrade your plan to: <b> {selectedTier.name} </b>{' '}
                                    </h2>
                                    <p>
                                        After updating to your new plan {selectedTier.name} you will be able to start
                                        operating in the etherum network. In the {selectedTier.name} plan each
                                        transaction has a cost, please read our guide before upgrading the.
                                    </p>
                                    <ul>
                                        <li> Plan Name: {selectedTier.name} </li>
                                        <li> Monthly price: {selectedTier.name} </li>
                                        <li> bylling type: {selectedTier.billing_type} </li>
                                        <li> Recurring payments: {selectedTier.name} </li>
                                    </ul>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button
                                        type="button"
                                        className="btn btn-danger light"
                                        data-bs-dismiss="modal"
                                        onClick={closeModalBasicUpgrade}
                                    >
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={updateFromFreeToBasic}>
                                        Upgrade Membership
                                    </button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showFree}>
                                <Modal.Body>
                                    <h2 className="text-center">
                                        Do you want to Downdgrade your plan to: <b> {selectedTier.name} </b>{' '}
                                    </h2>
                                    <p>
                                        The {selectedTier.name} plan only operates on testnet, all the opearations are
                                        deployed in a chain that can disapear anytime. We strongly recomment to only use
                                        the free version for testing and designing purpuse.
                                    </p>
                                    <ul>
                                        <li> Plan Name: {selectedTier.name} </li>
                                        <li> Monthly price: {selectedTier.name} </li>
                                        <li> bylling type: {selectedTier.billing_type} </li>
                                        <li> Recurring payments: {selectedTier.name} </li>
                                    </ul>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button
                                        type="button"
                                        className="btn btn-danger light"
                                        data-bs-dismiss="modal"
                                        onClick={closeModalFree}
                                    >
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={updateToFree}>
                                        Downdgrade Anyway
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default PricingTiers;

import React from 'react';
import { useSelector } from 'react-redux';

//Import
import knight from '../../../assets/images/knight.png';

const Home = () => {
   //onst stats = useSelector((state) => state.auth.auth.stats);
    const collections = useSelector((state) => state.collections?.collections?.length);
    const projects = useSelector((state) => state.projects?.projects?.length);
    const drops = useSelector((state) => state.drops?.drops?.length);
    const nfts = useSelector((state) => state.nfts?.nfts?.length);


    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-6 col-xxl-6 col-sm-6 ">
                            <div className="form-head mb-4 d-flex flex-wrap align-items-center">
                                <div className="me-auto">
                                    <h2 className="font-w600 mb-0">Welcome to Mintknight!</h2>
                                    <div style={{ height: '50px', width: '100%' }}></div>
                                    <p className="text-dark">
                                        We’re happy to see you again. These are the current stats:
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-xxl-6 col-sm-6" style={{ marginTop: '-20px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    justifyItems: 'center',
                                }}
                            >
                                <img
                                    src={knight}
                                    alt=""
                                    className=""
                                    style={{ alignSelf: 'center', maxWidth: '180px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="card overflow-hidden">
                                <div className="card-header" style={{}}>
                                    <h4 className="card-title" style={{ width: '100%', textAlign: 'center' }}>
                                        PROJECTS
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <span style={{ fontSize: '600%' }}>{projects}</span>
                                    </div>
                                </div>
                                <div className="card-footer pt-0 pb-0 text-center"></div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="card overflow-hidden">
                                <div className="card-header" style={{}}>
                                    <h4 className="card-title" style={{ width: '100%', textAlign: 'center' }}>
                                        COLLECTIONS
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <span style={{ fontSize: '600%' }}>{collections || 0}</span>
                                    </div>
                                </div>
                                <div className="card-footer pt-0 pb-0 text-center"></div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="card overflow-hidden">
                                <div className="card-header" style={{}}>
                                    <h4 className="card-title" style={{ width: '100%', textAlign: 'center' }}>
                                        DROPS
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <span style={{ fontSize: '600%' }}>{drops || 0}</span>
                                    </div>
                                </div>
                                <div className="card-footer pt-0 pb-0 text-center"></div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="card overflow-hidden">
                                <div className="card-header" style={{}}>
                                    <h4 className="card-title" style={{ width: '100%', textAlign: 'center' }}>
                                        NFTs
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <span style={{ fontSize: '600%' }}>{nfts || 0}</span>
                                    </div>
                                </div>
                                <div className="card-footer pt-0 pb-0 text-center"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    // } else if (0 === 2) {
    //     var items = [
    //         {
    //             title: 'Sell in Marketplaces',
    //             desc: 'Create campaigns in seconds and distribute them in marketplaces, or plug it on your own.',
    //             opts: ['OpenSea', 'Rarible', 'Monthly reports'],
    //         },
    //         {
    //             title: 'Social Airdrops',
    //             desc: 'Plan airdrop contests, social media and whitelisting through our panel,
    // get all the info related (hastag, RT) to our platform',
    //             opts: ['Telegram Airdrops', 'Hastag, RT, and sign airdrop', 'Contest airdrops using oracles'],
    //         },
    //         {
    //             title: 'Vouchers & pre-Sale',
    //             desc: 'Pre-Sale your final art in Vouchers in diferent drops.
    // Plan your strategy week to get more engagement',
    //             opts: ['Diferents images and prices', 'Whitelisted access to Drops', 'Rewards for early users'],
    //         },
    //     ];

    //     return (
    //         <>
    //             <Breadcrumbs breadcrumbs={[{ path: 'dashboard', title: 'Home' }]} activeMenu="dashboard" />
    //             <div className="row">
    //                 <div className="col-xl-12">
    //                     <div className="row">
    //                         <div className="col-xl-6 col-xxl-6 col-sm-6 ">
    //                             <div className="form-head mb-4 d-flex flex-wrap align-items-center">
    //                                 <div className="me-auto">
    //                                     <h2 className="font-w600 mb-0">Welcome to Mintknight!</h2>
    //                                     <div style={{ height: '50px', width: '100%' }}></div>
    //                                     <p className="text-dark">
    //                                         Welcome to Mintkight, now you are in the Playground Mode,
    // a place where you
    //                                         can create, play and learn in a safe enviroment.
    //                                     </p>
    //                                     <p className="text-dark">So, let’s play!</p>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-xl-6 col-xxl-6 col-sm-6 ">
    //                             <div
    //                                 style={{
    //                                     display: 'flex',
    //                                     alignItems: 'center',
    //                                     justifyContent: 'center',
    //                                     justifyItems: 'center',
    //                                 }}
    //                             >
    //                                 <img
    //                                     src={knight}
    //                                     alt=""
    //                                     className=""
    //                                     style={{ alignSelf: 'center', maxWidth: '250px' }}
    //                                 />
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="row" style={{ marginTop: '50px' }}>
    //                 <div className="col-xl-12">
    //                     <div className="row">
    //                         {items.map((item) => (
    //                             <div className="col-xl-4 col-lg-6 col-sm-6">
    //                                 <div className="card overflow-hidden">
    //                                     <div className="card-body">
    //                                         <div className="text-left">
    //                                             <h4 className="mt-4 mb-1">{item.title}</h4>
    //                                             <div style={{ height: '20px', width: '100%' }}></div>
    //                                             <p className="text-muted">{item.desc}</p>
    //                                             <div style={{ height: '10px', width: '100%' }}></div>

    //                                             <ol>
    //                                                 <li style={{}}>{item.opts[0]}</li>
    //                                                 <li style={{ marginTop: '10px' }}>{item.opts[1]}</li>
    //                                                 <li style={{ marginTop: '10px' }}>{item.opts[2]}</li>
    //                                             </ol>
    //                                         </div>
    //                                     </div>
    //                                     <div className="card-footer pt-0 pb-0 text-center"></div>
    //                                 </div>
    //                             </div>
    //                         ))}
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     );
    // } else return <></>;
};
export default Home;

import {
    CONFIRMED_CREATE_PROJECT_ACTION,
    CONFIRMED_DELETE_PROJECT_ACTION,
    CONFIRMED_EDIT_PROJECT_ACTION,
    CONFIRMED_GET_PROJECTS,
    CREATE_PROJECT_ACTION,
    CONFIRMED_GET_PROJECT_API,
    SET_CURRENT_PROJECT_ACTION,
} from '../actions/ProjectTypes';

const initialState = {
    projects: [],
    currentProject: {},
};

export default function ProjectssReducer(state = initialState, actions) {
    if (actions.type === CREATE_PROJECT_ACTION) {
        return {
            ...state,
            projects: [...state.projects, actions.payload],
        };
    }

    if (actions.type === CONFIRMED_DELETE_PROJECT_ACTION) {
        const projects = [...state.projects];
        const projectIndex = projects.findIndex((project) => project._id === actions.payload);

        projects.splice(projectIndex, 1);

        return {
            ...state,
            projects,
        };
    }

    if (actions.type === CONFIRMED_EDIT_PROJECT_ACTION) {
        const projects = [...state.projects];
        const projectIndex = projects.findIndex((project) => project._id === actions.payload._id);

        projects[projectIndex] = actions.payload;
        return {
            ...state,
            projects,
        };
    }

    if (actions.type === CONFIRMED_CREATE_PROJECT_ACTION) {
        const projects = [...state.projects];
        projects.push(actions.payload);

        return {
            ...state,
            projects: projects,
        };
    }

    if (actions.type === CONFIRMED_GET_PROJECTS) {
        return {
            ...state,
            projects: actions.payload.projects,
        };
    }

    if (actions.type === CONFIRMED_GET_PROJECT_API) {
        return {
            ...state,
            apikey: actions.payload.token,
        };
    }

    if (actions.type === SET_CURRENT_PROJECT_ACTION) {
        return {
            ...state,
            currentProject: actions.payload,
        };
    }

    return state;
}

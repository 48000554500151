/* eslint-disable no-unused-vars */
import React, {  useState,  useEffect, Fragment } from 'react';
import { Modal,  ListGroup, Button } from 'react-bootstrap';
import { Col, Card, Row } from 'react-bootstrap';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import wallet_deploy from '../../../assets/images/waiting_gif/wallet_deploy.gif';

const AddWallet = () => {
    const projectsAll = useSelector((state) => state.projects.projects);

    const [name, setName] = useState('test Wallet');
    const [internalName, setInternalName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [projectId, setProjectId] = useState('');

    const [show, setShow] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const showModal = () => setShow(true);
    const closeModal = () => setShow(false);

    /*    const projectsNames = projectsAll.map((project, index) => 
    
    <option value={[project._id, project.name, index] } selected>{project.name}</option>
);
*/
    const projectsNames = projectsAll.map((project, index) => {
        if (index > 0) {
            return <option value={[project._id, project.name, index]}>{project.name}</option>;
        } else {
            return (
                <option value={[project._id, project.name, index]} selected>
                    {project.name}
                </option>
            );
        }
    });

    useEffect(() => {
        const seting_first_project = projectsAll.map((project, index) => {
            if (index == 0) {
                setProjectId(project._id);
            }
        });
    }, [projectId]);

    const onSubmit = (event) => {
        //  return async dispatch => {
        event.preventDefault(); // Prevent default submission
        const singleWallet = {
            userRef: internalName,
        };

        showModal();
        try {
            //dispatch(addWalletAction(singleWallet, history));
        } catch (e) {
            alert(`Wallet creation failed! ${e.message}`);
        }
    };

    return (
        <div className="badge-demo">
            <Breadcrumbs motherMenu="Wallets" activeMenu="Add" pageContent="Add" />
            <Row>
                <Col lg="12">
                    <Card>
                        {console.log(projectId)}
                        <Card.Body>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Wallet</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="basic-form">
                                            <form onSubmit={onSubmit}>
                                                <div className="mb-3 row">
                                                    <label className="col-sm-4 col-form-label">Internal Name</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Internal Name"
                                                            onChange={(e) => setInternalName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <label className="col-sm-4 col-form-label">Project</label>
                                                    <div className="col-sm-8">
                                                        <select
                                                            className="default-select form-control wide mb-3"
                                                            id="collectionsymbol"
                                                            //  defaultValue={{ value: [projectsAll[0]._id, projectsAll[0].name]}}
                                                            onClick={(e) => {
                                                                setProjectName(e.target.value.split(/[ ,]+/)[1]);
                                                                setProjectId(e.target.value.split(/[ ,]+/)[0]);
                                                            }}
                                                        >
                                                            {projectsNames}
                                                        </select>
                                                    </div>
                                                </div>

                                                <Button type="submit" className="me-2" variant="primary">
                                                    Next
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Wallet Info</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="basic-list-group">
                                                <ListGroup as="ul">
                                                    <Fragment key="1">
                                                        <ListGroup.Item as="li">
                                                            <i className="fa fa-exclamation-circle text-success" />
                                                            &nbsp; The name of the wallet corresponds to your Company,
                                                            Wallet Inside the Company if any or the na me of the Client
                                                            you are giving Service to.
                                                        </ListGroup.Item>
                                                    </Fragment>
                                                    <Fragment key="2">
                                                        <ListGroup.Item as="li">
                                                            <i className="fa fa-exclamation-circle text-success" />
                                                            &nbsp; The description is your reference to the NFT wallet
                                                            you are building.
                                                        </ListGroup.Item>
                                                    </Fragment>
                                                </ListGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal show={show}>
                                <Modal.Body>
                                    <img className="card-img-top img-fluid" src={wallet_deploy} alt="Card image cap"></img>
                                    <>
                                        {' '}
                                        <h2 className="text-center">Your wallet is being created</h2>
                                    </>
                                </Modal.Body>
                                <Modal.Footer>
                                    {/*   <Button variant="secondary"  onClick={handleClose}> Close Modal</Button> */}
                                </Modal.Footer>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AddWallet;

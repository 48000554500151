import axios from 'axios';
import axiosInstance from '../services/AxiosInstance';
import swal from 'sweetalert';
import { loginConfirmedAction, logoutAction } from '../store/actions/AuthActions';
import { getProjectAction, setCurrentProjectAction, getProjectApiAction } from '../store/actions/ProjectActions';
import { getCollectionsFromProjectAction } from '../store/actions/CollectionActions';
import { getWalletsFromProjectAction } from '../store/actions/WalletsActions';
import { getMediasFromProjectAction } from '../store/actions/MediaActions';

const authHeader = (token) => ({ headers: { Authorization: `Bearer ${token}` } });

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        telephone: '5551111',
    };
    return axios.post(`${process.env.REACT_APP_API_SERVICE}/users/v2/register`, postData);
}

export function login(email, password) {
    const postData = {
        email,
        password,
    };
    return axios.post(`${process.env.REACT_APP_API_SERVICE}/users/v2/login`, postData);
}

export function getUserData(userId, token) {
    const route = `users/v2/userInfo/${userId}`;
    if (token) return axios.get(`${process.env.REACT_APP_API_SERVICE}/${route}`, authHeader(token));
    return axiosInstance.get(route);
}

export function getUserProjects(token) {
    const route = `projects/v2`;
    if (token) return axios.get(`${process.env.REACT_APP_API_SERVICE}/${route}`, authHeader(token));
    return axiosInstance.get(route);
}

export function getUserAvailableNetworks(token) {
    const route = `users/v2/availableNetworks/`;
    if (token) return axios.get(`${process.env.REACT_APP_API_SERVICE}/${route}`, authHeader(token));
    return axiosInstance.get(route);
}

export function formatError(errorResponse) {
    console.log('formating error', errorResponse);
    swal('Oups', errorResponse?.error, 'error');
}

export function saveTokenInLocalStorage(tokenDetails) {
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    console.log('runLogoutTimer', timer);
    setTimeout(() => {
        dispatch(logoutAction(history));
    }, timer);
}

export async function checkAutoLogin(dispatch, history) {

    const tokenDetailsString = localStorage.getItem('userDetails');

    if (!tokenDetailsString) {
        dispatch(logoutAction(history));
        return;
    }

    const tokenDetails = JSON.parse(tokenDetailsString);
    const now = new Date();

    const expireDate = tokenDetails.expireDate
        ? new Date(tokenDetails.expireDate)
        : new Date(Date.now() + 60 * 60 * 1000); // in an hour


    if (now > expireDate) {
        dispatch(logoutAction(history));
        return;
    }

    await getAllUserData(tokenDetails, dispatch);
    
    const projects = await dispatch(getProjectAction());
    
    const currentProject = projects[0];



    dispatch(setCurrentProjectAction(currentProject));
    // Get project api key
    await dispatch(getProjectApiAction(currentProject._id));
    
    // hem de retornar totes els wallets i les collecions

     projects.map( async (project) => {
        await dispatch(getCollectionsFromProjectAction(project._id));
        await dispatch(getWalletsFromProjectAction(project._id));
        await dispatch(getMediasFromProjectAction(project._id));
 
    })
    
    
    const timer = expireDate.getTime() - now.getTime();
    runLogoutTimer(dispatch, timer, history);
}

/**
 * Returns the array of networks the logged in user has access to
 * @returns {Promise<any[]>}
 */
export async function getUserNetworks() {
    try {
        const { data } = await getUserAvailableNetworks();
        return data;
    } catch (error) {
        formatError(error);
        return [];
    }
}

export async function getAllUserData(tokenDetails, dispatch) {
    try {
      

        Promise.all([
            getUserAvailableNetworks(tokenDetails.token),
        ]).then(([availableNetworks]) => {
            
            let availTmp = availableNetworks.data.map(element => {
                return element.name;
            });

            dispatch(loginConfirmedAction({ ...tokenDetails, availableNetworks: availTmp  }));
          
        
        })      
        
    } catch (error) {
        console.error(`🐛 | getAllUserData`, error);
        formatError(error);
        dispatch(loginConfirmedAction(tokenDetails));
    }
}

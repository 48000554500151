import ethereum from '../../assets/images/networks/ethereum.png';
import polygon from '../../assets/images/networks/polygon.png';
import xdai from '../../assets/images/networks/xdai.png';
import celo from '../../assets/images/networks/celo.png';

const allNetworks = [
    {
        name: 'Mumbai (testnet)',
        value: 'localhost',
        imgSrc: xdai,
        description: 'MUMBAI_NETWORK_DESCRIPTION',
    },
    {
        name: 'Polygon',
        value: 'polygon',
        imgSrc: polygon,
        description: 'POLYGON_NETWORK_DESCRIPTION',
    },
    {
        name: 'Etherum',
        value: 'ethereum',
        imgSrc: ethereum,
        description: 'ETHEREUM_NETWORK_DESCRIPTION',
    },
    {
        name: 'XDai',
        value: 'xdai',
        imgSrc: xdai,
        description: 'XDAI_NETWORK_DESCRIPTION',
    },
    {
        name: 'Celo',
        value: 'celo',
        imgSrc: celo,
        description: 'CELO_NETWORK_DESCRIPTION',
    },
];

export const getNetworks = (state) => {
    const availableNetworks = state.auth.auth.availableNetworks;
    if (Array.isArray(availableNetworks) && availableNetworks.length) {
        let tmp = allNetworks.filter((network) => availableNetworks.some((name) => name === network.name))
        return tmp;
    }
    return [];
};

export const isAuthenticated = (state) => {
    if (state.auth.auth.token) return true;
    return false;
};

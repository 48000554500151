import axios from 'axios';
import { store } from '../store/store';

const axiosService = axios.create({
    baseURL: process.env.REACT_APP_API_SERVICE,
});

axiosService.interceptors.request.use((config) => {
    const state = store.getState();
    const apikey = state.projects.apikey;

    config.headers.Authorization = `Bearer ${apikey}`;
    config.params = config.params || {};

    return config;
});

export default axiosService;

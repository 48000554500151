import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Col, Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectAction } from '../../../store/actions/ProjectActions';
import { useHistory } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { useUpdateSubtitle } from '../../../context/MenuTitle';
import { getNetworks } from '../../../store/selectors/AuthSelectors';
import { getProjectById } from '../../../store/selectors/ProjectSelectors';
import { getCollectionsByProjectId } from '../../../store/selectors/CollectionSelector';
import { useTranslation } from 'react-i18next';
import CommonCard from '../../components/Common/Card';
import FileInput from '../../components/Common/FileInput';
import Stats from '../../components/Common/Stats';

import Transferable from '../../../assets/images/collection_props/Transferable.svg';
import Mutable from '../../../assets/images/collection_props/Mutable.svg';
import OnChain from '../../../assets/images/collection_props/OnChain.svg';

const useProjectForm = () => {
    const [setSubtitle] = useUpdateSubtitle();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [network, setNetwork] = useState('mumbai');
    const [collections, setCollections] = useState([]);
    const [image, setImage] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    let { id: projectId } = useParams();

    const project = useSelector((state) => getProjectById(state, projectId));
    const projectCollections = useSelector((state) => getCollectionsByProjectId(state, projectId));
    const networks = useSelector(getNetworks);
    useEffect(() => {
        if (project) {
            setSubtitle(project.name);
            setName(project.name);
            setDescription(project.description);
            setNetwork(networks.find((net) => net.value === project.network));
            setCollections(projectCollections);
            setImage(project.image);
        } else {
            setSubtitle('New project');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, project?.id, networks]);

    const onSave = () => {
        dispatch(updateProjectAction({ ...project, name, description, network: network.value, image }, history));
    };

    return {
        projectId,
        name,
        description,
        image,
        network,
        collections,
        setName,
        setDescription,
        setImage,
        onSave,
        history,
    };
};

const ViewProject = () => {
    const {
        projectId,
        name,
        description,
        image,
        network,
        collections,
        setName,
        setDescription,
        setImage,
        onSave,
        history,
    } = useProjectForm();
    const { t } = useTranslation();
    return (
        <form>
            <Stats />
            <Card className="p-3">
                <Row>
                    <Col md={3} sm={4} xs={12} style={{}}>
                        <Form.Label htmlFor="description" className="text-primary">
                            {t('PROJECTS_FORM_EDIT_THUMBNAIL')}
                        </Form.Label>
                        <div className="thumbnail-centered">
                            <FileInput
                                initialFile={image}
                                onFileAdded={({ file }) => setImage(file)}
                                onFileRemoved={() => setImage()}
                            />
                        </div>
                    </Col>
                    <Col md={6} sm={4} xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description" className="text-primary">
                                {t('PROJECTS_FORM_NAME')}
                            </Form.Label>

                            <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="">
                            <Form.Label htmlFor="description" className="text-primary">
                                {t('PROJECTS_FORM_DESCRIPTION')}
                            </Form.Label>

                            <Form.Text
                                as="textarea"
                                name="description"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                style={{ height: '150px' }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={4} xs={12}>
                        <Form.Label className="text-primary"> {t('PROJECTS_FORM_NETWORK')}</Form.Label>
                        <div
                            id={`network`}
                            className={`rounded border d-flex flex-column b border border-3 p-2 `}
                            style={{ minHeight: 'calc(100% - 32px)', height: 'auto' }}
                        >
                            <div className="thumbnail-container d-flex align-content-center flex-wrap">
                                <img
                                    src={network?.imgSrc}
                                    alt={network?.name}
                                    style={{ maxHeight: 100, maxWidth: '100%', width: 'auto' }}
                                    className="mx-auto d-block"
                                />
                            </div>
                            <h4 className="fs-4 mt-1 text-center">{network?.name}</h4>
                            <p className="m0">{t(network?.description)}</p>
                        </div>
                    </Col>
                </Row>
            </Card>
            <Row>
                <Col xs={12}>
                    <Card className="p-3">
                        <div className="title-and-action">
                            <Form.Label className="text-primary">{t('PROJECTS_FORM_EDIT_COLLECTIONS')}</Form.Label>

                            <Button
                                type="button"
                                className="btn-sm "
                                variant="outline-primary"
                                onClick={() => history.push(`/add-collection?project=${projectId}`)}
                            >
                                {t('PROJECTS_FORM_EDIT_COLLECTIONS_ADD')} +
                            </Button>
                        </div>
                        <Container className="mt-2 min-300">
                            <Row className="row row-cols-sm-2 row-cols-md-4 row-cols-md-4 gy-3  g-xs-1 g-md-3 flex-wrap">
                                {collections.length ? (
                                    collections.map((collection, index) => (
                                        <CommonCard
                                            showFooter={false}
                                            image = {collection.imageUrl}
                                            titleExtras={[
                                                <img key="1" src={Transferable} style={{ marginRight: '4px' }} />,
                                                <img key="1" src={Mutable} style={{ marginRight: '4px' }} />,
                                                <img key="2" src={OnChain} />,
                                            ]}
                                            key={`CommonCard-${index}`}
                                            title={collection.name}
                                            description={collection.description}
                                            variant="darker"
                                        />
                                    ))
                                ) : (
                                    <p>{t('PROJECTS_FORM_EDIT_COLLECTIONS_EMPTY')}</p>
                                )}
                            </Row>
                        </Container>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={'d-flex justify-content-end mb-3'}>
                    <Button
                        disabled={!name.trim()}
                        type="button"
                        className="me-2"
                        variant="primary"
                        onClick={() => onSave()}
                    >
                        {t('PROJECTS_FORM_EDIT_SAVE')}
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default ViewProject;

//import { getTask } from '../../services/TasksService';

import { addWallet, getWallet, getOneWallet, getWalletsFromProject, deployWallet } from '../../services/WalletsService';
import {
    CONFIRMED_ADD_WALLET_ACTION,
    CONFIRMED_GET_WALLET,
    CONFIRMED_GET_WALLETS,
    SET_CURRENT_WALLET_ACTION,
} from './WalletsTypes';
import swal from 'sweetalert';
import { encriptSKey1 } from '../../utils/walletUtils';

import {
    getTask,
} from '../../services/TasksService';

export function addWalletAction(walletData, history) {
    return (dispatch, getState) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            let walletResponse;
            try {
                walletResponse = await addWallet(walletData);
            } catch (error) {
                console.log('addWallet error', error);
                reject(error);
                return;
            }

            console.log('walletResponse', walletResponse);
            if (!!walletResponse.data.status && walletResponse.data.status.toLowerCase() == 'failed') {
                console.log('addWallet error (status)', walletResponse.data.error);
                reject(`Wallet creation failed! ${walletResponse.data.error}`);
                return;
            }

            let wallet = walletResponse.data.wallet;
            console.log('wallet', wallet);
            console.log('skey1', walletResponse.data.skey1);
            if(wallet) {
                console.log(wallet)
                swal(
               'You succesfully created a project. Please write down your private Key for this project wallet. This is the only time we will show it to you. Please keep it safe!', 
                walletResponse.data.skey1, 
                'success');
  }
            dispatch(confirmedAddWalletAction(wallet));

            const authState = getState().auth;
            encriptSKey1(walletResponse.data.skey1, authState.auth.userId);

            if (history) history.push('/wallets');

            resolve(wallet._id);
        });
    };
}


export function deployWalletAction(walletId) {
    return (dispatch, ) => {
       
    // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            let walletResponse; 
           
            try {
                walletResponse = await deployWallet(walletId);
            } catch(error) {
                console.log('addWallet error', error);
                reject(error);
                return;
            }
            console.log('walletResponse', walletResponse);
            if (!!walletResponse.data.status && walletResponse.data.status.toLowerCase() == "failed"){
                console.log('addWallet error (status)', walletResponse.data.error);
                reject(`Wallet creation failed! ${walletResponse.data.error}`);
                return;
            }

            let taskResponse, taskSuccessful = false;
            for (let i = 0; i < 200; i++) {
                try {
                    taskResponse = await getTask(walletResponse.data.taskId);
                    console.log('tries', i);
                    if (taskResponse.data.state === 'running' || taskResponse.data.state === 'queued') {
                        await new Promise((r) => setTimeout(r, 5000));
                    } else {     
                        taskSuccessful = true;
                        break;
                    }
                }

               
                catch(error) {
                    console.log('getTask error', error);
                }
                console.log(taskSuccessful)
                dispatch(confirmedAddWalletAction(walletResponse));
            }

    });
};
}




/*


export function addWalletAction(walletData, history) {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {

            let walletResponse; 
            try {
                walletResponse = await addWallet(walletData);
            } catch(error) {
                console.log('addWallet error', error);
                reject(error);
                return;
            }

            console.log('walletResponse', walletResponse);
            if (!!walletResponse.data.status && walletResponse.data.status.toLowerCase() == "failed"){
                console.log('addWallet error (status)', walletResponse.data.error);
                reject(`Wallet creation failed! ${walletResponse.data.error}`);
                return;
            }

            let taskResponse, taskSuccessful = false;
            for (let i = 0; i < 20; i++) {
                try {
                    taskResponse = await getTask(walletResponse.data.taskId);
                    console.log('tries', i);
                    if (taskResponse.data.state === 'running' || taskResponse.data.state === 'queued') {
                        await new Promise((r) => setTimeout(r, 5000));
                    } else {     
                        taskSuccessful = true;
                        break;
                    }
                }
                catch(error) {
                    console.log('getTask error', error);
                }
            }

            if (!taskSuccessful) {
                console.log('addWallet error (timeout)');
                reject(`Creation wallet failed! Timeout error`);
                return;
            }
        
            let wallet = walletResponse.data.wallet;
            console.log('wallet', wallet);
            console.log('skey1', walletResponse.data.skey1);
            wallet.address = taskResponse.data.contractAddress;
            dispatch(confirmedAddWalletAction(wallet));

            const authState = getState().auth;
            encriptSKey1(walletResponse.data.skey1, authState.auth.userId, authState.userPassord);

            if (!!history)
                history.push('/wallets');

            resolve(wallet._id);
        });
    };
}
















*/









export function getWalletAction() {
    return (dispatch, ) => {
        getWallet().then((response) => {
            //let wallet = formatWallets(response.data);
            dispatch(confirmedGetWalletAction(response.data));
        });
    };
}

export function getOneWalletAction() {
    return (dispatch, ) => {
        getOneWallet().then((response) => {
            //let wallet = formatWallets(response.data);
            dispatch(confirmedGetOneWalletAction(response.data));
        });
    };
}

export function confirmedAddWalletAction(singleWallet) {
    return {
        type: CONFIRMED_ADD_WALLET_ACTION,
        payload: singleWallet,
    };
}

export function confirmedGetWalletAction(wallet) {
    return {
        type: CONFIRMED_GET_WALLET,
        payload: wallet,
    };
}

export function confirmedGetOneWalletAction(wallet) {
    return {
        type: CONFIRMED_GET_WALLET,
        payload: wallet,
    };
}

// export function confirmedUpdateWalletAction(wallet) {
//     return {
//         type: CONFIRMED_EDIT_WALLET_ACTION,
//         payload: wallet,
//     };
// }


export function getWalletsFromProjectAction(projectId) {
    return (dispatch, ) => {
        return new Promise((resolve) => {
            getWalletsFromProject(projectId).then((response) => {
                let wallets = response.data[0];
                dispatch(confirmedAddWalletsAction(wallets));
                resolve(wallets);
            });
        });
    };
}

export function confirmedGetWalletsAction(wallets) {
    return {
        type: CONFIRMED_GET_WALLETS,
        payload: wallets,
    };
}

export function confirmedAddWalletsAction(wallet) {
    return {
        type: CONFIRMED_ADD_WALLET_ACTION,
        payload: wallet,
    };
}




export function setCurrentWalletAction(wallet) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_WALLET_ACTION,
            payload: wallet,
        });
    };
}

import {
    createCollection,
    getCollectionsFromProject,
    deleteCollection,
    updateCollection,
    deployCollection,

} from '../../services/CollectionsService';

import {
    CONFIRMED_CREATE_COLLECTION_ACTION,
    CONFIRMED_DEPLOY_COLLECTION_ACTION,
    CONFIRMED_DELETE_COLLECTION_ACTION,
    CONFIRMED_EDIT_COLLECTION_ACTION,
    CONFIRMED_GET_COLLECTIONS,
    SET_CURRENT_COLLECTION_ACTION,
} from './CollectionTypes';

import {
    getTask,
} from '../../services/TasksService';




export function saveCollectionAction(formData, history) {

    return (dispatch) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const collectionData = {
                name: formData.name,
                description: formData.description,
                erc: parseInt(formData.erc),
                projectId: formData.projectId,
                contractType: parseInt(formData.erc),
                minter: formData.minter,
                owner: formData.owner,
                image: formData.image,
                reveal: formData.reveal,
                transferable: formData.transferable,
                symbol :formData.symbol
            };

            let collectionResponse;
            try {
                collectionResponse = await createCollection(collectionData);
            } catch (error) {
                console.log('deployCollection error', error);
                reject(error);
                return;
            }
            

            let contract = collectionResponse.data.contract;
            dispatch(confirmedDeployCollectionsAction(contract));
            if (history) history.push('/collections');

            resolve(contract);
        });
    };
}



export function deployCollectionAction(contractId) {
    return (dispatch, ) => {
       
       
        return new Promise( (resolve, reject) => {

            let contractResponse; 
           
            try {
                contractResponse = deployCollection(contractId);
            } catch(error) {
                console.log('deploy contract error', error);
                reject(error);
                return;
            }
            if (!!contractResponse.data.status && contractResponse.data.status.toLowerCase() == "failed"){
                console.log('add collection error (status)', contractResponse.data.error);
                reject(`Wallet creation failed! ${contractResponse.data.error}`);
                return;
            }

            let taskResponse, taskSuccessful = false;
            for (let i = 0; i < 20; i++) {
                try {
                    taskResponse = getTask(contractResponse.data.taskId);
                    console.log('tries', i);

                    if (taskResponse.data.state === 'running' || taskResponse.data.state === 'queued') {
                        new Promise((r) => setTimeout(r, 5000));
                    } else {     
                        taskSuccessful = true;
                        break;
                    }
                }
                catch(error) {
                    console.log('getTask error', error);
                }

            }

            if (!taskSuccessful) {
                console.log('addContract error (timeout)');
                reject(`Creation wallet failed! Timeout error`);
                return;
            }
        
            let contract = contractResponse.data.contract;
           
            contract.address = contractResponse.data.contractAddress;
            
            dispatch(confirmedDeployCollectionsAction(contract));

         
            resolve(contract._id);

    });
};
}








export function confirmedDeployCollectionsAction(singleCollection) {
    return {
        type: CONFIRMED_DEPLOY_COLLECTION_ACTION,
        payload: singleCollection,
    };
}

export function deleteCollectionAction(collectionId, history) {
    return (dispatch) => {
        deleteCollection(collectionId).then(() => {
            dispatch(confirmedDeleteCollectionAction(collectionId));
            history.push('/collections');
        });
    };
}
export function confirmedDeleteCollectionAction(collectionId) {
    return {
        type: CONFIRMED_DELETE_COLLECTION_ACTION,
        payload: collectionId,
    };
}

export function createCollectionAction(collectionData, history) {
    return (dispatch) => {
        createCollection(collectionData).then((response) => {
            const singleCollection = {
                ...collectionData,
                id: response.data.name,
            };
            dispatch(confirmedCreateCollectionsAction(singleCollection));
            history.push('/collections');
        });
    };
}
export function confirmedCreateCollectionsAction(singleCollection) {
    return {
        type: CONFIRMED_CREATE_COLLECTION_ACTION,
        payload: singleCollection,
    };
}

export function getCollectionsFromProjectAction(projectId) {
    return (dispatch) => {
        return new Promise((resolve) => {
            getCollectionsFromProject(projectId).then((response) => {
                let collections = response.data;
                for (let i=0; i<collections.length; i++ ){
                      
                     dispatch(confirmedGetCollectionsAction(collections[i]));
                }
                resolve(collections);
            });
        });
    };
}
export function confirmedGetCollectionsAction(collections) {
    return {
        type: CONFIRMED_GET_COLLECTIONS,
        payload: collections,
    };
}

export function updateCollectionAction(collection, history) {
    return (dispatch) => {
        updateCollection(collection, collection._id)
        .then(({data}) => {
            if (String(data.contract.status).toLowerCase() === 'failed') throw data;
            dispatch(confirmedUpdateCollectionAction(data.contract));
            history.push('/collections');
        })
        .catch((error) => {
            console.error(error, JSON.stringify(error));
   //         handleFailure(
   //             { error: error.status ? error.error : error.message },
   //             dispatch,
   //             ERROR_UPDATE_COLLECTION_ACTION,
   //         );
        });
    };
}




export function confirmedUpdateCollectionAction(collection) {
    return {
        type: CONFIRMED_EDIT_COLLECTION_ACTION,
        payload: collection,
    };
}

export function setCurrentCollectionAction(collection) {
    return (dispatch) => {
        dispatch({ type: SET_CURRENT_COLLECTION_ACTION, payload: collection });
    };
}

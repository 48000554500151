import React, { useState } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createDropAction } from '../../../store/actions/DropActions';
import { useHistory } from 'react-router-dom';
//import { RangeDatePicker, DatePicker } from "@y0c/react-datepicker";


import FileInput from '../../components/Common/FileInput';
import { useTranslation } from 'react-i18next';
import { useUpdateSubtitle } from '../../../context/MenuTitle';
//import { setCurrentProjectAction, getProjectApiAction } from '../../../store/actions/ProjectActions';
//import {addWalletAction} from '../../../store/actions/WalletsActions'

const useDropForm = () => {
    const [setSubtitle] = useUpdateSubtitle();
    setSubtitle('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [projectId, setProjectId] = useState();    
    const [walletId] = useState();
    const [collectionId] = useState();
    const [image, setImage] = useState('');

    const [twitter, setTwitter] = useState('');
    const [retwit, setRetwit] = useState('');
    const [liketweet, setLiketweet] = useState('');
    const [discord, setDiscord] = useState('');
    const [telegram, setTelegram] = useState('');




    const dispatch = useDispatch();
    const history = useHistory();
    const projectsAll = useSelector((state) => state.projects.projects);

      const selectProject = (projectId) => {
       const newSelectedproject = projectsAll.find((project) => project._id === projectId);
        setProjectId(newSelectedproject._id);
    };

    const  onCreate = async (goToNewCollection = false) => {

  
          const dropInfo = {
            name: name, 
            description: description, 
            image: image,
            dropType: 'fifo',
            useCodes: 0 ,
            isDirectMinting: 0 ,
            price: '1',
            state: 'mint',
            coin: 'usdc',
            startDate: "2022-06-12T00:00:00.000+00:00",
            endDate: "2022-08-14T00:00:00.000+00:00",
          }
          

       dispatch(createDropAction(dropInfo, collectionId, walletId, history, goToNewCollection));
           
    };


    return { name, description, image, setName, setDescription, setImage, projectsAll, projectId,
         onCreate, selectProject, setTwitter, setRetwit, setLiketweet,
         setDiscord, setTelegram, twitter, retwit, liketweet, discord, telegram };
   };

const AddDrop = () => {
    const { name, description, image, setName, setDescription, setImage, projectsAll, projectId,
    onCreate, selectProject, setTwitter, setRetwit, setLiketweet,
    setDiscord, setTelegram, twitter, retwit, liketweet, discord, telegram} = useDropForm();
    const { t } = useTranslation();
    

    return (
        <form>
            <Card className="p-3">
                <Row>
                    <Col md={3} sm={4} xs={12} style={{}}>
                        <Form.Label htmlFor="description" className="text-primary">
                            {t('PROJECTS_FORM_EDIT_THUMBNAIL')}
                        </Form.Label>
                        <div className="thumbnail-centered">
                            <FileInput
                                initialFile={image}
                                onFileAdded={({ file }) => setImage(file)}
                                onFileRemoved={() => setImage()}
                            />
                        </div>
                    </Col>
                    <Col md={3} sm={4} xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description" className="text-primary">
                                {t('PROJECTS_FORM_NAME')}
                            </Form.Label>

                            <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="">
                            <Form.Label htmlFor="description" className="text-primary">
                                {t('PROJECTS_FORM_DESCRIPTION')}
                            </Form.Label>

                            <Form.Text
                                as="textarea"
                                name="description"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('PROJECTS_FORM_DESCRIPTION')}
                                style={{ height: '150px' }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={4} xs={12}>
                    <Form.Group className="mb-3">
                                    <Form.Label htmlFor="description" className="text-primary">
                                        Link it to a Project
                                    </Form.Label>

                                   
                                    <Row>
                                        <Col sm={4} xs={6}>
                                            <select
                                                className="form-control mt-1 selecto"
                                                onChange={(event) => selectProject(event.target.value)}
                                                value={projectId}
                                            >
                                                {projectsAll.map(({ _id, name }) => (
                                                    <option key={_id} value={_id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col sm={8} xs={6}>
                                            <Button
                                                type="button"
                                                className="mt-2 mb-1"
                                                variant="outline-primary"
                                                onClick={() => history.push('/add-project')}
                                            >
                                                Project{' '}
                                                <span className="btn-icon-end">
                                                    <i className="fa fa-plus-circle" />
                                                </span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                    
                    </Col>
                </Row>
            </Card>


            <Row>
            <Col md={6} sm={6} xs={12}>
            <Card className="p-3">
                <Row>

                { /* ACQUISING STRATEGIES */}
                    <Form.Group className="mb-3">
                         <Form.Label htmlFor="description" className="text-primary">
                            {t('DROP_STRATEGIES_TITLE')}
                        </Form.Label>
                        <p>{t('DROP_STRATEGIES_DESCRIPTION')}</p>
                        <p>{t('DROP_STRATEGIES_ACQUIRE')}</p>
                    </Form.Group>
                   
            <form> 
                <div className ="row form-group">
                    
                    <label htmlFor="date" className="col-sm-3 col-form-label">Starting date</label>
                     
                    <div className="col-sm-6"> 
                        <div className = " input-group date " id="datepicker">
                        
                            <input type = "text" className ="form-control"></input>
                            
                            <span className ="input-group-append">
                                    <span className="input-group-text bg-white">
                                        <i className ="fa fa-calendar"></i>
                                    </span>
                            </span>
                        </div>    
                    </div>
                </div>
            </form>
                   




             
                    <Form.Group className="mb-3">
                         <Form.Label htmlFor="description" className="text-primary">
                            {t('DROP_STRATEGIES_DETAILS')}
                        </Form.Label>
                           
                       
                       
                       { /* TWEETER */}

                       <Form.Group className="mb-3">
                         <Form.Label htmlFor="description" className="text-primary">
                            {t('DROP_STARTEGY_DETAILS_TWITTER_TITLE')}
                        </Form.Label>
                        </Form.Group>
                       
                       
                        <Form.Group className="form-group row">
                         <Form.Label htmlFor="description" className="col-sm-3 col-form-labe">
                            {t('DROP_STARTEGY_DETAILS_TWITTER_FOLLOW')}
                        </Form.Label>
                        <div className="col-sm-9">
                        
                       
                        
                        <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('DROP_STARTEGY_DETAILS_TWITTER_FOLLOW_PLACEHOLDER')}
                                value={twitter}
                                onChange={(e) => setTwitter(e.target.value)}
                            />
                        </div>                      
                    </Form.Group>

                    <Form.Group className="form-group row">
                         <Form.Label htmlFor="description" className="col-sm-3 col-form-labe">
                            {t('DROP_STARTEGY_DETAILS_TWITTER_RETWEET')}
                        </Form.Label>
                        <div className="col-sm-9">
                      
                        <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('DROP_STARTEGY_DETAILS_TWITTER_RETWEET_PLACEHOLDER')}
                                value={retwit}
                                onChange={(e) => setRetwit(e.target.value)}
                            />
                        </div>                      
                    </Form.Group>
                    <Form.Group className="form-group row">
                         <Form.Label htmlFor="description" className="col-sm-3 col-form-labe">
                            {t('DROP_STARTEGY_DETAILS_TWITTER_LIKE')}
                        </Form.Label>
                        <div className="col-sm-9">
                        
                        <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('DROP_STARTEGY_DETAILS_TWITTER_LIKE_PLACEHOLDER')}
                                value={liketweet}
                                onChange={(e) => setLiketweet(e.target.value)}
                            />
                        </div>                      
                    </Form.Group>

                   { /* NAILING */}
                   <Form.Group className="mb-3">
                         <Form.Label htmlFor="description" className="text-primary">
                            {t('DROP_STARTEGY_DETAILS_MAILING_TITLE')}
                    </Form.Label>
                    </Form.Group>

                    { /* DISCORD */}
                   <Form.Group className="mb-3">
                         <Form.Label htmlFor="description" className="text-primary">
                            {t('DROP_STARTEGY_DETAILS_DISCORD_TITLE')}
                    </Form.Label>
                    </Form.Group>
                    <Form.Group className="form-group row">
                         <Form.Label htmlFor="description" className="col-sm-3 col-form-labe">
                            {t('DROP_STARTEGY_DETAILS_DISCORD_JOIN')}
                        </Form.Label>
                        <div className="col-sm-9">
                     
                        <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('DROP_STARTEGY_DETAILS_DISCORD_JOIN_PLACEHOLDER')}
                                value={discord}
                                onChange={(e) => setDiscord(e.target.value)}
                            />
                        </div>                      
                    </Form.Group>

                    { /* TELEGRAM*/}
                   <Form.Group className="mb-3">
                         <Form.Label htmlFor="description" className="text-primary">
                            {t('DROP_STARTEGY_DETAILS_TELEGRAM_TITLE')}
                        </Form.Label>
                    </Form.Group>
                    <Form.Group className="form-group row">
                         <Form.Label htmlFor="description" className="col-sm-3 col-form-labe">
                            {t('DROP_STARTEGY_DETAILS_TELEGRAM_JOIN')}
                        </Form.Label>
                        <div className="col-sm-9">
                      
                        <Form.Text
                                as="input"
                                name="name"
                                rows="4"
                                required
                                type="text"
                                className="form-control"
                                placeholder={t('DROP_STARTEGY_DETAILS_TELEGRAM_JOIN_PLACEHOLDER')}
                                value={telegram}
                                onChange={(e) => setTelegram(e.target.value)}
                            />
                        </div>                      
                    </Form.Group>
                   

                       
                    </Form.Group>
                </Row>
            </Card>
            </Col>
              
            <Col md={6} sm={6} xs={12}>
            <Card className="p-3">
            <Row>
            <Form.Group className="mb-3">
                    <Form.Label htmlFor="description" className="text-primary">
                            {t('DROP_LANDING_TITLE')}
                    </Form.Label>
                        <p>{t('DROP_LANDING_DETAIL')}</p>

                    <Row>
                    <Col md={6} sm={6} xs={12}>    
                    <Button
                        type="button"
                        className="mt-2 mb-1"
                        variant="outline-primary"
                        onClick={() => onCreate()}
                    >
                        {t('DROP_LANDING_BUTTON')}
                    </Button>
                    </Col>

                    <Col md={6} sm={6} xs={12}>    
                    <Button
                        type="button"
                        className="mt-2 mb-1"
                        variant="outline-primary"
                        onClick={() => onCreate()}
                    >
                        {t('DROP_LANDING_EDIT_SETTING_LINK')}
                    </Button>
                    </Col>
                    </Row>

                    </Form.Group>
            </Row>
            </Card>
            </Col>  
            </Row>


            <Row>
                <Col xs={12} className={'d-flex justify-content-end mb-3'}>
                    <Button
                        disabled={!name.trim()}
                        type="button"
                        className="me-2"
                        variant="primary"
                        onClick={() => onCreate()}
                    >
                        {t('PROJECTS_FORM_EDIT_SAVE')}
                    </Button>
                </Col>
            </Row>

        </form>
    );
};

export default AddDrop;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletAction } from '../../../store/actions/WalletsActions';

/// Bootstrap
import { Col, Card, Row } from 'react-bootstrap';

import Breadcrumbs from '../../layouts/Breadcrumbs';
import WalletTable from '../Wallets/WalletTable';

const IndexWallets = () => {
    const dispatch = useDispatch();
    const [wallets, setWallets] = useState([
        {
            name: 'TestWallet',
            wallet: {
                _id: 'xxx...',
                projectId: 'TestNet',
                address: '0x3333333',
            },
        },
    ]);

    const walletsAll = useSelector((state) => state.wallets.wallets);

    useEffect(() => {
        /* try {
            
        dispatch(getWalletAction());
        
    } catch (e) {
        alert(`fetching Wallets  failed! ${e.message}`);
    };
    */
    }, []);

    useEffect(() => {
        if (walletsAll === undefined || walletsAll.length == 0) {
        } else {
            setWallets(walletsAll);
        }
    }, [walletsAll]);

    // eslint-disable-next-line no-unused-vars
    const onSubmit = (event) => {
        event.preventDefault(); // Prevent default submission

        try {
            dispatch(getWalletAction());
            alert('Your Wallet was successfully fetched!');
        } catch (e) {
            alert(`fetching Wallets  failed! ${e.message}`);
        }
    };

    const tableHeader = ['Id', 'projectId', 'Adress'];
    const tableIndexes = ['_id', 'contractAdress', 'network', 'createdAt'];

    return (
        <div className="badge-demo">
            <Breadcrumbs motherMenu="Wallets" activeMenu="Index" pageContent="Index" />
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Header className="d-block">
                            <Card.Title>Wallets</Card.Title>
                            <Card.Text className="mb-0 subtitle"></Card.Text>
                            {/*   <Button type="submit" onClick ={onSubmit} className="me-2" variant="primary">FetchWallets</Button> */}
                        </Card.Header>
                        <Card.Body>
                            <WalletTable header={tableHeader} indexes={tableIndexes} wallets={wallets} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default IndexWallets;

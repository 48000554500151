/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { Col, Card, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import FileInput from '../../components/Common/FileInput';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';


import { saveCollectionAction } from '../../../store/actions/CollectionActions';
import { addWalletAction } from '../../../store/actions/WalletsActions';
import { getProjectApiAction } from '../../../store/actions/ProjectActions';

import wallet_deploy from '../../../assets/images/waiting_gif/wallet_deploy.gif';
import { useUpdateSubtitle } from '../../../context/MenuTitle';
import { useQuery } from '../../../hooks/useQuery';

const AddCollection = () => {
    const [setSubtitle] = useUpdateSubtitle();
    setSubtitle('');
    const userId = useSelector((state) => {
        return state.auth.auth.userId;
    });
    const projectsAll = useSelector((state) => state.projects.projects);
    const currentProject = useSelector((state) => state.projects.currentProject);
    const walletsAll = useSelector((state) => state.wallets.wallets);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
   
    const params = useQuery();

    const [projectId, setProjectId] = useState('');
    const [walletId, setWalletId] = useState('');
    const [image, setImage] = useState();

    const [transferable, setTransferable] = useState(true);
    const [reveal, setReveal] = useState(true);

    const [erc, setErc] = useState('51');
    const [contractType, setContractType] = useState('51');


    const [show, setShow] = useState(false);
    const showModal = () => setShow(true);
    const closeModal = () => setShow(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const selectProject = (projectId) => {
        console.log('walletsAll', walletsAll)
        const newSelectedProject = projectsAll.find((project) => project._id === projectId);
        const newWalletId =  walletsAll.find((wallet) => wallet.projectId === projectId);
        setProjectId(newSelectedProject._id);
        setWalletId(newWalletId._id);
        console.log('newWalletId', newWalletId)
    };

    useEffect(() => {
        selectProject(params.get('project') || currentProject._id || projectsAll[0]._id);
    }, []);



    const onSubmit = async () => {
     //   showModal();
        
        const currentProjectApiKey = await dispatch(getProjectApiAction(projectId));

        const formData = {
            name,
            description,
            erc,
            symbol: 'MK',
            projectId,
            contractType,
            minter: walletId,
            owner: walletId,
            walletId,
            image,
            transferable,
            reveal
        };

        dispatch(saveCollectionAction(formData, history)).then(
            (collection) => {
                closeModal();
                swal('Collection has been created successfully', collection.name, 'success');
            },
            (err) => {
                closeModal();
                console.log('Error adding collection', err);
                swal('Error adding a new collection', err, 'error');
            },
        );
    };
    const { t } = useTranslation();
    return (


        <form>
            <Row>
                <Col sm={12} xs={12}>
                    <Card className="p-4">
                        <Row>
                        <Col sm={6} md={4} xs={12} className="mb-sm-3">
                            <Form.Label htmlFor="thumbnail" className="text-primary">
                                {t('PROJECTS_FORM_THUMBNAIL')}  
                        
                            </Form.Label>
                            <p className="mb-2">{t('PROJECTS_FORM_THUMBNAIL_DESCRIPTION')} </p>
                            <FileInput onFileAdded={({ file }) => setImage(file)} onFileRemoved={() => setImage()} />
                        </Col>
                        
                            <Col sm={6} md={4} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="description" className="text-primary">
                                        Name
                                    </Form.Label>

                                    <Form.Text
                                        as="input"
                                        name="name"
                                        rows="4"
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Collection Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="">
                                    <Form.Label htmlFor="description" className="text-primary">
                                        Description
                                    </Form.Label>

                                    <Form.Text
                                        as="textarea"
                                        name="description"
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Collection Description"
                                        style={{ height: '150px' }}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={12} md={4} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="description" className="text-primary">
                                        Link it to a Project
                                    </Form.Label>

                                    <p className="mb-3">
                                        Collections are grouped under a project and the project defines the blockchain
                                        network. If you want to use a different network create a new project.
                                    </p>
                                    <Row>
                                        <Col sm={4} xs={6}>
                                            <select
                                                className="form-control mt-1 selecto"
                                                onChange={(event) => selectProject(event.target.value)}
                                                value={projectId}
                                            >
                                                {projectsAll.map(({ _id, name }) => (
                                                    <option key={_id} value={_id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col sm={8} xs={6}>
                                            <Button
                                                type="button"
                                                className="mt-2 mb-1"
                                                variant="outline-primary"
                                                onClick={() => history.push('/add-project')}
                                            >
                                                project{' '}
                                                <span className="btn-icon-end">
                                                    <i className="fa fa-plus-circle" />
                                                </span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col sm={12} xs={12}>
                    <Card className="p-4">
                        <Row>
                            <Col sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="description" className="text-primary">
                                        Transferrable
                                    </Form.Label>

                                    <p className="mb-0">
                                        If enabled NFT owners will be able to transfer their NFTs. If disabled, NFTs
                                        held under this contract will not be transferrable while disabled.
                                    </p>
                                    <div className="mt-3 mb-2">
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`transferable-yes`}
                                            name={`transferable`}
                                            label={'Yes'}
                                            checked={transferable === true}
                                            onChange={(e) => {setTransferable(true)}}
                                            style={{ marginRight: '60px' }}
                                        />
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`transferable-no`}
                                            checked={transferable === false}
                                            name={`transferable`}
                                            onChange={(e) => setTransferable(false)}
                                            label={'No'}
                                        />
                                    </div>
                                    <Row className="mb-4">
                                        <Col lg={4} sm={6}>
                                            <div className="form-group mb-3">
                                                <select defaultValue={'option'} className="nice-select form-control selecto">
                                                    <option>Days</option>
                                                    <option>Months</option>
                                                    <option>Years</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={6}>
                                            <Form.Text
                                                as="input"
                                                name="name"
                                                rows="4"
                                                required
                                                type="number"
                                                className="form-control"
                                                placeholder="Amount"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </Col>
                                    </Row>

                                    <Form.Label htmlFor="description" className="text-primary">
                                        Mutable
                                    </Form.Label>

                                    <p className="mb-0">
                                        Mutable NFT content can be changed after minting as is will not be stored on
                                        chain.
                                    </p>
                                    <div className="mt-3 mb-4">
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`Mutable-yes`}
                                            name={`Mutable`}
                                            label={'Yes'}
                                            style={{ marginRight: '60px' }}
                                            checked={contractType === '51'}
                                            onChange={(e) =>{ 
                                                console.log('contractType', contractType);
                                                setContractType('51')}}
                                     
                                        />
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`Mutable-no`}
                                            name={`Mutable`}
                                            label={'No'}
                                            checked={contractType === '52'}
                                            onChange={(e) => setContractType('52')}
                                        />
                                    </div>

                                    <Form.Label htmlFor="description" className="text-primary">
                                        Reveal
                                    </Form.Label>

                                    <p className="mb-0">
                                        This functionality enables you to reveal the content af the NFT when the drop
                                        ends.
                                    </p>
                                    <div className="mt-3 mb-4">
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`Reveal-yes`}
                                            name={`Reveal`}
                                            label={'Yes'}
                                            style={{ marginRight: '60px' }}
                                            checked={reveal === true}
                                            onChange={(e) => {setReveal(true)}}

                                        />
                                        <Form.Check
                                            inline
                                            type={'radio'}
                                            id={`Reveal-no`}
                                            name={`Reveal`}
                                            label={'No'}
                                            checked={reveal === false}
                                            onChange={(e) => {setReveal(false)}}
              
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Form.Label htmlFor="thumbnail" className="text-primary">
                                    Royalties
                                </Form.Label>
                                <p className="mb-3">
                                    If you want to collect royalties from primary and secondary sales please define the
                                    %. This will be encoded in the smart contract.
                                </p>
                                <Row>
                                    <Col lg={4} sm={6}>
                                        <Form.Text
                                            as="input"
                                            name="Primary"
                                            rows="1"
                                            required
                                            type="number"
                                            className="form-control"
                                            placeholder="Primary %"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Col>
                                    <Col lg={4} sm={6}>
                                        <Form.Text
                                            as="input"
                                            name="Secondary"
                                            rows="1"
                                            required
                                            type="number"
                                            className="form-control"
                                            placeholder="Secondary %"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Col>
                                </Row>

                                <p className="mt-3 mb-3">
                                    Choose the wallet where you&apos;d like to collect the proceeds.
                                </p>
                                <Row>
                                    <Col sm={6} xs={6}>
                                        <div id="asd" className="m-3">
                                            <Form.Check
                                                inline
                                                checked={!walletId}
                                                type={'radio'}
                                                id={`project-wallet-yes`}
                                                name={`projectWallet`}
                                                label={'Use the project wallet'}
                                                onChange={() => setWalletId('')}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={6} xs={6}>
                                        <Form.Text
                                            as="input"
                                            name="newAddress"
                                            rows="1"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Or paste a New address"
                                            value={walletId}
                                            onChange={(e) => setWalletId(e.target.value || '')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* <Row>
                    <Col sm={6} xs={12}>
                        <Card className="p-3">
                            <Form.Label htmlFor="thumbnail" className="text-primary">
                                Landing page settings
                            </Form.Label>
                            <p className="mb-2">
                                Choose the setting for the landing page where NFTs can be reedemed. Customize the logo,
                                URL, and disclaimers below.
                            </p>

                            <Row>
                                <Col lg={6} md={12}>
                                    <FileInput />
                                </Col>
                                <Col lg={6} md={12} className={'d-flex align-items-end justify-content-center'}>
                                    <Button
                                        type="button"
                                        className="mt-2 mb-3"
                                        variant="outline-primary"
                                        onClick={() => onCreate()}
                                    >
                                        Edit landing preferences
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row> */}
            <Row>
                <Col xs={12} className={'d-flex justify-content-end mb-3'}>
                    <Button
                        disabled={!name.trim() || !description.trim()}
                        type="button"
                        className="me-5"
                        variant="outline-primary"
                        onClick={() => onSubmit()}
                    >
                        Draft
                    </Button>
                    <Button
                        disabled={!name.trim() || !description.trim()}
                        type="button"
                        className="me-2"
                        variant="primary"
                        onClick={() => onSubmit(true)}
                    >
                        Save and add Drop
                    </Button>
                </Col>
            </Row>

            <Modal show={show}>
                <Modal.Body>
                    <img className="card-img-top img-fluid" src={wallet_deploy} alt="Card image cap"></img>
                    <h2 className="text-center">Your Collection is being deployed</h2>
                </Modal.Body>
                <Modal.Footer>
                    {/*   <Button variant="secondary"  onClick={handleClose}> Close Modal</Button> */}
                </Modal.Footer>
            </Modal>
        </form>
        
    );
};

export default AddCollection;

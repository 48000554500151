import React, { Fragment, useContext, useState } from 'react';
/// React router dom
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../context/ThemeContext';
import logo from '../../../assets/images/logo-full-color.png';
import logoMini from '../../../assets/images/logo-mini.png';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

const NavHader = () => {
    const [toggle, setToggle] = useState(false);
    const { navigationHader, openMenuToggle, background } = useContext(ThemeContext);
    const { width } = useWindowDimensions();

    return (
        <div className="nav-header">
            <Link to="/dashboard" className="brand-logo">
                {background.value === 'dark' || navigationHader !== 'color_1' ? (
                    <Fragment></Fragment>
                ) : (
                    <div style={{}}>
                        {toggle || width <= 1020 ? (
                            <img src={logoMini} alt="" width="50" />
                        ) : (
                            <img src={logo} alt="" width="150" />
                        )}
                    </div>
                )}
            </Link>

            <div
                className="nav-control"
                onClick={() => {
                    setToggle(!toggle);
                    openMenuToggle();
                }}
            >
                <div className={`hamburger`}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            </div>
        </div>
    );
};

export default NavHader;
